import { IonCol, IonRow } from "@ionic/react";
import { digitChanger } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";
import Card from "../Common/Card";

export default function OrderItemCard({ order }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	let imageFunc = (item) => {
		if (Array.isArray(item?.catalog?.product?.images)) {
			if (item?.catalog?.product?.images.length !== 0) {
				return item?.catalog?.product?.images[0];
			} else {
				if (item?.catalog?.product.type === "variant") {
					return "/assets/images/varient_default.png";
				} else if (item?.catalog?.product.type === "pack") {
					return "/assets/images/pack_default.png";
				} else if (item?.catalog?.product.type === "offer") {
					return "/assets/images/offer_default.png";
				}
			}
		} else {
			if (item?.catalog?.product.type === "variant") {
				return "/assets/images/varient_default.png";
			} else if (item?.catalog?.product.type === "pack") {
				return "/assets/images/pack_default.png";
			} else if (item?.catalog?.product.type === "offer") {
				return "/assets/images/offer_default.png";
			}
		}
	};

	return (
		<div className='px-2 pt-1 pb-5'>
			<Card title={ekkLanguage.orderDetails.orderItemLabel}>
				<div>
					<IonRow>
						{order?.order_items
							? order?.order_items.map((item, index) => {
									return (
										<IonCol size='12' className='p-0' key={index}>
											<div className='p-2 mt-2'>
												<div className='flex mb-2 border-b'>
													<div
														style={{ width: 65, height: 65 }}
														className='mb-2 bg-F3F3F3 rounded-[10px] text-center flex items-center justify-center overflow-hidden'>
														<img
															src={imageFunc(item)}
															alt='Ordered'
														/>
													</div>
													<div className='ml-3 flex-1 '>
														<h2 className='text-black-1000 text-14 font-semibold mb-2 text-black'>
															{item?.catalog?.product?.title}
														</h2>
														<h3 className='text-222222 text-14 font-bold mb-2'>
															{`${digitChanger(
																item?.quantity
															)} x ${
																order?.country
																	? order?.country
																			?.currency_symbol_native
																	: ""
															} ${digitChanger(
																Number(
																	item?.unit_price
																).toFixed(2)
															)} `}

															<span className='text-primary text-14 font-bold mb-2'>
																={" "}
																{
																	order?.country
																		?.currency_symbol_native
																}
																{digitChanger(
																	(
																		item?.quantity *
																		item?.unit_price
																	).toFixed(2)
																)}
															</span>
														</h3>
													</div>
												</div>
											</div>
										</IonCol>
									);
							  })
							: ""}
					</IonRow>
				</div>
			</Card>
		</div>
	);
}
