import { IonCol, IonRow } from "@ionic/react";
import { digitChanger } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";

export default function DatedAmountCards({
	todayAmount,
	yesterDayAmount,
	dayAmountLoader,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<IonRow>
			<IonCol size='6'>
				<div
					className='text-center  bg-white rounded-[10px] mb-7'
					style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)" }}>
					<div className='inline-block text-center  px-6 py-4'>
						<p className='text-black-333 text-12 pb-[5px]'>
							{ekkLanguage.depositsList.todayAmount}
						</p>
						{!dayAmountLoader && (
							<>
								<p className='text-delivered text-12 pb-[10px] font-extrabold'>
									{todayAmount && todayAmount.total_orders}{" "}
									{ekkLanguage.depositForm.orders}
								</p>
								<h6 className='text-delivered text-18 pb-[10px] font-bold'>
									৳{" "}
									{todayAmount?.total_amount
										? digitChanger(
												Number(todayAmount?.total_amount).toFixed(2)
										  )
										: digitChanger(0)}
								</h6>
							</>
						)}
					</div>
				</div>
			</IonCol>
			<IonCol size='6'>
				<div
					className='text-center  bg-white rounded-[10px] mb-7'
					style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)" }}>
					<div className='inline-block text-center  px-6 py-4'>
						<p className='text-black-333 text-12 pb-[5px]'>
							{ekkLanguage.depositsList.yesterdayAmount}
						</p>
						{!dayAmountLoader && (
							<>
								<p className='text-delivered text-12 pb-[10px] font-extrabold'>
									{yesterDayAmount && yesterDayAmount.total_orders}{" "}
									{ekkLanguage.depositForm.orders}
								</p>
								<h6 className='text-delivered text-18 pb-[10px] font-bold'>
									৳{" "}
									{yesterDayAmount?.total_amount
										? digitChanger(
												Number(
													yesterDayAmount?.total_amount
												).toFixed(2)
										  )
										: digitChanger(0)}
								</h6>
							</>
						)}
					</div>
				</div>
			</IonCol>
		</IonRow>
	);
}
