import { IonApp, IonContent, IonPage } from "@ionic/react";
import BankList from "components/mobile-components/BankList";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React from "react";
import { useSelector } from "react-redux";

function BankListPage() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <BackButtonTitle title={ekkLanguage.bankDetailsPage.selectBank} />
          <BankList />
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
export default BankListPage;
