import {
  IonApp,
  IonContent,
  IonPage,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import CostSummeryCard from "components/mobile-components/OrderDetails/CostSummeryCard";
import DeliveryDetailsCard from "components/mobile-components/OrderDetails/DeliveryDetailsCard";
import OrderItemCard from "components/mobile-components/OrderDetails/OrderItemCard";
import OrderMetaBar from "components/mobile-components/OrderDetails/OrderMetaBar";
import OrderOnwerState from "components/mobile-components/OrderDetails/OrderOnwerState";
import PaymentDetailsCard from "components/mobile-components/OrderDetails/PaymetDetailsCard";
import TimeLine from "components/mobile-components/OrderDetails/TimeLine";
import TimeLineTextBox from "components/mobile-components/OrderDetails/TimeLineTextBox";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderSingle } from "redux/container/orderSlice";
import { useParams } from "react-router-dom";
import PaymentSettlementCard from "components/mobile-components/OrderDetails/PaymentSettlement";
import Orders from "services/Orders";
import BottomSheet from "components/mobile-components/DriverOrderDetails/BottomSheet";

export default function DriverOrderDetailsPage() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const response = useSelector((state) => state.order);
  let [collection, setCollection] = useState([]);

  useIonViewDidEnter(() => {
    dispatch(fetchOrderSingle(id));
  });
  useIonViewWillEnter(() => {
    dispatch(fetchOrderSingle(id));
  });

  const fetchOderCollectionAmount = async () => {
    let response = await Orders.orderAmountCollection(id);
    if (response?.status === 200) {
      setCollection(response.data);
    }
  };

  useEffect(() => {
    dispatch(fetchOrderSingle(id));
    fetchOderCollectionAmount(id);
  }, []);

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <BackButtonTitle
            title={response?.singleOrder?.created_by_business?.business_name}
            from={
              response?.singleOrder?.order_status ===
                "Scheduled For Delivery" && `orderDetailsPage`
            }
          />

          <OrderMetaBar order={response.singleOrder} />
          <section className="px-2 pt-24">
            <OrderOnwerState order={response.singleOrder} />
            <DeliveryDetailsCard order={response.singleOrder} />
            <OrderItemCard order={response.singleOrder} />
            <CostSummeryCard order={response.singleOrder} />
            <PaymentDetailsCard order={response.singleOrder} />
            <PaymentSettlementCard collection={collection} />

            <TimeLine order={response.singleOrder} />
            <BottomSheet order={response.singleOrder} />
          </section>
          {/* <TimeLineTextBox order={response.singleOrder} /> */}
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
