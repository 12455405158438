import { digitChanger, translator } from "helpers/helper";
import { useSelector } from "react-redux";
import Card from "../Common/Card";

export default function CostSummeryCard({ order }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const orderUpdate = useSelector((state) => state.order.deliveredItems);
	return (
		<div className='px-2'>
			<Card title={ekkLanguage.orderDetails.castItemLabel}>
				<div>
					<table className='table-fixed w-full'>
						<tbody className="before:content-[' '] before:block before:h-2 after:content-[' '] after:block after:h-2">
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.subtotal}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.sub_total &&
										order?.country?.currency_symbol_native}{" "}
									{orderUpdate?.subTotal &&
										digitChanger(orderUpdate?.subTotal?.toFixed(2))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.tax}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.country?.currency_symbol_native}
									{orderUpdate?.taxTotal
										? digitChanger(orderUpdate?.taxTotal?.toFixed(2))
										: digitChanger(Number(0).toFixed(2))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.discount}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.country?.currency_symbol_native}
									{"-"}
									{order?.discountAmount
										? digitChanger(
												Number(order?.discountAmount).toFixed(2)
										  )
										: digitChanger(Number(0).toFixed(2))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.deliveryFee}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.country?.currency_symbol_native}{" "}
									{order?.deliveryFee
										? digitChanger(
												Number(order?.deliveryFee).toFixed(2)
										  )
										: digitChanger(Number(0).toFixed(2))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.creditFee}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.country?.currency_symbol_native}{" "}
									{orderUpdate?.creditFee
										? digitChanger(orderUpdate?.creditFee?.toFixed(2))
										: digitChanger(Number(0).toFixed(2))}
								</td>
							</tr>
						</tbody>
						<tfoot className='border-t'>
							<tr>
								<td className='text-primary text-12 font-bold py-[6px]'>
									{ekkLanguage.orderDetails.total}
								</td>
								<td className='text-primary text-12 font-bold py-[6px] text-right'>
									{order?.total &&
										order?.country?.currency_symbol_native}{" "}
									{orderUpdate?.total &&
										digitChanger(orderUpdate?.total?.toFixed(2))}
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</Card>
		</div>
	);
}
