import {
  IonApp,
  IonContent,
  IonPage,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import DeliverForm from "components/mobile-components/DeliverForm";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchOrderSingle } from "redux/container/orderSlice";

export default function DeliverFormPage() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  let { id } = useParams();
  const dispatch = useDispatch();
  const response = useSelector((state) => state.order);

  useIonViewDidEnter(() => {
    dispatch(fetchOrderSingle(id));
  });
  useIonViewWillEnter(() => {
    dispatch(fetchOrderSingle(id));
  });

  useEffect(() => {
    dispatch(fetchOrderSingle(id));
  }, []);

  return (
    <IonApp>
      <IonPage className="">
        <IonContent>
          <BackButtonTitle title={ekkLanguage.deliverForm.pageTitle} />
          <DeliverForm order={response.singleOrder} />
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
