import { digitChanger, translator } from "helpers/helper";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

export default function TotalPayment({ data }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='text-center py-4 mt-4'>
			<h4 className='text-20 pb-[10px] font-bold'>
				{ekkLanguage.payment.totalAmount}
			</h4>
			<h5
				className='text-20 pb-[10px] font-bold'
				style={{ color: "#0099CC" }}>
				{data?.country?.currency_symbol_native}{" "}
				{digitChanger(Number(data?.total - data?.total_paid).toFixed(2))}
			</h5>
			<p className='text-16 font-normal pb-1 text-black-500'>
				{ekkLanguage.payment.dueDate}:{" "}
				{digitChanger(
					moment(data?.payment_term?.paymentDueDate).format("DD-MM-YYYY")
				)}
			</p>
		</div>
	);
}
