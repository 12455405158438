import { IonApp, IonContent, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import CollectionHistoryList from "components/mobile-components/CollectionHistoryList";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import BottomSheet from "components/mobile-components/OrderList/BottomSheet";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function CollectionHistoryListPage() {
  const [menuCollapse, setMenuCollapse] = useState(true);
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  const ekkLanguage = useSelector((state) => state.lan.lan);

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.collectionHistory.collectionHistory}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <section>
            <CollectionHistoryList />
          </section>
          <BottomSheet />
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
