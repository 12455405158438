import React, { useRef, useState } from "react";
import Select from "react-select";
import { COUNTRYINFO } from "data/country";
import { IonCheckbox } from "@ionic/react";
import { getLanguage, setLanguage } from "config/language";
import { useDispatch, useSelector } from "react-redux";
import { parseNumber } from "libphonenumber-js";
import Auth from "services/Auth";
import { Link, useNavigate } from "react-router-dom";
import { setCreateUser } from "redux/container/userSlice";
import { selectLan } from "redux/container/languageSlice";
import { authBox_Block, forgotPass_block } from "Constants";

export default function SendOtpBlock({
	sSize,
	phoneNo,
	setPhoneNo,
	country,
	setCountry,
	countryCode,
	setCountryCode,
	setBlockIndex,
}) {
	const ekkLanguage = useSelector((state) => state.lan);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [warning, setWarning] = useState("");

	var countryList = Object.values(COUNTRYINFO).map((val) => ({
		countryCode: val.code,
		country: val.name,
		value: val.code,
		label: (
			<div className='flex items-center'>
				<img
					src={val.flag}
					className='sm:h-[32px] sm:w-[50px] h-auto w-5 rounded-[5px]'
					alt='flag'></img>{" "}
				<p className='text-16 pl-2'>{val.code}</p>
			</div>
		),
	}));
	const handleSelectCountry = (e) => {
		setCountryCode(e.countryCode);
		setCountry(e.country);
		setWarning("");
	};
	let clickBtnRef = useRef(null);
	const _handleKeyPress = (e) => {
		if (e.key === "Enter") {
			clickBtnRef.click();
		}
	};
	let customStyles = {
		option: (provided, state) => ({
			...provided,
			zIndex: 10,
			paddingLeft: 30,
		}),
		singleValue: (provided, state) => ({
			...provided,
			borderWidth: 0,
			height: sSize.matches ? 50 : 80,
			borderRadius: 0,
			display: "flex",
			fontSize: sSize.matches ? 16 : 24,
		}),
		control: (provided, state) => ({
			...provided,
			color: "red",
			borderRadius: 0,
			borderWidth: 0,
			outLine: "none",
			height: sSize.matches ? 50 : 80,
			paddingLeft: sSize.matches ? 10 : 20,
			fontSize: sSize.matches ? 16 : 24,
			borderBottomLeftRadius: 5,
			borderTopLeftRadius: 5,
		}),
	};

	const errorMessage = () => {
		if (phoneNo) {
			if (country === "Bangladesh") {
				if (
					!(
						phoneNo.trim().length > 10 &&
						phoneNo.trim().length < 12 &&
						phoneNo[0] == 0
					)
				) {
					return ekkLanguage?.lan.login.validationLabel;
				} else {
					return "";
				}
			} else {
				if (
					!Object.keys(parseNumber(countryCode + phoneNo)).length &&
					phoneNo.trim().length > 0
				) {
					return ekkLanguage?.lan.login.validationLabel;
				} else {
					return "";
				}
			}
		}
	};

	//submit country verificarion handler, it's verify first country and phone no.
	const submitPhoneNumberCountry = async (
		countryCode,
		country,
		phoneNumber
	) => {
		if (country === "Bangladesh") {
			if (
				!(
					phoneNo.trim().length > 10 &&
					phoneNo.trim().length < 12 &&
					phoneNo[0] == 0
				)
			) {
				// toast.error(ekkLanguage?.lan?.login.validationLabel);
				return;
			}
		}
		let req_body = {
			country,
			phone_number: (countryCode + phoneNo).trim(),
		};

		try {
			// startLoading();
			let response = await Auth.resendOptToken(req_body);

			if (response.status === 200) {
				// let data = {
				// 	countryCode,
				// 	country,
				// 	phoneNumber,
				// 	verify: false,
				// };
				// dispatch(setCreateUser(data));
				// navigate("/login");
				setBlockIndex(forgotPass_block.TOKEN);
			} else {
				// let data = {
				// 	countryCode,
				// 	country,
				// 	phoneNumber,
				// 	verify: true,
				// };
				// dispatch(setCreateUser(data));
				// setVerifyNumber(true);
				// navigate("/login");
				setWarning();
			}
			// stopLoading();
		} catch (e) {
			// stopLoading();
			setWarning(e.errorData.message);
			console.log(e);
		}
	};
	return (
		<>
			<label
				className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] block`}>
				{ekkLanguage?.lan?.landing.phoneNumber}
			</label>
			<div
				className='flex z-10 relative'
				style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.05)" }}>
				<Select
					styles={customStyles}
					className='basic-single w-[250px]'
					placeholder='+88'
					name='color'
					options={countryList}
					onChange={(e) => {
						setWarning("");
						handleSelectCountry(e);
					}}
					components={{
						IndicatorSeparator: () => null,
					}}
					style={{ fontSize: sSize.matches ? 14 : 24 }}
					defaultValue={{
						countryCode: "+88",
						country: "Bangladesh",
						value: "+88",
						label: (
							<div className='flex items-center'>
								<img
									src='https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bangladesh.png'
									className='sm:h-[32px] sm:w-[50px] h-auto w-5 rounded-[5px]'
									alt='flag'></img>{" "}
								<p className='text-18 pl-2'>+88</p>
							</div>
						),
					}}
				/>

				<input
					type='number'
					onKeyPress={_handleKeyPress}
					onChange={(event) => {
						setWarning("");
						setPhoneNo(event.target.value);
					}}
					placeholder={ekkLanguage?.lan?.landing.phoneNumberPlaceHolder}
					id={"ekk-input"}
					style={{
						paddingLeft: 30,
						height: sSize.matches ? 50 : 80,
						flex: "flex: 1 0 auto",
						borderRadius: ".25rem",
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
						borderWidth: 0,
						borderLeft: "1px solid rgba(0,0,0,.05)",
					}}
					className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
				/>
			</div>
			<div className='pt-4'>
				<p className='text-red text-xs text-primary'>{errorMessage()}</p>
				<p className='text-red text-xs text-primary'>{warning}</p>
			</div>

			<button
				onClick={() => {
					submitPhoneNumberCountry(countryCode, country, phoneNo);
				}}
				// propsRef={(input) => {
				// 	clickBtnRef = input;
				// }}
				style={{
					background:
						"linear-gradient(89.31deg, #00C0FF 0%, rgba(0, 178, 36, 0.5) 100%)",
				}}
				className={`text-20 sm:text-26 font-bold text-white w-full h-[50px] sm:h-[60px] rounded-[5px] mt-4`}>
				{ekkLanguage?.lan?.landing.requestOtp}
			</button>
			<div className='text-left pt-4'>
				<p className='font-bold text-14'>
					{ekkLanguage?.lan?.login?.langChange}
					<span
						className={`${
							getLanguage() == "en" ? "text-success" : "text-black-1000"
						}  mx-1 cursor-pointer`}
						onClick={() => {
							setLanguage("en");
							dispatch(selectLan());
						}}>
						English
					</span>{" "}
					<span
						className={`${
							getLanguage() == "bn" ? "text-success" : "text-black-1000"
						}  mx-1 cursor-pointer`}
						onClick={() => {
							setLanguage("bn");
							dispatch(selectLan());
						}}>
						বাংলা
					</span>
				</p>
			</div>
		</>
	);
}
