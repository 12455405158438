export const initBusiness = {
	business_type: "",
	business_name: "",
	address_line: "",
	office_number: "",
	mobile_number: "",
	city: "",
	email: "",
	postal_code: "",
	long_lat: { type: "Point", coordinates: [0.0, 0.0] },
	booking_rate: "",
	country: "",
	website: "",
	origin_of_creation: "EkkBaz",
	created_by: "",
};

export function setLocalUser(response) {
	localStorage.setItem("setUser", JSON.stringify(response));
}

export function getLocalUser() {
	return JSON.parse(localStorage.getItem("setUser"));
}
export function checkGetLocalUser() {
	if (localStorage.getItem("setUser") !== null) {
		return true;
	} else {
		return false;
	}
}

export function setUserBusiness(business) {
	localStorage.setItem("_b", JSON.stringify(business));
}

export function getUserBusiness() {
	return JSON.parse(localStorage.getItem("_b"));
}

export function setUser(user) {
	localStorage.setItem("_u", JSON.stringify(user));
}

export function getUser() {
	return JSON.parse(localStorage.getItem("_u"));
}

export function setCreateUserBusiness(business) {
	localStorage.setItem("cb", JSON.stringify(business));
}
export function getCreateUserBusiness() {
	return JSON.parse(localStorage.getItem("cb"));
}
export function setProductSeller(seller) {
  localStorage.setItem("ps", JSON.stringify(seller));
}
export function getProductSeller() {
  return JSON.parse(localStorage.getItem("ps"));
}