import { setLanguage } from "config/language";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "redux/container/authSlice";
import BlogData from "services/Blog";
import BannerArea from "./BannerArea";
import Footer from "./Footer";
import Navigation from "./Navigation";
import { useLocation, useNavigate } from "react-router-dom";
import { clearAuth } from "config/authFetch";
import { actions } from "redux/container/authSlice";
import { cond } from "lodash";
import { selectLan } from "redux/container/languageSlice";
import DownloadPwaScreen from "pages/Auth/DownloadPwaModal";

function Landing() {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const { loginStatus } = useSelector(selectors.getAuth);
  const [blogList, setBlogList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    function onScroll() {
      // let currentPosition = window.pageYOffset;
      // document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
      let currentPosition = document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
      if (currentPosition > 150) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  // const getBlogList = async (ctx) => {
  // 	let blogList = await BlogData.getBlogList(ctx);
  // 	if (blogList.status === 200) {
  // 		setBlogList(blogList.data);
  // 	}
  // };

  // useEffect(() => {
  // 	getBlogList("Latest News");
  // }, []);
  useEffect(() => {
    var parts = location?.search?.split("?");

    if (parts[1]) {
      console.log("Login true");
      dispatch(actions.logout());
      localStorage.removeItem("master_token");
      localStorage.removeItem("_u");
      localStorage.removeItem("_b");
      localStorage.removeItem("cb");
      localStorage.setItem("auth", "false");
      // dispatch(setPreUrl(parts[1]));
      navigate(loginStatus ? "/" : `/${parts[1] ? `?${parts[1]}` : ""}`);
    } else {
      console.log("Login true");
      // dispatch(setPreUrl(""));
      navigate(loginStatus ? "/" : "/login");
    }
  }, [loginStatus]);

  if (localStorage.getItem("lan") === null) {
    setLanguage();
  } else {
    dispatch(selectLan());
  }

  return (
    <section>
      <Navigation scrolling={scrolling ? "isScrolled" : ""} />
      <BannerArea />
      <div className="mt-24 md:mt-10 lg:mt-10">
        <Footer />
      </div>
    </section>
  );
}

export default Landing;
