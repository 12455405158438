import { Button } from "components/global-components/common/button";
import React, { useRef, useState } from "react";
import Box from "./Box";
import { parseNumber } from "libphonenumber-js";
import Select from "react-select";
import { COUNTRYINFO } from "data/country";
import { toast } from "react-toastify";
import Auth from "services/Auth";
import { authBox_Block } from "Constants";
import { authOtpSend } from "helpers/helper";
import { useDispatch, useSelector } from "react-redux";
import { getLanguage, setLanguage } from "config/language";
import { selectLan } from "redux/container/languageSlice";

export default function PhoneNumberCountry({
	loading,
	phoneNo,
	setPhoneNo,
	countryCode,
	setCountryCode,
	setCountry,
	startLoading,
	stopLoading,
	setBlockIndex,
	country,
	setVerifyNumber,
}) {
	// language call from redux service
	const [notFound, setNotFound] = useState("");
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan);
	const language = getLanguage();
	var countryList = Object.values(COUNTRYINFO).map((val) => ({
		countryCode: val.code,
		country: val.name,
		value: val.code,
		label: val.name,
	}));

	const errorMessage = () => {
		if (phoneNo) {
			if (country === "Bangladesh") {
				if (
					!(
						phoneNo.trim().length > 10 &&
						phoneNo.trim().length < 12 &&
						phoneNo[0] == 0
					)
				) {
					return ekkLanguage?.lan.login.validationLabel;
				} else {
					return "";
				}
			} else {
				if (
					!Object.keys(parseNumber(countryCode + phoneNo)).length &&
					phoneNo.trim().length > 0
				) {
					return ekkLanguage?.lan.login.validationLabel;
				} else {
					return "";
				}
			}
		}
	};
	const handleSelectCountry = (e) => {
		setCountryCode(e.countryCode);
		setCountry(e.country);
	};
	//submit country varificarion handler, it's varify first country and phone no.
	const submitPhoneNumberCountry = async (
		countryCode,
		country,
		phoneNumber
	) => {
		if (country === "Bangladesh") {
			if (
				!(
					phoneNo.trim().length > 10 &&
					phoneNo.trim().length < 12 &&
					phoneNo[0] == 0
				)
			) {
				toast.error(ekkLanguage?.lan?.login.validationLabel);
				return;
			}
		}

		// let pn = parseNumber(countryCode + phoneNo).phone;
		let req_body = {
			country,
			phone_number: (countryCode + phoneNo).trim(),
		};

		try {
			startLoading();
			let response = await Auth.countryAndUserFilter(req_body);
			if (response.status === 200) {
				console.log(response);
				setBlockIndex(authBox_Block.PASSWORD_BLOCK);
			} else {
				// setVerifyNumber(true);
				setNotFound(ekkLanguage.lan.login.notFound);
			}
			stopLoading();
		} catch (e) {
			stopLoading();
			console.log(e);
		}
	};
	let clickBtnRef = useRef(null);
	const _handleKeyPress = (e) => {
		if (e.key === "Enter") {
			clickBtnRef.click();
		}
	};

	return (
		<Box>
			<div id='phonenumber-country-container'>
				<div>
					<label className='uppercase tracking-wide text-grey-darker text-sm font-semibold mb-2'>
						{ekkLanguage?.lan?.login?.contryLabel}
					</label>
					<Select
						className='basic-single'
						placeholder='Select Your Country'
						name='color'
						options={countryList}
						onChange={(e) => handleSelectCountry(e)}
						defaultValue={{
							countryCode: "+88",
							country: "Bangladesh",
							value: "+88",
							label: "Bangladesh",
						}}
					/>
				</div>

				<div className='mb-1 mt-4'>
					<label className='uppercase tracking-wide text-grey-darker text-sm font-semibold mb-2'>
						{ekkLanguage?.lan?.login?.phoneLable}
					</label>
					<div className='flex items-stretch '>
						<span
							value={countryCode}
							placeholder='+88'
							readOnly={true}
							id={"ekk-input"}
							style={{
								borderRadius: ".25rem",
								borderTopRightRadius: 0,
								borderBottomRightRadius: 0,
							}}
							className='appearance-none text-lg text-grey-darker border border-right-0 py-2 px-2 mb-3 leading-tight '>
							{countryCode}
						</span>

						<input
							type='number'
							onKeyPress={_handleKeyPress}
							onChange={(event) => setPhoneNo(event.target.value)}
							placeholder={ekkLanguage?.lan?.login?.phonePlaceHolder}
							id={"ekk-input"}
							style={{
								flex: "flex: 1 0 auto",
								borderRadius: ".25rem",
								borderTopLeftRadius: 0,
								borderBottomLeftRadius: 0,
							}}
							className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white'
						/>
					</div>
					<p className='text-red text-xs'>
						{errorMessage(countryCode, phoneNo)}
					</p>
					<p className='text-primary'>{notFound}</p>
				</div>

				<div className='flex justify-center'>
					<Button
						title={ekkLanguage?.lan?.login?.btnLabel}
						loading={loading}
						onClick={() =>
							submitPhoneNumberCountry(countryCode, country, phoneNo)
						}
						propsRef={(input) => {
							clickBtnRef = input;
						}}
						style={{ marginBottom: "10px" }}
					/>
				</div>
				<div className='text-center'>
					<p className='font-bold text-14'>
						{ekkLanguage?.lan?.login?.langChange}
						<span
							className={`${
								language == "en" ? "text-primary" : "text-black-1000"
							}  mx-1 cursor-pointer`}
							onClick={() => {
								setLanguage("en");
								dispatch(selectLan());
							}}>
							English
						</span>{" "}
						<span
							className={`${
								language == "bn" ? "text-primary" : "text-black-1000"
							}  mx-1 cursor-pointer`}
							onClick={() => {
								setLanguage("bn");
								dispatch(selectLan());
							}}>
							বাংলা
						</span>
					</p>
				</div>
			</div>
		</Box>
	);
}
