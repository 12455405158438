import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import Amount from "assets/images/icon/svg/Amount";
import Buyer from "assets/images/icon/svg/Buyer";
import Calendar2 from "assets/images/icon/svg/Calendar2";
import Pin from "assets/images/icon/svg/Pin";
import Seller from "assets/images/icon/svg/Seller";
import { addStatusClass, digitChanger, monthChanger } from "helpers/helper";
import "moment-timezone";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
export default function CollectionCard({ response, ekkLanguage, loading }) {
	return (
		<IonGrid>
			{Array.isArray(response) && response.length > 0 ? (
				<IonRow>
					{response &&
						response?.map((order, index) => {
							let orderStatus = addStatusClass(order?.order_status);

							return (
								<IonCol size='12' key={index}>
									<div
									// to={`/collection-history-list-details/${order?.collection.order_id}`}
									>
										<div
											className='mb-2 rounded-[10px] overflow-hidden'
											style={{
												boxShadow: `0px 5px 15px rgba(0, 0, 0, 0.05)`,
											}}>
											<div className={`bg-paid py-[7px]  px-4`}>
												<ul className='flex items-center justify-between'>
													<li>
														<h6 className='font-bold text-12 text-white'>
															{digitChanger(
																order?.order?.order_id
															)}
														</h6>
													</li>
													<li>
														<h6 className='font-bold text-12 text-white'>
															{digitChanger(
																monthChanger(
																	moment(
																		order?.order?.createdAt
																	).format("DD MMM, YYYY")
																)
															)}
														</h6>
													</li>
													<li>
														<h6 className='font-bold text-12 text-white'>
															৳{" "}
															{digitChanger(
																Number(
																	order?.order?.total
																).toFixed(2)
															)}
														</h6>
													</li>
												</ul>
											</div>
											<div className='px-4 bg-white py-[7px]'>
												<div className='flex justify-between'>
													<div>
														<p className='text-black-500 text-10 font-semibold py-[3px] flex'>
															<span className='pr-2'>
																<Calendar2 />
															</span>
															{digitChanger(
																moment(
																	order?.collection?.createdAt
																).format("DD-MM-YYYY")
															)}
														</p>
													</div>
													<div className='w-1/2'>
														<p className='text-black-500 text-10 font-semibold py-[3px] flex'>
															<span className='pr-2'>
																<Amount />
															</span>
															৳{" "}
															{digitChanger(
																Number(
																	order?.collection?.amount
																).toFixed(2)
															)}
														</p>
													</div>
												</div>
												<div className='flex justify-between'>
													<div className='w-1/2'>
														<p className='text-black-500 text-10 font-semibold py-[3px] flex'>
															<span className='pr-2'>
																<Buyer />
															</span>
															{
																order?.order?.buyer
																	?.business_name
															}
														</p>
													</div>
													<div className='w-1/2'>
														<p className='text-black-500 text-10 font-semibold py-[3px] flex'>
															<span className='pr-2'>
																<Pin />
															</span>
															{order?.order?.buyer?.address_line}
															,{order?.order?.buyer?.city},
															{order?.order?.buyer?.country}
														</p>
													</div>
												</div>
												<div className='flex justify-between'>
													<div className='w-1/2'>
														<p className='text-black-500 text-10 font-semibold py-[3px] flex'>
															<span className='pr-2'>
																<Seller />
															</span>
															{
																order?.order?.seller
																	?.business_name
															}
														</p>
													</div>
													<div className='w-1/2'>
														<p className='text-black-500 text-10 font-semibold py-[3px] flex'>
															<span className='pr-2'>
																<Pin />
															</span>
															{
																order?.order?.seller
																	?.address_line
															}
															,{order?.order?.seller?.city},
															{order?.order?.seller?.country}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</IonCol>
							);
						})}
				</IonRow>
			) : (
				<IonRow>
					<IonCol size='2'></IonCol>
					<IonCol size='8'>
						<div
							className='text-center flex items-center justify-center'
							style={{ height: "calc(100vh - 420px)" }}>
							<div>
								<img
									className='m-auto mb-[10px] mt-5'
									src='/assets/images/not-found-file.png'
									alt='emptyCard'
								/>
								<p className='text-16 font-normal text-222222 mb-[35px]'>
									{ekkLanguage.orderList.notFound}
								</p>
							</div>
						</div>
					</IonCol>
				</IonRow>
			)}
		</IonGrid>
	);
}
