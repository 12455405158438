import { IonInput, IonItem, IonTextarea, IonToggle } from "@ionic/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function FullDepositForm({
	totalDepositAmount,
	data,
	restAmount,
	setRestAmount,
	remaining,
	setRemaining,
	depositTicketNumber,
	setDepositTicketNumber,
	transactionNumber,
	setTransactionNumber,
	branchSubBranch,
	setBranchSubBranch,
	bankAddress,
	setBankAddress,
	payAmount,
	setPayAmount,
	comment,
	setComment,
	amount,
	setAmount,
	selectedBank,
}) {
	const [checked, setChecked] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	// useEffect(() => {
	// 	setAmount(data.total_amount);
	// }, [data]);

	useEffect(() => {
		if (checked) {
			let a = payAmount
				? Number(Number(amount) + Number(payAmount)).toFixed(2)
				: amount.toFixed(2);
			setPayAmount(a);
			setAmount(0);
		} else {
			setPayAmount(0);
			setAmount(data.total_amount - totalDepositAmount);
		}
	}, [checked]);

	useEffect(() => {
		if (payAmount < data.total_amount - totalDepositAmount) {
			payAmount > 0
				? setAmount(
						Number(data.total_amount) -
							Number(payAmount) -
							totalDepositAmount
				  )
				: setAmount(data.total_amount - totalDepositAmount);
		}
	}, [payAmount]);

	return (
		<div>
			<div
				className='text-center py-4 bg-white rounded-[10px] mt-4 px-4 mb-7 business-location'
				style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)" }}>
				{/* <div>
					<p className='text-16 text-black-1000 text-left'>
						{ekkLanguage.depositForm.depositTicket}
					</p>
					<div className='w-full pb-[30px]'>
						<IonItem>
							<IonInput
								name='depositTicketNumber'
								type='string'
								value={depositTicketNumber}
								placeholder={ekkLanguage.depositForm.depositPlaceholder}
								onIonChange={(e) => {
									setDepositTicketNumber(e.detail.value);
								}}
								clearInput></IonInput>
						</IonItem>
					</div>
				</div> */}
				{/* <div>
					<p className='text-16 text-black-1000 text-left'>
						{ekkLanguage.depositForm.transactionNumber}
					</p>
					<div className='w-full pb-[30px]'>
						<IonItem>
							<IonInput
								name='transactionNumber'
								type='string'
								value={transactionNumber}
								placeholder={
									ekkLanguage.depositForm.transactionPlaceholder
								}
								onIonChange={(e) => {
									setTransactionNumber(e.detail.value);
								}}
								clearInput></IonInput>
						</IonItem>
					</div>
				</div> */}

				{/* <div>
					<p className='text-16 text-black-1000 text-left'>
						{ekkLanguage.depositForm.branchSubBranch}
					</p>
					<div className='w-full pb-[30px]'>
						<IonItem>
							<IonInput
								name='branchSubBranch'
								type='string'
								value={branchSubBranch}
								placeholder={ekkLanguage.depositForm.branchPlaceholder}
								onIonChange={(e) => {
									setBranchSubBranch(e.detail.value);
								}}
								clearInput></IonInput>
						</IonItem>
					</div>
				</div> */}
				{/* <div>
					<p className='text-16 text-black-1000 text-left'>
						{selectedBank.type === "hub"
							? ekkLanguage.depositForm.hubAddress
							: ekkLanguage.depositForm.bankAddress}
					</p>
					<div className='w-full pb-[30px]'>
						<IonItem>
							<IonInput
								name='bankAddress'
								type='string'
								value={bankAddress}
								placeholder={`write details ${
									selectedBank.type === "hub"
										? ekkLanguage.depositForm.hubAddress
										: ekkLanguage.depositForm.bankAddress
								}`}
								onIonChange={(e) => {
									setBankAddress(e.detail.value);
								}}
								clearInput></IonInput>
						</IonItem>
					</div>
				</div> */}
				<div>
					<div className='flex items-center justify-between'>
						<p className='text-16 text-black-1000'>
							{ekkLanguage.payment.payFullCredit}
						</p>
						{/* <IonToggle
							checked={checked}
							onIonChange={(e) => setChecked(e.detail.checked)}
						/> */}
					</div>

					<div className={`flex mb-1 business-location`}>
						<div className='w-full pb-[30px]'>
							<IonItem>
								<IonInput
									type='number'
									name='collectedAmount'
									value={payAmount}
									disabled
									placeholder={
										ekkLanguage.payment.payFullCreditPlaceholder
									}
									// onIonChange={(e) => {
									// 	setPayAmount(e.detail.value);
									// }}
									clearInput></IonInput>
							</IonItem>
						</div>
					</div>
				</div>
				<div>
					<p className='text-16 text-black-1000 text-left'>
						{ekkLanguage.depositForm.comment}
					</p>
					<div className='w-full'>
						<IonItem>
							<IonTextarea
								name='comment'
								cols={3}
								rows='15'
								value={comment}
								placeholder={ekkLanguage.depositForm.commentPlaceholder}
								onIonChange={(e) => {
									setComment(e.detail.value);
								}}
								clearInput></IonTextarea>
						</IonItem>
					</div>
				</div>
			</div>
		</div>
	);
}
