export const authBox_Block = {
	PHONE_NUMBER_BLOCK: 1,
	PASSWORD_BLOCK: 2,
	TOKEN: 3,
	CHANGE_PASS_BLOCK: 4,
	REGISTRATION_BLOCK: 5,
	CONGRESS_BLOCK: 6,
};

export const forgotPass_block = {
	PHONE_NUMBER_BLOCK: 1,
	TOKEN: 2,
	CHANGE_PASS_BLOCK: 3,
	CONGRESS_BLOCK: 4,
};
