// export const bankListArray = [
// 	{
// 		id: 1,
// 		type: "mfs",
// 		logo: "/assets/images/bank/bkash.png",
// 		image: "/assets/images/bank/bkash.png",
// 		name: "Bkash",
// 		numberAgent: "01745386138",
// 		numberPersonal: "01745386138",
// 		numberMerchant: "01745386138",
// 		countryName: "Bangladesh",
// 		countryShortName: "BD",
// 	},
// 	{
// 		id: 2,
// 		type: "mfs",
// 		logo: "/assets/images/bank/nagad.png",
// 		image: "/assets/images/bank/nagad.png",
// 		name: "Nagad",
// 		numberAgent: "01745386138",
// 		numberPersonal: "01745386138",
// 		numberMerchant: "01745386138",
// 		countryName: "Bangladesh",
// 		countryShortName: "BD",
// 	},
// 	{
// 		id: 3,
// 		type: "mfs",
// 		logo: "/assets/images/bank/upay.png",
// 		image: "/assets/images/bank/upay.png",
// 		name: "Upay",
// 		numberAgent: "01745386138",
// 		numberPersonal: "01745386138",
// 		numberMerchant: "01745386138",
// 		countryName: "Bangladesh",
// 		countryShortName: "BD",
// 	},
// 	{
// 		id: 4,
// 		type: "bank",
// 		logo: "/assets/images/bank/bank-asia.png",
// 		image: "/assets/images/bank-asia.png",
// 		name: "Bank Asia",
// 		numberAgent: "01745386138",
// 		numberPersonal: "01745386138",
// 		numberMerchant: "01745386138",
// 		acName: "EkkBaz Bangladesh Pvt Ltd.",
// 		acNumber: "044330013280",
// 		routeNumber: "70274187",
// 		branch: "Mogbazar Branch",
// 		countryName: "Bangladesh",
// 		countryShortName: "BD",
// 	},
// ];

export const bankListArray = [
	{
		id: 1,
		type: "hub",
		logo: "/assets/images/bank/cash.png",
		image: "/assets/images/bank/cash.png",
		name: "Cash & Hub",
		acName: "EkkBaz Hub Payment.",
		acNumber: "044330013280",
		routeNumber: "70274187",
		branch: "Jatrabari Branch",
		countryName: "Bangladesh",
		countryShortName: "BD",
	},
	{
		id: 2,
		type: "bank",
		logo: "/assets/images/bank/islami-bank.png",
		image: "/assets/images/islami-bank.png",
		name: "Islami Bank limited",
		acName: "EkkBaz Bangladesh Pvt. ltd",
		acNumber: "20503110100185612",
		routeNumber: "125274245",
		branch: "Islami Bank Motijheel Branch, Dhaka",
		countryName: "Bangladesh",
		countryShortName: "BD",
	},
	{
		id: 3,
		type: "bank",
		logo: "/assets/images/bank/bank-asia.png",
		image: "/assets/images/bank-asia.png",
		name: "Bank Asia",
		acName: "EkkBaz Bangladesh Pvt Ltd.",
		acNumber: "044330013280",
		routeNumber: "70274187",
		branch: "Mogbazar Branch",
		countryName: "Bangladesh",
		countryShortName: "BD",
	},

	{
		id: 4,
		type: "mfs",
		logo: "/assets/images/bank/bkash.png",
		image: "/assets/images/bank/bkash.png",
		name: "Bkash",
		acName: "Bkash",
		acNumber: "01701898144",
		numberAgent: "01701898144",
		countryName: "Bangladesh",
		countryShortName: "BD",
	},
	{
		id: 5,
		type: "mfs",
		logo: "/assets/images/bank/nagad.png",
		image: "/assets/images/bank/nagad.png",
		name: "Nagad",
		acName: "Nagad",
		acNumber: "01701898144",
		numberAgent: "01701898144",
		countryName: "Bangladesh",
		countryShortName: "BD",
	},
];
