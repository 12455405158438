import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrder } from "redux/container/orderSlice";
import OrderStatusCard from "../Common/OrderList/OrderStatusCard";
import OrderInvoiceSearch from "./OrderInvoiceSearch";
import OrderStatusBtn from "./OrderStatusBtn";

export default function OrderStatus({ category }) {
	const orderList = useSelector((state) => state.order);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			fetchOrder(category, "Delivered,Partially_Delivered,Partially_Paid")
		);
	}, []);
	return (
		<div className='px-2 pt-14'>
			{/* <OrderInvoiceSearch /> */}
			{/* <OrderStatusBtn category={category} /> */}
			<OrderStatusCard
				response={orderList}
				ekkLanguage={ekkLanguage}
				category={category}
			/>
		</div>
	);
}
