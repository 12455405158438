import React, { useState } from "react";
import Sheet from "react-modal-sheet";
import {
	IonButton,
	IonItem,
	IonLabel,
	IonModal,
	IonRadio,
	IonRadioGroup,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setBottomSheet } from "redux/container/userSlice";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import Card from "../Common/Card";
import Modal from "react-modal";
import Orders from "services/Orders";
import { fetchOrderSingle } from "redux/container/orderSlice";

export default function BottomSheet({ order }) {
	const isOpenBottomSheet = useSelector((state) => state.user.bottomSheet);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [deliveryPosition, setDeliveryPosition] = useState(
		order?.delivery_info ? order?.delivery_info.delivery_msg : ""
	);
	const [failedStatus, setFailureStatus] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const onCloseModal = () => setIsOpen(false);
	const onOpenModal = () => setIsOpen(true);

	const deliveryFailedHandler = async () => {
		let query = {
			order_status: "delivery_failed",
			delivery_status: "Delivery Failed",
			delivery_msg: failedStatus,
		};
		let response = await Orders.orderUpdate(order._id, query);
		if (response.status === 200) {
			onCloseModal();
			dispatch(fetchOrderSingle(order._id));
		}
	};
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "calc(100% - 50px)",
			maxWidth: "500px",
			maxHeight: "calc(100% - 40px)",
			height: "auto",
			transition: ".4s all ease",
			borderRadius: 12,
			backgroundColor: "#F2F3F7",
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				isOpen={isOpenBottomSheet}
				snapPoints={[260, 260, 100, 0]}
				onClose={() => dispatch(setBottomSheet(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						{/* <IonItem
							button
							className='py-[5px]'
							// onClick={() =>
							// 	navigate(``)
							// }
							lines='none'>
							<a
								href='https://maps.app.goo.gl/Q1YF2YrDpSywTmtC9'
								className='flex items-center'>
								<span
									className='my-[5px] h-10 w-10 flex justify-center items-center rounded-full'
									style={{ background: "rgba(54, 169, 77, 0.15)" }}>
									<img
										src='/assets/images/icon/location-pin.png'
										alt='location'
									/>
								</span>
								<p className='text-14 font-normal text-black-1000 pl-2'>
									{ekkLanguage.orderDetails.googleMap}
								</p>
							</a>
						</IonItem> */}
						<IonItem
							button
							className='py-[5px]'
							onClick={() => navigate(`/deliver-form/${order._id}`)}
							lines='none'>
							<span
								className='my-[5px] h-10 w-10 flex justify-center items-center rounded-full'
								style={{ background: "rgba(54, 169, 77, 0.15)" }}>
								<img
									src='/assets/images/delivery-truck.png'
									alt='track'
								/>
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.orderDetails.deliver}
							</p>
						</IonItem>
						<IonItem
							button
							className='py-[5px]'
							lines='none'
							onClick={() => {
								onOpenModal();
								dispatch(setBottomSheet(false));
							}}>
							<span
								className='my-[5px] h-10 w-10 flex justify-center items-center rounded-full'
								style={{ background: "rgba(249, 75, 65, 0.15)" }}>
								<img src='/assets/images/truck.png' alt='track' />
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.deliverForm.failDelivery}
							</p>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop onClick={() => dispatch(setBottomSheet(false))} />
			</Sheet>
			<div>
				<Modal
					style={customStyles}
					closeTimeoutMS={200}
					isOpen={isOpen}
					onRequestClose={onCloseModal}>
					<div>
						<Card
							title='Delivery Fail'
							onHide={true}
							closeModal={onCloseModal}>
							<div>
								<div className='pt-5'>
									<IonRadioGroup
										defaultValue={deliveryPosition}
										value={deliveryPosition}
										onIonChange={(e) => {
											setFailureStatus(e.detail.value);
											setDeliveryPosition(e.detail.value);
										}}>
										<IonItem
											lines='none'
											className='bg-white border rounded-[10px] mb-4 no-ripple'>
											<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
												{ekkLanguage.orderDetails.onTimeClose}
											</IonLabel>
											<IonRadio
												slot='start'
												value={ekkLanguage.orderDetails.onTimeClose}
												color='success'
											/>
										</IonItem>
										<IonItem
											lines='none'
											className='bg-white border rounded-[10px] mb-5 no-ripple'>
											<IonLabel className=' border-0 font-sans text-14 text-black-500 font-medium'>
												<div className='flex'>
													{ekkLanguage.orderDetails.onTimeDontWant}
												</div>
											</IonLabel>
											<IonRadio
												slot='start'
												value={
													ekkLanguage.orderDetails.onTimeDontWant
												}
												color='success'
											/>
										</IonItem>
										<IonItem
											lines='none'
											className='bg-white border rounded-[10px] mb-5 no-ripple'>
											<IonLabel className=' border-0 font-sans text-14 text-black-500 font-medium'>
												<div className='flex'>
													{ekkLanguage.orderDetails.lateTimeClose}
												</div>
											</IonLabel>
											<IonRadio
												slot='start'
												value={
													ekkLanguage.orderDetails.lateTimeClose
												}
												color='success'
											/>
										</IonItem>
										<IonItem
											lines='none'
											className='bg-white border rounded-[10px] mb-4 no-ripple'>
											<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
												{ekkLanguage.orderDetails.lateTimeDontWant}
											</IonLabel>
											<IonRadio
												slot='start'
												value={
													ekkLanguage.orderDetails.lateTimeDontWant
												}
												color='success'
											/>
										</IonItem>
										<IonItem
											lines='none'
											className='bg-white border rounded-[10px] mb-4 no-ripple'>
											<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
												{ekkLanguage.orderDetails.notEnoughProduct}
											</IonLabel>
											<IonRadio
												slot='start'
												notEnoughProduct
												value={
													ekkLanguage.orderDetails.notEnoughProduct
												}
												color='success'
											/>
										</IonItem>
										<IonItem
											lines='none'
											className='bg-white border rounded-[10px] mb-4 no-ripple'>
											<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
												{ekkLanguage.orderDetails.notFound}
											</IonLabel>
											<IonRadio
												slot='start'
												notEnoughProduct
												value={ekkLanguage.orderDetails.notFound}
												color='success'
											/>
										</IonItem>
										<IonItem
											lines='none'
											className='bg-white border rounded-[10px] mb-4 no-ripple'>
											<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
												{ekkLanguage.orderDetails.shopNotFound}
											</IonLabel>
											<IonRadio
												slot='start'
												notEnoughProduct
												value={
													ekkLanguage.orderDetails.shopNotFound
												}
												color='success'
											/>
										</IonItem>
										<IonItem
											lines='none'
											className='bg-white border rounded-[10px] mb-4 no-ripple'>
											<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
												{ekkLanguage.orderDetails.notAccept}
											</IonLabel>
											<IonRadio
												slot='start'
												notEnoughProduct
												value={ekkLanguage.orderDetails.notAccept}
												color='success'
											/>
										</IonItem>
										<IonItem
											lines='none'
											className='bg-white border rounded-[10px] mb-4 no-ripple'>
											<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
												{ekkLanguage.orderDetails.supplierNotGiven}
											</IonLabel>
											<IonRadio
												slot='start'
												notEnoughProduct
												value={
													ekkLanguage.orderDetails.supplierNotGiven
												}
												color='success'
											/>
										</IonItem>
									</IonRadioGroup>
								</div>
								<div className='text-center pt-0'>
									<IonButton
										color='primary'
										onClick={deliveryFailedHandler}>
										{ekkLanguage.orderDetails.confirm}
									</IonButton>
								</div>
							</div>
						</Card>
					</div>
				</Modal>
			</div>
		</div>
	);
}
