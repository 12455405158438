import Calendar from "assets/images/icon/svg/Calendar";
import { getUser } from "config/authCommon";
import { bankListArray } from "data/bankList";
import { digitChanger, translator } from "helpers/helper";
import moment from "moment";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { fetchOrderSingle } from "redux/container/orderSlice";
import { setDepositBottomSheet } from "redux/container/userSlice";
import Deposit from "services/Deposit";
import User from "services/User";
import CustomModal from "../Common/Modal";
import Signature from "../Common/Signature";
import UploadImage from "../Common/UploadImage";
import BankMeta from "./BankMeta";
import DatedAmountCards from "./DatedAmountCards";
import FullDepositForm from "./FullDepositForm";
import TotalAmount from "./TotalAmount";
import { IonButton } from "@ionic/react";
import SelectOrders from "./SelectOrders";
import Orders from "services/Orders";

export default function DepositForm() {
  let [loader, setLoader] = useState();
  let user = getUser();
  let { id } = useParams();
  const response = useSelector((state) => state.order.singleOrder);
  const [isOpen, setIsOpen] = useState(false);
  let [signatureImage, setSignatureImage] = useState(null);
  let [cameraImage, setCameraImage] = useState(null);
  const [selectedDeliveryDate, setSelectedDeliveryDate] =
    useState("Select A Date");
  const [creditValidationModal, setCreditValidationModal] = useState(false);
  const [dateValidationModal, setDateValidationModal] = useState(false);
  const [signatureValidationModal, setSignatureValidationModal] =
    useState(false);
  const [cameraValidationModal, setCameraValidationModal] = useState(false);
  const [restAmount, setRestAmount] = useState("");
  const [amount, setAmount] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();
  const onCloseModal = () => setIsOpen(false);
  const onOpenCreditValidationModal = () => setCreditValidationModal(true);
  const onCloseCreditValidationModal = () => setCreditValidationModal(false);
  const onOpenDateValidationModal = () => setDateValidationModal(true);
  const onCloseDateValidationModal = () => setDateValidationModal(false);
  const onOpenSignatureValidationModal = () =>
    setSignatureValidationModal(true);
  const onCloseSignatureValidationModal = () =>
    setSignatureValidationModal(false);
  const onOpenCameraValidationModal = () => setCameraValidationModal(true);
  const onCloseCameraValidationModal = () => setCameraValidationModal(false);

  const ekkLanguage = useSelector((state) => state.lan.lan);
  let [remaining, setRemaining] = useState();
  const navigate = useNavigate();
  let [signatureImageURL, setSignatureImageURL] = useState(null);
  const [collectionList, setCollectionList] = useState([]);
  const [selectedCollectionList, setSelectedCollectionList] = useState([]);
  let [cameraImageURL, setCameraImageURL] = useState(null);
  const [collectionAmount, setCollectionAmount] = useState([]);
  const [depositTicketNumber, setDepositTicketNumber] = useState("");
  const [transactionNumber, setTransactionNumber] = useState("");
  const [branchSubBranch, setBranchSubBranch] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [payAmount, setPayAmount] = useState(0);
  const [comment, setComment] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const [collectorLoader, setCollectorLoader] = useState(false);
  const [totalDepositAmount, setTotalDepositAmount] = useState("");
  const [todayAmount, setTodayAmount] = useState({});
  const [yesterDayAmount, setYesterDayAmount] = useState({});
  const [dayAmountLoader, setDayAmountLoader] = useState(false);

  let selectedBank = bankListArray.find((el) => el.id === Number(id));

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      width: "300px",
      transition: ".4s all ease",
      borderRadius: 12,
      backgroundColor: "#F2F3F7",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: ".4s all ease",
      backgroundColor: "rgba(0,0,0,.5)",
    },
  };
  useEffect(() => {
    dispatch(fetchOrderSingle(id));
  }, []);

  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }
  const imageUrlCollect = async () => {
    setImageLoader(true);
    // const signatureFile = DataURIToBlob(signatureImage);
    // const signData = new FormData();
    // signData.append("key", signatureFile, "image.jpg");
    const cameraFile = DataURIToBlob(cameraImage);
    const cameraData = new FormData();
    cameraData.append("key", cameraFile, "image.jpg");

    // let signResponse = await User.toBucketImage(signData);
    let cameraResponse = await User.toBucketImage(cameraData);
    if (cameraResponse.status === 201) {
      // setSignatureImageURL(signResponse?.results);
      setCameraImageURL(cameraResponse?.results);
      setIsOpen(true);
      setImageLoader(false);
    } else {
      setImageLoader(false);
    }
  };

  const orderSubmit = async () => {
    setLoader(true);

    let query = {
      depositTicketNumber,
      transactionNumber,
      branchSubBranch,
      bankAddress,
      userId: user._id,
      payAmount: Number(payAmount),
      collectedAmount: collectionAmount?.total_amount - totalDepositAmount,
      // signature: signatureImageURL[0],
      depositSlip: cameraImageURL[0],
      bankName: selectedBank?.name,
      totalOrders: collectionAmount?.total_orders,
      comment,
      userName: user.full_name,
      country: user.country,
      accountName: selectedBank?.acName,
      accountNumber: selectedBank?.acNumber,
      routeNumber: selectedBank?.routeNumber,
      todayCollection: todayAmount?.total_amount ?? 0,
      yesterdayCollection: yesterDayAmount?.total_amount ?? 0,
      collectedList: selectedCollectionList,
      totalDue: collectionList?.dueTotal,
      totalCollection: collectionList?.allTotal,
      totalDeposit: collectionList?.collectedTotal,
    };
    let resp = await Deposit.collectionSubmit(query);
    if (resp.success === true) {
      setLoader(false);
      navigate("/success-deposit");
      // onOpenCreditValidationModal();
      setIsOpen(false);
      // dispatch(setSingleOrder(resp.data));
      setAmount();
      // dispatch(setBottomSheet(false));
      dispatch(setDepositBottomSheet(false));
    } else {
      setLoader(false);
    }
  };
  const getCollectionAmount = async () => {
    setCollectorLoader(true);
    let response = await Deposit.getTodayCollection();
    if (response.status === 200) {
      setCollectionAmount(response?.data[0]);
      setAmount(response?.data[0]?.total_amount);
      let resp = await Deposit.getDepositAmount();
      if (resp.status === 200) {
        setTotalDepositAmount(resp?.data?.totalAmount);
        let amount = response?.data[0]?.total_amount - resp?.data?.totalAmount;
        setAmount(amount);
        const res = await Orders.getCollectionsOrderList();
        if (res.status === 200) {
          setCollectionList(res?.data);
          setCollectorLoader(false);
        }
      } else {
        setCollectorLoader(false);
      }
    }
  };

  let now = moment().utc();
  let startTime = now.startOf("day").toString();
  let endTime = now.endOf("day").toString();

  let yesterDay = moment().subtract(1, "days").utc();
  let yesterdayStart = yesterDay.startOf("day").toString();
  let yesterdayEnd = yesterDay.endOf("day").toString();

  const getTodayCollectionAmount = async () => {
    setDayAmountLoader(true);
    let response = await Deposit.getDepositByDate(startTime, endTime);
    if (response.status === 200) {
      setDayAmountLoader(false);
      setTodayAmount(response.data[0]);
    } else {
      setDayAmountLoader(false);
    }
  };
  const getYesterdayCollectionAmount = async () => {
    setDayAmountLoader(true);
    let response = await Deposit.getDepositByDate(yesterdayStart, yesterdayEnd);
    if (response.status === 200) {
      setDayAmountLoader(false);
      setYesterDayAmount(response.data[0]);
    } else {
      setDayAmountLoader(false);
    }
  };

  useEffect(() => {
    getTodayCollectionAmount();
    getYesterdayCollectionAmount();
  }, []);

  useEffect(() => {
    getCollectionAmount();
  }, []);

  return (
    <>
      <div className="pt-14 px-4">
        <BankMeta selectedBank={selectedBank} />
        <TotalAmount
          collectorLoader={collectorLoader}
          collectionAmount={collectionAmount}
          amount={amount}
        />
        <DatedAmountCards
          todayAmount={todayAmount}
          yesterDayAmount={yesterDayAmount}
          dayAmountLoader={dayAmountLoader}
        />
        <div className="pb-[30px]">
          <h6 className="pb-[10px]">{ekkLanguage.depositForm.todayDate}</h6>
          <div
            className="bg-white rounded-[5px] px-4 py-3 flex"
            style={{ boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)" }}
          >
            <Calendar color="#000" />
            <span className="pl-2">
              {digitChanger(moment().format("DD-MM-YYYY"))}
            </span>
          </div>
        </div>
        <SelectOrders
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          collectorLoader={collectorLoader}
          collectionList={collectionList?.collection}
          selectedCollectionList={selectedCollectionList}
          setSelectedCollectionList={setSelectedCollectionList}
          setPayAmount={setPayAmount}
        />
        <FullDepositForm
          totalDepositAmount={totalDepositAmount}
          data={collectionAmount}
          restAmount={restAmount}
          setRestAmount={setRestAmount}
          remaining={remaining}
          setRemaining={setRemaining}
          depositTicketNumber={depositTicketNumber}
          setDepositTicketNumber={setDepositTicketNumber}
          transactionNumber={transactionNumber}
          setTransactionNumber={setTransactionNumber}
          branchSubBranch={branchSubBranch}
          setBranchSubBranch={setBranchSubBranch}
          bankAddress={bankAddress}
          setBankAddress={setBankAddress}
          payAmount={payAmount}
          setPayAmount={setPayAmount}
          comment={comment}
          setComment={setComment}
          amount={amount}
          setAmount={setAmount}
          selectedBank={selectedBank}
        />
        {/* <Signature
					setSignatureImage={setSignatureImage}
					signatureImage={signatureImage}
				/> */}
        <UploadImage
          cameraImage={cameraImage}
          setCameraImage={setCameraImage}
        />
      </div>
      <div className="pt-[80px] pb-[30px] px-4">
        <button
          onClick={() => {
            if (payAmount < 1) {
              onOpenCreditValidationModal();
            } else if (!cameraImage) {
              onOpenCameraValidationModal();
            } else {
              imageUrlCollect();
            }
          }}
          className="h-[50px] w-full rounded-[5px] bg-primary text-white
					text-24 font-bold flex justify-center items-center"
        >
          {imageLoader ? (
            <ClipLoader
              sizeUnit={"px"}
              size={25}
              color={"white"}
              loading={true}
            />
          ) : (
            ekkLanguage.depositsList.collectNow
          )}
        </button>
      </div>
      <Modal
        closeTimeoutMS={200}
        isOpen={isOpen}
        onRequestClose={onCloseModal}
        style={customStyles}
      >
        <div className="p-5 relative">
          <div
            onClick={onCloseModal}
            className="absolute right-3 top-3 h-7 w-7 flex items-center justify-center"
          >
            <i className="material-icons">close</i>
          </div>
          <img
            src="/assets/images/ekkbaz_icon.png"
            alt="logo"
            className="w-[50px] h-[40px] mb-2"
          />
          <h5 className="text-24 font-bold pb-7" style={{ color: "#0099CC" }}>
            {ekkLanguage.depositsList.confirmDeposits}
          </h5>
          <div className="flex justify-between items-center pb-5">
            <div>
              <img src={selectedBank?.image} alt="" />
            </div>
            <div>
              <p className="text-12 font-normal text-black-333">
                {ekkLanguage.depositForm.depositTicketNumber}
              </p>
              <h6 className="text-18 font-normal text-black-1000">
                {digitChanger(depositTicketNumber)}
              </h6>
            </div>
          </div>
          <div className="pb-5 text-center">
            <h5 className="text-18 font-bold text-black-1000 pb-[10px]">
              {ekkLanguage.payment.totalAmount}
            </h5>
            <h6 className="text-18 font-bold" style={{ color: "#0099CC" }}>
              {translator("BDT")} {digitChanger(Number(amount).toFixed(2))}
            </h6>
          </div>
          <div className="flex justify-between items-center pb-10">
            <div>
              <h6 className="text-14 font-normal text-success pb-[10px]">
                {ekkLanguage.depositsList.todayDeposits}
              </h6>
              <p className="text-14 font-normal text-success">
                {translator("BDT")} {digitChanger(Number(payAmount).toFixed(2))}
              </p>
              <p
                className="text-14 font-normal pt-5"
                style={{ color: "#B3B3B3" }}
              >
                {digitChanger(moment().format("DD-MM-YYYY"))}
              </p>
            </div>
            <div>
              <h6 className="text-14 font-normal text-primary pb-[10px]">
                {ekkLanguage.depositsList.dueDeposits}
              </h6>
              <p className="text-14 font-normal text-primary">
                {translator("BDT")}{" "}
                {digitChanger(
                  Number(
                    collectionAmount?.total_amount -
                      Number(payAmount) -
                      totalDepositAmount
                  ).toFixed(2)
                )}
              </p>
              <p
                className="text-14 font-normal pt-5"
                style={{ color: "#B3B3B3" }}
              >
                {digitChanger(
                  selectedDeliveryDate === "Select A Date"
                    ? moment(response?.payment_term?.paymentDueDate).format(
                        "DD-MM-YYYY"
                      )
                    : moment(selectedDeliveryDate).format("DD-MM-YYYY")
                )}
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center">
            {/* <div
							className='w-1/2 mr-[10px] h-[80px] rounded-[5px] overflow-hidden'
							style={{ backgroundColor: "#E6E6E6" }}>
							<img
								src={signatureImage}
								alt='signature'
								className='m-auto h-100 object-cover'
							/>
						</div> */}
            <div
              className="w-full mr-[10px] h-[80px] rounded-[5px] overflow-hidden"
              style={{ backgroundColor: "#E6E6E6" }}
            >
              <img
                src={cameraImage}
                alt="billImage"
                className="m-auto h-100 object-cover"
              />
            </div>
          </div>
          <div className="pt-[40px] pb-[20px] px-4">
            <IonButton
              disabled={loader ? true : false}
              onClick={orderSubmit}
              className="h-[50px] w-full rounded-[5px] bg-primary text-white text-24 font-bold"
            >
              {loader ? (
                <ClipLoader
                  sizeUnit={"px"}
                  size={25}
                  color={"white"}
                  loading={true}
                />
              ) : (
                ekkLanguage.depositsList.collectionDone
              )}
            </IonButton>
          </div>
        </div>
      </Modal>
      {/* credit modal*/}
      <CustomModal
        isOpen={creditValidationModal}
        onClose={onCloseCreditValidationModal}
        title={ekkLanguage.payment.creditWarning}
        btnText={ekkLanguage.payment.okBtnLabel}
      />
      {/* date modal*/}
      <CustomModal
        isOpen={dateValidationModal}
        onClose={onCloseDateValidationModal}
        title={ekkLanguage.payment.dateWarning}
        btnText={ekkLanguage.payment.okBtnLabel}
      />
      {/* camera modal*/}
      <CustomModal
        isOpen={cameraValidationModal}
        onClose={onCloseCameraValidationModal}
        title={ekkLanguage.payment.cameraWarning}
        btnText={ekkLanguage.payment.okBtnLabel}
      />
      {/*  signature modal*/}
      <CustomModal
        isOpen={signatureValidationModal}
        onClose={onCloseSignatureValidationModal}
        title={ekkLanguage.payment.signatureWarning}
        btnText={ekkLanguage.payment.okBtnLabel}
      />
    </>
  );
}
