import Card from "components/mobile-components/Common/Card";
import { digitChanger, translator } from "helpers/helper";
import { useSelector } from "react-redux";

export default function CostDetailsCard({ order }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	let creditFee;
	if (order?.payment_term?.duration === 0) {
		creditFee = 0;
	} else if (order?.payment_term?.duration === 1) {
		creditFee = 0.001 * order.total;
	} else if (order?.payment_term?.duration === 3) {
		creditFee = 0.005 * order.total;
	} else if (order?.payment_term?.duration === 7) {
		creditFee = 0.01 * order.total;
	}

	return (
		<div className='px-2 pb-5'>
			<Card title={ekkLanguage.orderDetails.castItemLabel}>
				<div>
					<table className='table-fixed w-full'>
						<tbody className="before:content-[' '] before:block before:h-2 after:content-[' '] after:block after:h-2">
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.subtotal}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{translator(order?.currency)}{" "}
									{digitChanger(order?.sub_total?.toFixed(2))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.tax}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{translator(order?.currency)}{" "}
									{digitChanger(order?.tax_total?.toFixed(2))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.discount}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{translator(order?.currency)}{" "}
									{digitChanger(Number(0.0).toFixed(2))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.deliveryFee}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{translator(order?.currency)}{" "}
									{digitChanger(
										Number(order?.deliveryFee ?? 0.0).toFixed(2)
									)}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.serviceFee}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{translator(order?.currency)}{" "}
									{digitChanger(creditFee?.toFixed(2))}
								</td>
							</tr>
						</tbody>
						<tfoot className='border-t'>
							<tr>
								<td className='text-primary text-12 font-bold py-[6px]'>
									{ekkLanguage.orderDetails.total}
								</td>
								<td className='text-primary text-12 font-bold py-[6px] text-right'>
									{translator(order?.currency)}{" "}
									{order?.order_status === "Partially_Delivered"
										? digitChanger(
												Number(order?.total + creditFee).toFixed(2)
										  )
										: digitChanger(Number(order?.total).toFixed(2))}
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</Card>
		</div>
	);
}
