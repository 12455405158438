import React from "react";
import Card from "../Common/Card";
import "moment-timezone";
import { useSelector } from "react-redux";
import { digitChanger } from "helpers/helper";

export default function DepositAccountCard({ bankInfo }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<div className='px-2 pb-5'>
			<Card title={ekkLanguage.bankDetailsPage.depositAC}>
				<div>
					<table className='table-fixed w-full'>
						<tbody className="before:content-[' '] before:block before:h-2">
							{bankInfo.bankName === "EkkBaz Hub" ? (
								<>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											Receiver Name
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											Md Repon Hossain
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											Receiver Mobile Number
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											01716203305
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetailsPage.branch}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{bankInfo?.branchSubBranch}
										</td>
									</tr>
								</>
							) : (
								<>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetailsPage.bankName}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{bankInfo?.bankName}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetailsPage.acName}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{bankInfo?.accountName}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetailsPage.acNumber}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{bankInfo?.accountNumber &&
												digitChanger(bankInfo?.accountNumber)}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetailsPage.routeNumber}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{bankInfo?.routeNumber &&
												digitChanger(bankInfo?.routeNumber)}
										</td>
									</tr>
									<tr>
										<td className='text-000080 text-12 font-medium py-[6px]'>
											{ekkLanguage.bankDetailsPage.branch}
										</td>
										<td className='text-000080 text-12 font-medium py-[6px] text-right'>
											{bankInfo?.branchSubBranch}
										</td>
									</tr>
								</>
							)}
						</tbody>
					</table>
				</div>
			</Card>
		</div>
	);
}
