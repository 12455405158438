import React from "react";

export default function Document() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='18'
			viewBox='0 0 16 18'
			fill='none'>
			<path
				d='M11.0977 12.5195H5.08105'
				stroke='black'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M11.0977 9.03076H5.08105'
				stroke='black'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M7.37689 5.55009H5.08105'
				stroke='black'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.2576 1.2915C11.2576 1.2915 4.86014 1.29484 4.85014 1.29484C2.55014 1.309 1.12598 2.82234 1.12598 5.13067V12.794C1.12598 15.114 2.56098 16.6332 4.88098 16.6332C4.88098 16.6332 11.2776 16.6307 11.2885 16.6307C13.5885 16.6165 15.0135 15.1023 15.0135 12.794V5.13067C15.0135 2.81067 13.5776 1.2915 11.2576 1.2915Z'
				stroke='black'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
