import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import { bankListArray } from "data/bankList";
import "moment-timezone";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

let verified = require("assets/images/verified.png");
let notVerified = require("assets/images/notVerified.png");

export default function BankCard({ depositList, loading }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<>
			{loading ? (
				<IonLoading
					isOpen={loading}
					message={ekkLanguage.orderList.loader}
				/>
			) : (
				<IonGrid>
					{Array.isArray(depositList) && depositList.length > 0 ? (
						<IonRow>
							{depositList &&
								depositList.map((list, index) => {
									let logo = bankListArray.find(
										(el) => el.name === list.bankName
									);
									return (
										<IonCol size='12' key={index}>
											<Link to={`/bank-details/${list._id}`}>
												<div
													className='mb-2 rounded-[10px] overflow-hidden bg-white pt-[5px]'
													style={{
														boxShadow: `0px 5px 15px rgba(0, 0, 0, 0.05)`,
													}}>
													<div
														className={`py-[7px]  px-4 relative`}>
														<div className='absolute left-[50%] top-[8px] translate-x-[-50%]'>
															<img
																src={
																	list.status !== "unVerify"
																		? verified
																		: notVerified
																}
																alt='checkVerified'
															/>
														</div>
														<ul className='flex items-center justify-between'>
															<li className='w-[140px] h-[24px]'>
																<img
																	className='h-full'
																	src={logo?.image}
																	alt='bank'
																/>
															</li>
															<li>
																<h6 className='font-bold text-18 text-delivered'>
																	৳{" "}
																	{list.collectedAmount &&
																		Number(
																			list.payAmount
																		).toFixed(2)}
																</h6>
															</li>
														</ul>
													</div>
													<div className='px-4 py-[7px]'>
														<ul className='flex'>
															<li className='w-1/2'>
																<p className='text-black-333 text-10 font-normal py-[3px] flex'>
																	{
																		ekkLanguage.depositsList
																			.totalOrder
																	}
																	{" - "}
																	<span className='text-black-500 text-10 font-bold'>
																		{list.totalOrders}
																	</span>
																</p>

																<p className='text-black-333 text-10 font-normal py-[3px] flex'>
																	{
																		ekkLanguage.depositsList
																			.date
																	}
																	{" - "}
																	<span className='text-black-500 text-10 font-bold'>
																		{moment(
																			list.createdAt
																		).format("DD-MM-YYYY")}
																	</span>
																</p>
															</li>
															<li>
																<p className='text-black-333 text-10 font-normal py-[3px] flex'>
																	{
																		ekkLanguage.depositsList
																			.ticketNumber
																	}
																	{" - "}
																	<span className='text-black-500 text-10 font-bold'>
																		{list.depositTicketNumber}
																	</span>
																</p>
																<p className='text-black-333 text-10 font-normal py-[3px] flex'>
																	{
																		ekkLanguage.depositsList
																			.branch
																	}
																	{" - "}
																	<span className='text-black-500 text-10 font-bold'>
																		{list.branchSubBranch}
																	</span>
																</p>
															</li>
														</ul>
													</div>
												</div>
											</Link>
										</IonCol>
									);
								})}
						</IonRow>
					) : (
						<IonRow>
							<IonCol size='2'></IonCol>
							<IonCol size='8'>
								<div
									className='text-center flex items-center justify-center'
									style={{ height: "calc(100vh - 420px)" }}>
									<div>
										<img
											className='m-auto mb-[10px] mt-5'
											src='assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-16 font-normal text-222222 mb-[35px]'>
											{ekkLanguage.orderList.notFound}
										</p>
									</div>
								</div>
							</IonCol>
						</IonRow>
					)}
				</IonGrid>
			)}
		</>
	);
}
