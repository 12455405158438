import Document from "assets/images/icon/svg/Document";
import Download2 from "assets/images/icon/svg/Download2";
import CheckBusiness from "components/CheckBusiness";
import { getUser, getUserBusiness } from "config/authCommon";
import DeviceDetector from "device-detector-js";
import { digitChanger, hasActive } from "helpers/helper";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import {
	Menu,
	MenuItem,
	ProSidebar,
	SidebarContent,
	SidebarFooter,
	SidebarHeader,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { setPwaDownloadData } from "redux/container/userSlice";
import Scrollbar from "smooth-scrollbar";
import "./styles/_SidebarComponent.scss";

const LeftSidebar = ({ isMobile, menuCollapse, menuIconClick }) => {
	const { pathname } = useLocation();
	let dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const pwaObject = useSelector((state) => state.user);
	const [isOpen, setIsOpen] = useState(false);
	let user = getUser();
	let userBusiness = getUserBusiness();
	const userData = useSelector((state) => state.user.data);

	useEffect(() => {
		Modal.setAppElement("body");
		Scrollbar.init(document.querySelector("#sidebar-scrollbar"));
	});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handler = (e) => {
		e.preventDefault();
		dispatch(setPwaDownloadData(e));
	};
	useEffect(() => {
		window.addEventListener("beforeinstallprompt", handler);
		return () => window.removeEventListener("beforeinstallprompt", handler);
	}, [pathname, handler]);

	const onClick = () => {
		if (!pwaObject.pwaDownloadData) {
			return;
		}
		pwaObject.pwaDownloadData.prompt();
		setIsOpen(false);
	};
	const onCloseModal = () => {
		setIsOpen(false);
	};
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	const deviceDetector = new DeviceDetector();
	const userAgent = navigator.userAgent;
	const device = deviceDetector.parse(userAgent);

	return (
		<>
			<CheckBusiness />
			<div
				onClick={menuIconClick}
				className={`back-drop w-full h-full bg-black-1000 z-[100]  opacity-40 transition-opacity ${
					menuCollapse
						? "opacity-0 invisible absolute"
						: "opacity-40 visible fixed"
				}`}></div>
			<div
				className={`sidebar ${isMobile ? "mobiel-sidebar" : ""}`}
				style={
					menuCollapse
						? {
								width: 0,
								zIndex: 1000000,
						  }
						: {
								width: "300px",
								zIndex: 1000000,
						  }
				}>
				<div id='sidebar-scrollbar'>
					<span
						className='absolute top-2 right-2 text-black p-1 cursor-pointer'
						style={{ zIndex: 1111, lineHeight: 1 }}
						onClick={menuIconClick}>
						<i className='material-icons'>close</i>
					</span>
					<nav className='sidebar-menu'>
						<ProSidebar collapsed={menuCollapse} className='relative'>
							{!menuCollapse && (
								<SidebarHeader>
									<Link to='/profile'>
										<div
											className='logo-box flex items-center pt-[30px] px-5 pb-5'
											// style={{
											// 	background:
											// 		"url(assets/images/sidebarBg.png)",
											// 	position: "relative",
											// }}
										>
											<div className='user-img mr-[10px] '>
												{userData?.profile_image ? (
													<img
														src={userData?.profile_image}
														alt='avatar'
														className='h-[80px] w-[80px] rounded-full'
													/>
												) : (
													<img
														src={user?.profile_image}
														alt='user_avater'
														className='h-[80px] w-[80px] rounded-full'
													/>
												)}
											</div>
											<div className='user-info'>
												<p className='text-16 font-semibold text-222222 pb-[5px]'>
													{user?.full_name}
												</p>
												<p className='text-10 pb-[5px]'>
													{digitChanger(user?.PhoneNumber)}
												</p>
												<p className='text-10 pb-[5px]'>
													{userBusiness?.business_name}
												</p>
												<span className='text-12'>
													{userBusiness?.business_type?.title}
													{" - "}
													{userBusiness?.business_status}
													{" - "}
													{userBusiness?.role}
												</span>
											</div>
										</div>
									</Link>
								</SidebarHeader>
							)}
							<SidebarContent>
								<Menu iconShape='square'>
									<MenuItem
										className={hasActive("/", pathname)}
										icon={<i className='material-icons'>home</i>}>
										{ekkLanguage.letNav.homeMenuLabel}
										<NavLink to={`/`} />
									</MenuItem>
									<MenuItem
										className={hasActive("/deliver-list", pathname)}
										icon={
											<img
												src='/assets/images/driverNav.png'
												alt='driver'
											/>
										}>
										{ekkLanguage.letNav.driverList}
										<NavLink to={`/deliver-list`} />
									</MenuItem>
									<MenuItem
										// icon={<i className='material-icons'>list_alt</i>}
										icon={<Document />}
										className={hasActive(
											"/orders-for-collector/collector",
											pathname
										)}>
										{ekkLanguage.letNav.orderMenuLabel}
										<NavLink to={`/orders-for-collector/collector`} />
									</MenuItem>
									<MenuItem
										className={hasActive("/deposits-list", pathname)}
										icon={
											<img
												src='/assets/images/money.png'
												alt='money'
											/>
										}>
										{ekkLanguage.letNav.depositsList}
										<NavLink to={`/deposits-list`} />
									</MenuItem>
								</Menu>
							</SidebarContent>
							<SidebarFooter>
								{pwaObject.pwaDownloadData !== undefined ? (
									<div
										className={`text-center cursor-pointer p-4  flex justify-center items-center`}
										onClick={() => onClick()}>
										<Download2 color='#F94B41' />
										<p
											className={`text-primary text-14 font-semibold pl-3 pt-1`}>
											Download EkkHero App
										</p>
									</div>
								) : (
									<p
										className={`text-primary text-14 text-center font-semibold pl-3 pt-3 py-3`}>
										Installed{" "}
										{device?.device === "desktop"
											? device?.device?.brand
											: device?.device?.model}
									</p>
								)}
							</SidebarFooter>
						</ProSidebar>
					</nav>
				</div>
			</div>
		</>
	);
};

export default LeftSidebar;
