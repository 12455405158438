import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import DepositForm from "components/mobile-components/DepositForm";
import Payment from "components/mobile-components/Payment";
import React from "react";
import { useSelector } from "react-redux";

export default function DepositFormPage() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  return (
    <IonApp>
      <IonPage className="">
        <IonContent>
          <BackButtonTitle title={ekkLanguage.depositForm.pageTitle} />
          <DepositForm />
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
