import { IonApp, IonButton, IonCol, IonContent, IonGrid, IonPage } from "@ionic/react";
import { clearAuth } from "config/auth";
import { getLocalUser } from "config/authCommon";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { actions } from "redux/container/authSlice";

export default function Error() {
	const navigate = useNavigate();
	let user = getLocalUser();
	let dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const urlPath = useLocation();

	return (
		<IonApp>
			{(user?.associated_businesses.length === 0) |
			(urlPath.pathname === "/select-business") ? (
				<IonPage className='bg-white'>
					<IonContent>
						<IonGrid>
							<IonCol size='12'>
								<div className='flex items-center justify-center text-center h-screen bg-white px-4'>
									<div>
										<img
											src='assets/images/alert.png'
											alt='icon'
											className='inharit m-auto'
										/>

										<p className='text-14 text-primary font-bold mb-10'>
											{ekkLanguage.error.warning}
										</p>

										<Link
											to='/'
											className='w-full'
											onClick={() => {
												dispatch(actions.logout());
												localStorage.removeItem("master_token");
												localStorage.removeItem("_u");
												localStorage.removeItem("_b");
												localStorage.removeItem("cb");
												localStorage.setItem("auth", "false");
												clearAuth();
												navigate("/");
											}}>
											<IonButton
												expand='block'
												className='rounded-[10px] h-12 w-full text-white font-bold bg-primary text-18'
												style={{
													boxShadow:
														"0px 5px 15px rgba(0, 0, 0, 0.2)",
												}}>
												{ekkLanguage.error.logout}
											</IonButton>
										</Link>
									</div>
								</div>
							</IonCol>
						</IonGrid>
					</IonContent>
				</IonPage>
			) : (
				<IonPage className='business-location'>
					<IonContent>
						<div className='flex items-center justify-center h-screen'>
							<div className='text-center'>
								<h4 className='text-18 text-black-1000 font-bold pb-6'>
									{ekkLanguage.error.notFound}
								</h4>

								<IonButton
									color='primary'
									className='font-bold h-10 text-14'
									onClick={() => {
										navigate("/");
									}}>
									{ekkLanguage.error.btn}
								</IonButton>
							</div>
						</div>
					</IonContent>
				</IonPage>
			)}
		</IonApp>
	);
}
