import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectors } from "redux/container/authSlice";
import React from "react";

export default function PrivateRoutes({ children: Component }) {
	const { loginStatus } = useSelector(selectors.getAuth);
	// const { token } = getAuth();
	// return !token ? <Navigate to='/login' replace /> : children;

	return !loginStatus ? <Navigate to='/login' /> : Component;
}
