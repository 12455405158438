import { IonInput, IonItem, IonToggle } from "@ionic/react";
import { digitChanger, translator } from "helpers/helper";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function FullCredit({
	data,
	restAmount,
	setRestAmount,
	remaining,
	setRemaining,
}) {
	const [checked, setChecked] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	useEffect(() => {
		setRemaining(Number(data?.total - data?.total_paid).toFixed(2));
	}, [data]);

	// useEffect(() => {
	// 	checked ? setRemaining(0) : setRemaining(data?.total - data?.total_paid);
	// }, [checked]);

	// useEffect(() => {
	// 	restAmount > 0 < data?.total - data?.total_paid - Number(restAmount) + 2
	// 		? setRemaining(data?.total - data?.total_paid - Number(restAmount))
	// 		: setRemaining(data?.total - data?.total_paid);
	// }, [restAmount]);

	// useEffect(() => {
	// 	setRemaining(data?.total - data?.total_paid - Number(restAmount));
	// }, [restAmount]);

	return (
		<div>
			<div
				className='text-center py-4 bg-white rounded-[10px] mt-4 px-4 mb-7'
				style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)" }}>
				<div className='flex items-center justify-between'>
					<p className='text-16 text-black-1000'>
						{ekkLanguage.payment.payFullCredit}
					</p>
					{/* <IonToggle
						checked={checked}
						onIonChange={(e) => {
							setChecked(e.detail.checked);
							if (e.detail.checked) {
								setRemaining(0);
								setRestAmount(Number(data?.total - data?.total_paid));
							} else {
								setRestAmount(null);
								setRemaining(
									Number(data?.total - data?.total_paid).toFixed(2)
								);
							}
						}}
					/> */}
				</div>

				<div className={`flex mb-1 business-location pt-6`}>
					<div className='w-full'>
						<IonItem>
							<IonInput
								type='number'
								value={restAmount}
								placeholder={
									ekkLanguage.payment.payFullCreditPlaceholder
								}
								onIonChange={(e) => {
									if (e.detail.value > -1) {
										setRemaining(
											data?.total - data?.total_paid - e.detail.value
										);
										setRestAmount(e.detail.value);
									}
								}}
								readonly={checked}
								clearInput></IonInput>
						</IonItem>
					</div>
				</div>
			</div>
			<div
				className='text-center py-4 bg-white rounded-[10px] mt-4 px-4 mb-7'
				style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)" }}>
				<div className='flex items-center justify-between'>
					<p className='text-16 text-black-1000 font-bold'>
						{ekkLanguage.payment.fullPayment}
					</p>
					<IonToggle
						checked={checked}
						onIonChange={(e) => {
							setChecked(e.detail.checked);
							if (e.detail.checked) {
								setRemaining(0);
								setRestAmount(Number(data?.total - data?.total_paid));
							} else {
								setRestAmount(null);
								setRemaining(
									Number(data?.total - data?.total_paid).toFixed(2)
								);
							}
						}}
					/>
				</div>
			</div>
			<div className='flex items-center justify-between'>
				<p className='text-16 text-black-1000 font-semibold'>
					{ekkLanguage.payment.credit}
				</p>
				<p className='text-16 text-black-1000 font-bold '>
					{data?.country?.currency_symbol_native}{" "}
					{digitChanger(Number(remaining).toFixed(2))}
				</p>
			</div>
		</div>
	);
}
