import { IonApp } from "@ionic/react";
import Login from "pages/Auth/Login";
import BankDetailsPage from "pages/MobilePage/BankDetailsPage";
import BankListPage from "pages/MobilePage/BankListPage";
import ChangeLanguagePage from "pages/MobilePage/ChangeLanguagePage";
import ChangePasswordPage from "pages/MobilePage/ChangePasswordPage";
import ChangePasswordSuccessPage from "pages/MobilePage/ChangePasswordSuccessPage";
import CollectionHistoryListDetailsPage from "pages/MobilePage/CollectionHistoryListDetailsPage";
import CollectionHistoryListPage from "pages/MobilePage/CollectionHistoryListPage";
import CollectionSuccessPage from "pages/MobilePage/CollectionSuccess";
import CongratulationPage from "pages/MobilePage/CongratulationPage";
import CongressDepositPage from "pages/MobilePage/CongressDepositPage";
import ContactPage from "pages/MobilePage/ContactPage";
import DeliverFormPage from "pages/MobilePage/DeliverFormPage";
import DeliverySuccessPage from "pages/MobilePage/DeliverySuccessPage";
import DepositFormPage from "pages/MobilePage/DepositFormPage";
import DepositsListPage from "pages/MobilePage/DepositsListPage";
import DriverOrderDetailsPage from "pages/MobilePage/DriverOrderDetailsPage";
import DriverOrderListPage from "pages/MobilePage/DriverOrderListPage";
import Error from "pages/MobilePage/Error";
import OrderDetailsPage from "pages/MobilePage/OrderDetailsPage";
import OrderHistoryListPage from "pages/MobilePage/OrderHistoryListPage";
import OrderList from "pages/MobilePage/OrderList";
import PaymentPage from "pages/MobilePage/PaymentPage";
import ProfilePage from "pages/MobilePage/ProfilePage";
import ProfileVarifyOtpPage from "pages/MobilePage/ProfileVarifyOtpPage";
import ProfileVarifyPage from "pages/MobilePage/ProfileVarifyPage";
import SelectBusiness from "pages/MobilePage/SelectBusiness";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { selectors } from "redux/container/authSlice";
import PrivateOutlet from "./src/routes/PrivateOutlet";
import HomePage from "pages/MobilePage/Home";
import AnalysesDetailsPage from "pages/MobilePage/AnalysesDetailsPage";
import AutoLogoutPage from "pages/MobilePage/AutoLogoutPage";
import Landing from "pages/Landing";
import BlackListedPage from "pages/MobilePage/BlackListedPage";
import PhoneNumberCheck from "pages/LandingPageV2/PhoneNumberCheck";
import PasswordV2 from "pages/LandingPageV2/PasswordV2";

function Mobile() {
	const { loginStatus } = useSelector(selectors.getAuth);

	return (
		<>
			<Routes>
				<Route path='/' element={loginStatus ? <HomePage /> : <Landing />} />

				{/* <Route path='/login' element={<Landing />} /> */}
				{/* <Route path='/login' element={<Login />} /> */}
			<Route path='/login' element={<PhoneNumberCheck />} />
			<Route path='/password' element={<PasswordV2 />} />
			{/* <Route path="/registration" element={<RegistrationV2 />} />
			<Route path='/submit-token' element={<TokenV2 />} />
			<Route path='/change-password' element={<ChangePasswordV2 />} /> */}
				<Route path='/login/:phoneNumber/:token' element={<Landing />} />
				{/* ${response?.data?.user?.phone_number}/${response?.token} */}
				<Route path='/auto-log-out' element={<AutoLogoutPage />} />
				<Route path='/black-listed' element={<BlackListedPage />} />
				<Route path='/*' element={<PrivateOutlet />}>
					<Route path='deliver-list' element={<DriverOrderListPage />} />
					<Route
						path='order-history-list'
						element={<OrderHistoryListPage />}
					/>
					<Route path='select-business' element={<SelectBusiness />} />
					<Route path='order-details/:id' element={<OrderDetailsPage />} />
					<Route path='profile' element={<ProfilePage />} />
					<Route path='contact' element={<ContactPage />} />
					<Route path='change-password' element={<ChangePasswordPage />} />
					<Route
						path='changed-password-success'
						element={<ChangePasswordSuccessPage />}
					/>
					<Route path='otp-send' element={<ProfileVarifyPage />} />
					<Route path='otp-submit' element={<ProfileVarifyOtpPage />} />
					<Route path='change-language' element={<ChangeLanguagePage />} />
					<Route path='payment/:id' element={<PaymentPage />} />
					<Route path='success-order' element={<CongratulationPage />} />
					<Route
						path='success-deposit'
						element={<CongressDepositPage />}
					/>
					<Route
						path='success-delivery'
						element={<DeliverySuccessPage />}
					/>
					<Route
						path='success-collection'
						element={<CollectionSuccessPage />}
					/>
					<Route path='deposits-list' element={<DepositsListPage />} />
					<Route path='bank-details/:id' element={<BankDetailsPage />} />
					<Route path='bank-list' element={<BankListPage />} />
					<Route path='deposit-form/:id' element={<DepositFormPage />} />
					<Route
						path='orders-for-collector/:category'
						element={<OrderList />}
					/>
					<Route
						path='driver-order-details/:id'
						element={<DriverOrderDetailsPage />}
					/>
					<Route
						path='collection-history-list'
						element={<CollectionHistoryListPage />}
					/>
					<Route
						path='collection-history-list-details/:id'
						element={<CollectionHistoryListDetailsPage />}
					/>
					<Route path='deliver-form/:id' element={<DeliverFormPage />} />
					<Route
						path='analyses-details/:date/:category/:cateList'
						element={<AnalysesDetailsPage />}
					/>					
					<Route path='*' element={<Error />} />
				</Route>
			</Routes>
		</>
	);
}

export default Mobile;
