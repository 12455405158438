import React, { useEffect, useRef, useState } from "react";
import Auth from "services/Auth";
import { authBox_Block, forgotPass_block } from "Constants";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { loginFromSignUp } from "redux/container/authSlice";
import { useNavigate } from "react-router-dom";
import { updateToken } from "config/SuperFetch";
import { setLocalUser } from "config/user";
import { getLanguage, setLanguage } from "config/language";
import { selectLan } from "redux/container/languageSlice";
import { selectors } from "redux/container/authSlice";
import LeftArrow2 from "assets/images/icon/svg/LeftArrow2";
import { authOtpSend } from "helpers/helper";

export default function TokenBlock({
  password,
  setPassword,
  loading,
  startLoading,
  setBlockIndex,
  setIsForgotPassword,
  stopLoading,
  phoneNo,
  country,
  countryCode,
  sSize,
}) {
  let clickBtnRef = useRef(null);
  const [waiting, setWaiting] = useState(false);
  const [counter, setCounter] = useState(120);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [token, setToken] = useState();
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  let time = 0;
  var interval;

  const _handleKeyPress = (e) => {
    if (e.key === "Enter") {
      clickBtnRef.click();
    }
  };

  const resendTokenHandler = async (phoneNumber, country, countryCode) => {
    let getToken = authOtpSend(phoneNumber, country, countryCode);
    getToken
      .then((res) => {
        if (res.status === 200) {
          toast.success(ekkLanguage.token.tokenSuccessLabel);
          setWaiting(true);
          time = 0;
          interval = setInterval(() => {
            setCounter(120 - time);
            time = time + 1;
            if (time === 121) {
              setWaiting(false);
              clearInterval(interval);
              setCounter(0);
            }
          }, 1000);
        } else {
          toast.success(ekkLanguage.token.tokenErrorLabel);
        }
      })
      .catch((err) => {
        console.log(err);
      });
};

  const tokenSubmit = async (countryCode, country, phoneNumber) => {
    if (token) {
      let req_body = {
        country: country,
        phone_number: (countryCode + phoneNumber).trim(),
        otp_token: Number(token),
      };

      try {
        startLoading();
        let response = await Auth.userVerifyOtp(req_body);
        if (response.status === 200) {
          updateToken(response);
          if (response.success) {
            toast.success(ekkLanguage.token.tokenVarified);
            setBlockIndex(authBox_Block.CHANGE_PASS_BLOCK);
          } else {
            // toast.error(ekkLanguage.token.tokenNotMatch);
            setError(ekkLanguage.token.tokenNotMatch);
          }
        }
        stopLoading();
      } catch (e) {
        stopLoading();
        // toast.error(ekkLanguage.token.tokenNotMatch);
        setError(ekkLanguage.token.tokenNotMatch);
        console.log(e);
      }
    } else {
      toast.error(ekkLanguage.token.tokenNotWrite);
    }
  };
  useEffect(() => {
    setWaiting(true);
    let time = 0;
    let int = setInterval(() => {
      time = time + 1;
      setCounter(120 - time);
      if (time === 120) {
        setWaiting(false);
        clearInterval(int);
        setCounter(0);
      }
    }, 1000);
    return () => clearInterval(int);
}, []);
  return (
    <div>
      <div>
        <label
          className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}
        >
          <span className="font-bold">{ekkLanguage?.token.tokenLabel}</span>
          <span
            onClick={() => setBlockIndex(authBox_Block.PHONE_NUMBER_BLOCK)}
            className="text-xs text-red cursor-pointer flex items-center"
          >
            <span className="mr-1">
              <LeftArrow2 />
            </span>
            {ekkLanguage?.password.backWord}
          </span>
        </label>
        <input
          type="number"
          onKeyPress={_handleKeyPress}
          onChange={(event) => {
            setError("");
            setToken(event.target.value);
          }}
          placeholder={ekkLanguage?.token.tokenPlaceHolder}
          id={"ekk-input"}
          style={{
            paddingLeft: 30,
            height: sSize.matches ? 50 : 80,
            flex: "flex: 1 0 auto",
            borderRadius: ".25rem",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderWidth: 0,
            borderLeft: "1px solid rgba(0,0,0,.05)",
          }}
          className="appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white"
        />

        <div className="pt-4">
          <p className="text-dark-500 text-14 font-normal">
            {ekkLanguage.token.timerLabel}{" "}
            <span className="font-bold text-primary">{counter}</span>{" "}
            {ekkLanguage.token.second}
          </p>
        </div>
        <div className="w-full flex items-center justify-center lg:h-[150px] h-[250px] pt-4 flex-col">
          {!waiting && (
            <span
              onClick={() => resendTokenHandler(phoneNo, country, countryCode)}
              id="resend-token"
              className="text-sm  text-red"
            >
              {ekkLanguage.token.notGet}
              <span className="font-bold text-primary ml-2 underline">
                {ekkLanguage.token.tokenResend}
              </span>
            </span>
          )}
          <div className="pt-4">
            <div className="text-red text-sm text-primary">
              {error ? (
                <div className="flex-col text-primary font-bold justify-center items-center">
                  <p className="mb-2">{error}</p>
                  <p>{ekkLanguage.token.tokenNotWrite}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="py-4">
        <button
          onClick={() => tokenSubmit(countryCode, country, phoneNo, password)}
          ref={clickBtnRef}
          style={{
            background:
              "linear-gradient(89.31deg, #00C0FF 0%, rgba(0, 178, 36, 0.5) 100%)",
          }}
          className={`text-20 sm:text-26 font-bold text-white w-full h-[50px] sm:h-[60px] rounded-[5px] mt-2`}
        >
          {ekkLanguage?.token.btnLabel}
        </button>
        {/* <div className='text-left pt-4'>
					<p className='font-bold text-14'>
						{ekkLanguage?.login?.langChange}
						<span
							className={`${
								getLanguage() == "en"
									? "text-success"
									: "text-black-1000"
							}  mx-1 cursor-pointer`}
							onClick={() => {
								setLanguage("en");
								dispatch(selectLan());
							}}>
							English
						</span>{" "}
						<span
							className={`${
								getLanguage() == "bn"
									? "text-success"
									: "text-black-1000"
							}  mx-1 cursor-pointer`}
							onClick={() => {
								setLanguage("bn");
								dispatch(selectLan());
							}}>
							বাংলা
						</span>
					</p>
				</div> */}
      </div>
    </div>
  );
}
