import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Contact from "components/mobile-components/Profile/Contact";
import React from "react";
import { useSelector } from "react-redux";

export default function ContactPage() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  return (
    <IonApp>
      <IonPage className="business-name">
        <IonContent>
          <BackButtonTitle title={ekkLanguage.contact.pageTitle} />
          <Contact />
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
