import {
	IonButton,
	IonCol,
	IonGrid,
	IonInput,
	IonItem,
	IonRow,
	useIonAlert,
} from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { getUser } from "config/authCommon";
// import { getCreateUserBusiness, setCreateUserBusiness } from "config/user";
import { getLanguage } from "config/language";
import { bankListArray } from "data/bankList";
import { BUSINESS_TYPE } from "data/businessType";
import React, { useState } from "react";
import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function BankList() {
	let user = getUser();
	// const [text, setText] = useState(getCreateUserBusiness().business_type);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const selectedLanguage = getLanguage();

	const [bType, setBType] = useState("");
	const navigate = useNavigate();
	const [present] = useIonAlert();

	function setBusinessType(business) {
		// const lBusiness = getCreateUserBusiness();
		// setText(business.type);
		// lBusiness.business_type = text;
		// lBusiness.business_type = business.type;
		// setCreateUserBusiness(lBusiness);
	}

	return (
		<div className='pt-14 '>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div className='pt-5 text-center'>
							<div className='text-left pb-6'>
								<div>
									<IonRow>
										{bankListArray
											.filter(
												(el) => el.countryName === user.Country
											)
											.map((list, index) => {
												return (
													<IonCol
														size-sm='3'
														className='p-1.5'
														size='4'
														key={index}>
														<div
															onClick={() =>
																navigate(
																	`/deposit-form/${list.id}`
																)
															}
															className={`bg-white rounded-[5px] w-[100px] h-[100px]  m-auto flex items-center justify-center cursor-pointer`}
															style={{
																boxShadow:
																	"0px 3px 15px rgba(0, 0, 0, 0.05)",
															}}
															// className={
															// 	business.type === text
															// 		? "border-2 border-primary rounded-xl overflow-hidden py-3 text-center mb-4"
															// 		: "border border-black-200 rounded-xl overflow-hidden py-3  text-center opacity-30 mb-4"
															// }
														>
															<div>
																<img
																	className='m-auto mb-[10px]'
																	src={list.logo}
																	alt='fmcg'
																/>
																<h6 className='font-semibold text-10'>
																	{list.name ===
																		"Cash & Hub" && list.name}
																</h6>
															</div>
														</div>
													</IonCol>
												);
											})}
									</IonRow>
								</div>
							</div>
						</div>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
}
