import { getUser } from "config/authCommon";
import { bankListArray } from "data/bankList";
import { digitChanger, translator } from "helpers/helper";
import moment from "moment";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { fetchOrderSingle, setSingleOrder } from "redux/container/orderSlice";
import { setBottomSheet } from "redux/container/userSlice";
import Deposit from "services/Deposit";
import Orders from "services/Orders";
import User from "services/User";
import CustomModal from "../Common/Modal";
import Signature from "../Common/Signature";
import UploadImage from "../Common/UploadImage";
import CostSummeryCard from "./CostSummeryCard";
import CustomerSupplierCards from "./CustomerSupplierCards";
import ProductCard from "./ProductCard";
import { clearAuth } from "config/auth";
import { IonButton } from "@ionic/react";

export default function DeliverForm({ order }) {
	const [loader, setLoader] = useState(false);
	let user = getUser();
	let { id } = useParams();
	const response = useSelector((state) => state.order.singleOrder);
	const [isOpen, setIsOpen] = useState(false);
	let [signatureImage, setSignatureImage] = useState(null);
	let [cameraImage, setCameraImage] = useState(null);
	const [selectedDeliveryDate, setSelectedDeliveryDate] =
		useState("Select A Date");
	const [creditValidationModal, setCreditValidationModal] = useState(false);
	const [dateValidationModal, setDateValidationModal] = useState(false);
	const [signatureValidationModal, setSignatureValidationModal] =
		useState(false);
	const [cameraValidationModal, setCameraValidationModal] = useState(false);
	const [restAmount, setRestAmount] = useState("");
	const [amount, setAmount] = useState("");
	const dispatch = useDispatch();
	const onCloseModal = () => setIsOpen(false);
	const onOpenCreditValidationModal = () => setCreditValidationModal(true);
	const onCloseCreditValidationModal = () => setCreditValidationModal(false);
	const onOpenDateValidationModal = () => setDateValidationModal(true);
	const onCloseDateValidationModal = () => setDateValidationModal(false);
	const onOpenSignatureValidationModal = () =>
		setSignatureValidationModal(true);
	const onCloseSignatureValidationModal = () =>
		setSignatureValidationModal(false);
	const onOpenCameraValidationModal = () => setCameraValidationModal(true);
	const onCloseCameraValidationModal = () => setCameraValidationModal(false);

	const ekkLanguage = useSelector((state) => state.lan.lan);
	let [remaining, setRemaining] = useState();
	const navigate = useNavigate();
	let [signatureImageURL, setSignatureImageURL] = useState(null);
	let [cameraImageURL, setCameraImageURL] = useState(null);
	const [collectionAmount, setCollectionAmount] = useState([]);
	const [imageLoader, setImageLoader] = useState(false);
	const [collectorLoader, setCollectorLoader] = useState(false);
	const [totalDepositAmount, setTotalDepositAmount] = useState("");
	const orderItems = useSelector((state) => state.order.deliveredItems);
	let productItems = orderItems.productItems.map(
		(el) =>
			el && {
				id: el._id,
				quantity: el.quantity,
			}
	);

	let selectedBank = bankListArray.find((el) => el.id === Number(id));

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
			backgroundColor: "#F2F3F7",
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	useEffect(() => {
		dispatch(fetchOrderSingle(id));
	}, []);

	function DataURIToBlob(dataURI) {
		const splitDataURI = dataURI.split(",");
		const byteString =
			splitDataURI[0].indexOf("base64") >= 0
				? atob(splitDataURI[1])
				: decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++)
			ia[i] = byteString.charCodeAt(i);

		return new Blob([ia], { type: mimeString });
	}
	const imageUrlCollect = async () => {
		setImageLoader(true);
		// const signatureFile = DataURIToBlob(signatureImage);
		// const signData = new FormData();
		// signData.append("key", signatureFile, "image.jpg");
		const cameraFile = DataURIToBlob(cameraImage);
		const cameraData = new FormData();
		cameraData.append("key", cameraFile, "image.jpg");

		// let signResponse = await User.toBucketImage(signData);
		let cameraResponse = await User.toBucketImage(cameraData);
		if (cameraResponse.status === 201) {
			// setSignatureImageURL(signResponse?.results);
			setCameraImageURL(cameraResponse?.results);
			setIsOpen(true);
			setImageLoader(false);
		} else {
			clearAuth();
			setImageLoader(false);
		}
	};

	const orderSubmit = async () => {
		setLoader(true);

		let paidQuery = {
			order_status: "Delivered",
			// buyerDeliveredSignature: signatureImageURL[0],
			buyerDeliveredProof: cameraImageURL[0],
			tax: orderItems?.taxTotal,
			subtotal: orderItems?.subTotal,
			creditFee: orderItems?.creditFee,
			total: orderItems?.total,
		};
		let partialQuery = {
			order_status: "Partially_Delivered",
			buyer: order.buyer._id,
			seller: order.seller._id,
			order_items: productItems,
			// buyerDeliveredSignature: signatureImageURL[0],
			buyerDeliveredProof: cameraImageURL[0],
			tax: orderItems?.taxTotal,
			subtotal: orderItems?.subTotal,
			creditFee: orderItems?.creditFee,
			total: orderItems?.total,
		};

		let resp = await Orders.orderUpdate(
			id,
			order.total === orderItems.total ? paidQuery : partialQuery
		);
		if (resp.success === true) {
			setLoader(false);
			navigate("/success-delivery");
			onOpenCreditValidationModal();
			setIsOpen(false);
			dispatch(setSingleOrder(resp.data));
			setRestAmount();
			dispatch(setBottomSheet(false));
		} else {
			setLoader(false);
		}
	};

	const getCollectionAmount = async () => {
		setCollectorLoader(true);
		let response = await Deposit.getTodayCollection();
		if (response.status === 200) {
			setCollectionAmount(response.data[0]);
			setAmount(response.data[0]?.total_amount);
			let resp = await Deposit.getDepositAmount();
			if (resp.status === 200) {
				setCollectorLoader(false);
				setTotalDepositAmount(resp.data.totalAmount);
				let amount = response.data[0]?.total_amount - resp.data.totalAmount;
				setAmount(amount);
			} else {
				setCollectorLoader(false);
			}
		}
	};
	useEffect(() => {
		getCollectionAmount();
	}, []);

	return (
		<>
			<div className='pt-14 px-4'>
				<div className='text-center pt-7 pb-4'>
					<h2 className=' text-delivered text-20 font-bold'>
						{order?.order_id}
					</h2>
				</div>
				<CustomerSupplierCards order={order} />
				{/* <OrderItemCard order={order} /> */}
				<ProductCard />
				<CostSummeryCard order={order} />
				{/* <Signature
					setSignatureImage={setSignatureImage}
					signatureImage={signatureImage}
				/> */}
				{/* <UploadImage
					cameraImage={cameraImage}
					setCameraImage={setCameraImage}
				/> */}
				<UploadImage
					cameraImage={cameraImage}
					setCameraImage={setCameraImage}
				/>
			</div>
			<div className='pt-[80px] pb-[30px] px-4'>
				<button
					// onClick={() => {
					// 	if (!payAmount) {
					// 		onOpenCreditValidationModal();
					// 	} else if (!signatureImage) {
					// 		onOpenSignatureValidationModal();
					// 	} else if (!cameraImage) {
					// 		onOpenCameraValidationModal();
					// 	} else {
					// 		imageUrlCollect();
					// 	}
					// }}
					onClick={() => {
						if (!cameraImage) {
							onOpenCameraValidationModal();
						} else {
							imageUrlCollect();
						}
					}}
					className='h-[50px] w-full rounded-[5px] bg-primary text-white
					text-24 font-bold flex justify-center items-center'>
					{imageLoader ? (
						<ClipLoader
							sizeUnit={"px"}
							size={25}
							color={"white"}
							loading={true}
						/>
					) : (
						ekkLanguage.deliverForm.deliver
					)}
				</button>
			</div>
			<Modal
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				style={customStyles}>
				<div className='p-5 relative'>
					<div
						onClick={onCloseModal}
						className='absolute right-3 top-3 h-7 w-7 flex items-center justify-center'>
						<i className='material-icons'>close</i>
					</div>
					<img
						src='/assets/images/ekkbaz_icon.png'
						alt='logo'
						className='w-[50px] h-[40px] mb-2'
					/>
					<h5
						className='text-24 font-bold pb-5'
						style={{ color: "#0099CC" }}>
						{ekkLanguage.payment.confirmDelivery}
					</h5>
					<div className='flex justify-between items-center pb-5'>
						{/* <div>
							<img src={selectedBank?.image} alt='' />
						</div> */}
						<div>
							<p className='text-12 font-normal text-black-333'>
								{ekkLanguage.deliverForm.customer}
							</p>
							<h6 className='text-14 font-semibold text-black-1000'>
								{order?.buyer?.business_name}
							</h6>
						</div>
						<div>
							<p className='text-12 font-normal text-black-333'>
								{ekkLanguage.deliverForm.supplier}
							</p>
							<h6 className='text-14 font-semibold text-black-1000'>
								{order?.seller?.business_name}
							</h6>
						</div>
					</div>

					<div className='flex justify-between items-center pb-5'>
						<div>
							<p className='text-12 font-normal text-black-333'>
								{ekkLanguage.deliverForm.totalOrder}
							</p>
							<h6 className='text-14 font-bold text-primary'>
								{order?.country?.currency_symbol_native}{" "}
								{digitChanger(order?.total.toFixed(2))}
							</h6>
							<p className='text-14 font-normal text-black-333'>
								{digitChanger(
									moment(order?.createdAt).format("DD-MM-YYYY")
								)}
							</p>
						</div>
						<div>
							<p className='text-12 font-normal text-black-333'>
								{ekkLanguage.deliverForm.totalDeliver}
							</p>
							<h6 className='text-14 font-semibold text-success'>
								{order?.country?.currency_symbol_native}{" "}
								{digitChanger(orderItems?.total?.toFixed(2))}
							</h6>
							<p className='text-14 font-normal text-black-333'>
								{digitChanger(moment().format("DD-MM-YYYY"))}
							</p>
						</div>
					</div>
					{/* <div className='pb-5 text-center'>
						<h5 className='text-18 font-bold text-black-1000 pb-[10px]'>
							{ekkLanguage.payment.totalAmount}
						</h5>
						<h6
							className='text-18 font-bold'
							style={{ color: "#0099CC" }}>
							{translator("BDT")}{" "}
							{digitChanger(Number(payAmount).toFixed(2))}
						</h6>
					</div> */}
					<div className='flex justify-between items-center'>
						{/* <div
							className='w-1/2 mr-[10px] h-[80px] rounded-[5px] overflow-hidden'
							style={{ backgroundColor: "#E6E6E6" }}>
							<img
								src={signatureImage}
								alt='signature'
								className='m-auto h-100 object-cover'
							/>
						</div> */}
						<div
							className='h-[100px] rounded-[5px] overflow-hidden'
							style={{ backgroundColor: "#E6E6E6" }}>
							<img
								src={cameraImage}
								alt='billImage'
								className='m-auto h-100 object-cover'
							/>
						</div>
					</div>
					<div className='pt-[40px] pb-[20px] px-4'>
						<IonButton
							disabled={loader ? true : false}
							onClick={orderSubmit}
							className='h-[50px] w-full rounded-[5px] bg-primary text-white text-24 font-bold'>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={25}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.deliverForm.deliverDone
							)}
						</IonButton>
					</div>
				</div>
			</Modal>
			{/* credit modal*/}
			<CustomModal
				isOpen={creditValidationModal}
				onClose={onCloseCreditValidationModal}
				title={ekkLanguage.payment.creditWarning}
				btnText={ekkLanguage.payment.okBtnLabel}
			/>
			{/* date modal*/}
			<CustomModal
				isOpen={dateValidationModal}
				onClose={onCloseDateValidationModal}
				title={ekkLanguage.payment.dateWarning}
				btnText={ekkLanguage.payment.okBtnLabel}
			/>
			{/* camera modal*/}
			<CustomModal
				isOpen={cameraValidationModal}
				onClose={onCloseCameraValidationModal}
				title={ekkLanguage.payment.cameraWarning}
				btnText={ekkLanguage.payment.okBtnLabel}
			/>
			{/*  signature modal*/}
			<CustomModal
				isOpen={signatureValidationModal}
				onClose={onCloseSignatureValidationModal}
				title={ekkLanguage.payment.signatureWarning}
				btnText={ekkLanguage.payment.okBtnLabel}
			/>
		</>
	);
}
