import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import React from "react";
import Modal from "react-modal";

export default function CustomModal({
	isOpen,
	onClose,
	btnText,
	title,
	loading,
}) {
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
			backgroundColor: "#F2F3F7",
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	return (
		<Modal
			style={customStyles}
			closeTimeoutMS={200}
			isOpen={isOpen}
			onRequestClose={onClose}
			classNames='bottom-sheet-modal'>
			<div className='p-5'>
				<span className='mb-2 inline-block mx-2'>
					<CancelPopUp />
				</span>
				<h6 className='text-16 font-bold pb-[20px]'>{title}</h6>

				<div className='flex justify-end'>
					<button
						color='primary'
						className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white'
						onClick={onClose}>
						{btnText}
					</button>
				</div>
			</div>
		</Modal>
	);
}
