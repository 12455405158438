import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import { addStatusClass, digitChanger, monthChanger } from "helpers/helper";
import "moment-timezone";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
export default function OrderStatusCard({ response, ekkLanguage, category }) {
	return (
		<>
			{response?.status === "loading" ? (
				<IonLoading
					isOpen={response.status === "loading" ? true : false}
					// onDidDismiss={() => setShowLoading(false)}
					message={ekkLanguage.orderList.loader}
				/>
			) : (
				<IonGrid>
					{Array.isArray(response?.data) && response.data.length > 0 ? (
						<IonRow>
							{response?.data &&
								response?.data.map((order, index) => {
									let orderStatus = addStatusClass(
										order?.order_status
									);

									return (
										<IonCol size='12' key={index}>
											<Link
												to={`${
													category
														? "/order-details"
														: "/driver-order-details"
												}/${order._id}`}>
												<div
													className='mb-2 rounded-[10px] overflow-hidden'
													style={{
														boxShadow: `0px 5px 15px rgba(0, 0, 0, 0.05)`,
													}}>
													<div
														className={`bg-${orderStatus} py-[7px]  px-4`}>
														<ul className='flex items-center justify-between'>
															<li>
																<h6 className='font-bold text-12 text-white'>
																	{digitChanger(
																		order?.order_id
																	)}
																</h6>
															</li>
															<li>
																<h6 className='font-bold text-12 text-white'>
																	{digitChanger(
																		monthChanger(
																			moment(
																				order?.createdAt
																			).format(
																				"DD MMM, YYYY"
																			)
																		)
																	)}
																</h6>
															</li>
															<li>
																<h6 className='font-bold text-12 text-white'>
																	৳{" "}
																	{digitChanger(
																		Number(
																			order?.total
																		).toFixed(2)
																	)}
																</h6>
															</li>
														</ul>
													</div>
													<div className='px-4 bg-white py-[7px]'>
														<ul className=''>
															<li className='w-full'>
																<div className='flex'>
																	<p className='text-black-500 text-10 font-semibold py-[3px] flex w-1/2'>
																		<img
																			src='/assets/images/to.png'
																			alt=''
																			className='w-[14px] h-[14px] mr-1'
																		/>
																		{" - "}
																		{
																			order?.seller
																				?.business_name
																		}
																	</p>

																	{/* <p className='text-black-500 text-10 font-semibold py-[3px] flex'>
																	<img
																		src='/assets/images/icon/createdBy.png'
																		alt=''
																		className='w-[14px] h-[14px] mr-1'
																	/>
															p		{" - "}
																	{
																		order?.created_by_user
																			?.DisplayName
																	}
																	{order?.order_origin ===
																		"EkkHero Sales" && (
																		<img
																			src='/assets/images/icon/hero-icon.png'
																			alt='hero'
																			class='pl-2'></img>
																	)}
																</p> */}
																	<p className='text-black-500 text-10 font-semibold py-[3px] flex'>
																		<img
																			src='/assets/images/for.png'
																			alt=''
																			className='w-[14px] h-[14px] mr-1'
																		/>
																		{" - "}
																		{
																			order?.buyer
																				?.business_name
																		}
																	</p>
																</div>
															</li>
															<li>
																<p className='text-black-500 text-10 font-semibold py-[3px] flex'>
																	<img
																		src='/assets/images/for.png'
																		alt=''
																		className='w-[14px] h-[14px] mr-1'
																	/>
																	{" - "}
																	{order?.buyer
																		?.address_line &&
																		order?.buyer
																			?.address_line}
																	{order?.buyer?.city &&
																		", " + order?.buyer?.city}
																	{order?.buyer?.postal_code &&
																		", " +
																			order?.buyer
																				?.postal_code}
																</p>
															</li>
														</ul>
													</div>
												</div>
											</Link>
										</IonCol>
									);
								})}
						</IonRow>
					) : (
						<IonRow>
							<IonCol size='2'></IonCol>
							<IonCol size='8'>
								<div
									className='text-center flex items-center justify-center'
									style={{ height: "calc(100vh - 420px)" }}>
									<div>
										<img
											className='m-auto mb-[10px] mt-5'
											src='/assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-16 font-normal text-222222 mb-[35px]'>
											{ekkLanguage.orderList.notFound}
										</p>
									</div>
								</div>
							</IonCol>
						</IonRow>
					)}
				</IonGrid>
			)}
		</>
	);
}
