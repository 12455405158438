import { getUserBusiness } from "config/authCommon";
import { digitChanger } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";

export default function BankMeta({ selectedBank }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const businessInfo = getUserBusiness();

	return (
		<div>
			<div className='text-center py-[30px]'>
				<img
					src={selectedBank?.image}
					alt='bankLogo'
					className='m-auto mb-[10px]'
				/>
				<p className='text-black-1000 font-semibold text-12'>
					{ekkLanguage.depositForm.checkForm}
				</p>
			</div>
			<div>
				{selectedBank.type === "bank" && (
					<ul className='flex'>
						<li className='w-4/12'>
							<p className='text-black-500 text-14 font-semibold flex'>
								{ekkLanguage.depositForm.bankName}:
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{ekkLanguage.depositForm.acName}:
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{ekkLanguage.depositForm.acNumber}:
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{ekkLanguage.depositForm.routeNumber}:
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{ekkLanguage.depositForm.branch}:
							</p>
						</li>
						<li className='w-8/12'>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.name}
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.acName}
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.acNumber}
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.routeNumber}
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.branch}
							</p>
						</li>
					</ul>
				)}
				{selectedBank.type === "mfs" && (
					<ul className='flex'>
						<li className='w-4/12'>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.name} Number:
							</p>
						</li>
						<li className='w-8/12'>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.numberAgent}(Agent)
							</p>
							{/* <p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.numberPersonal}(Personal)
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.numberMerchant}(Merchant)
							</p> */}
						</li>
					</ul>
				)}
				{selectedBank.type === "hub" && (
					<ul className='flex'>
						<li className='w-4/12'>
							<p className='text-black-500 text-14 font-semibold flex'>
								{ekkLanguage.depositForm.businessName}:
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{ekkLanguage.depositForm.businessType}:
							</p>

							<p className='text-black-500 text-14 font-semibold flex'>
								{ekkLanguage.depositForm.mobileNumber}:
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{ekkLanguage.depositForm.address}:
							</p>
							{businessInfo?.images?.length && (
								<p className='text-black-500 text-14 font-semibold flex'>
									{ekkLanguage.depositForm.photo}:
								</p>
							)}
						</li>
						<li className='w-8/12 text-right'>
							<p className='text-black-500 text-14 font-semibold flex'>
								{businessInfo?.business_name}
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{businessInfo?.business_type?.title}
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{digitChanger(businessInfo?.phone_number)}
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{businessInfo?.address_line}
								{","} {businessInfo?.city}
								{","}
								{businessInfo?.country}
							</p>
							{businessInfo?.image?.length && (
								<p className='text-black-500 text-14 font-semibold flex'>
									<img
										className='pt-2'
										src={businessInfo?.images[0]}
										alt='casher logo'
									/>
								</p>
							)}
						</li>
					</ul>
				)}
				{/* {selectedBank.type === "bank" ? (
					<ul className='flex'>
						<li className='w-4/12'>
							<p className='text-black-500 text-14 font-semibold flex'>
								Bank Name:
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								A/C Name:
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								A/C Number:
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								Route Number:
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								Branch:
							</p>
						</li>
						<li className='w-8/12'>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.name}
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.acName}
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.acNumber}
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.routeNumber}
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.branch}
							</p>
						</li>
					</ul>
				) : (
					<ul className='flex'>
						<li className='w-4/12'>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.name} Number:
							</p>
						</li>
						<li className='w-8/12'>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.numberAgent}(Agent)
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.numberPersonal}(Personal)
							</p>
							<p className='text-black-500 text-14 font-semibold flex'>
								{selectedBank?.numberMerchant}(Merchant)
							</p>
						</li>
					</ul>
				)} */}
			</div>
		</div>
	);
}
