import { IonApp, IonContent, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import DriverBottomSheet from "components/mobile-components/DriverOrderList/DriverBottomSheet";
import SummeryCard from "components/mobile-components/Home/SummeryCard";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useCookie from "react-use-cookie";
import { getUserBusiness } from "config/user";

export default function HomePage() {
  const { category } = useParams();
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [userToken, setToken, removeToken] = useCookie("TOKEN");
  const [userPhoneNumber, setPhoneNumber, removePhoneNumber] =
    useCookie("PHONE_NUMBER");
  const [userCountry, setUserCountry, removeCountry] = useCookie("COUNTRY");
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  const ekkLanguage = useSelector((state) => state.lan.lan);
  function getSelectedBusiness() {
    let selectedBusiness = getUserBusiness();
    if (selectedBusiness?.id) {
      removeCountry();
      removeToken();
      removePhoneNumber();
    }
  }
  useEffect(() => {
    if (userToken) {
      getSelectedBusiness();
    }
  }, [userToken]);

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.letNav.homeMenuLabel}
            burgerMenu={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <section className="pt-14">
            <div className="pt-5">
              <SummeryCard />
            </div>
          </section>
          <DriverBottomSheet />
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
