import {
	IonCol,
	IonGrid,
	IonRow,
	IonSegment,
	IonSegmentButton,
} from "@ionic/react";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrder } from "redux/container/orderSlice";

function OrderStatusBtn({ category }) {
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<IonGrid>
			<IonRow>
				<IonCol size='12'>
					<IonSegment
						value='Partially_Delivered'
						scrollable
						className='orderBtnWrapper'
						onIonChange={(e) =>
							dispatch(
								fetchOrder(
									category ? "collector" : "driver",
									e.detail.value
								)
							)
						}>
						{/* <IonSegmentButton
							className='recent relative'
							onClick={() => {
								dispatch(
									fetchOrder(category ? "collector" : "driver", "")
								);
							}}>
							<button className='text-black-1000 font-sans p-0 font-normal shadow-none text-14'>
								{ekkLanguage.orderList.tabMenuRecent}
							</button>
						</IonSegmentButton> */}
						{/* <IonSegmentButton
							className='placed relative'
							onClick={async () => {
								dispatch(fetchOrderListBySorted("Placed", 1, 100));
							}}>
							<button className='text-FFC227-333 font-sans p-0 font-normal shadow-none text-14'>
								{ekkLanguage.orderList.tabMenuPlaced}
							</button>
						</IonSegmentButton> */}
						<IonSegmentButton
							value='Partially_Delivered'
							className='partial-delivery relative'>
							<button className='text-66D9FF-1000 font-sans p-0 font-normal shadow-none text-14'>
								{ekkLanguage.orderList.tabMenuPartialDelivered}
							</button>
						</IonSegmentButton>
						<IonSegmentButton
							value='Delivered'
							className='delivered relative'>
							<button className='text-0099CC-1000 font-sans p-0 font-normal shadow-none text-14'>
								{ekkLanguage.orderList.tabMenuDelivered}
							</button>
						</IonSegmentButton>
						<IonSegmentButton
							value='Partially_Paid'
							className='partial-payment relative'>
							<button className='text-00FF33-1000 font-sans p-0 font-normal shadow-none text-14'>
								{ekkLanguage.orderList.tabMenuPartialPaid}
							</button>
						</IonSegmentButton>
						{/* {category === "collector" && (
							<IonSegmentButton
								className='paid relative'
								onClick={() => {
									dispatch(fetchOrder("collector", "Paid"));
								}}>
								<button className='text-paid font-sans p-0 font-normal shadow-none text-14'>
									{ekkLanguage.orderList.tabMenuPaid}
								</button>
							</IonSegmentButton>
						)} */}
					</IonSegment>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
}
export default memo(OrderStatusBtn);
