import { getLanguage, setLanguage } from "config/language";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLan } from "redux/container/languageSlice";

export default function ChangedCongressBlock() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const { preUrl } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	return (
		<div>
			{" "}
			<div className='py-4'>
				<h1
					className={`  ${
						getLanguage() === "en"
							? "lg:text-24 text-28 md:text-24"
							: "lg:text-24 text-28 md:text-24"
					} font-bold text-262626-1000 pr-[0px] pb-5 text-center`}>
					{ekkLanguage?.changePass.successLabel}
				</h1>
				<button
					onClick={() => (window.location.href = preUrl)}
					style={{
						background:
							"linear-gradient(89.31deg, #00C0FF 0%, rgba(0, 178, 36, 0.5) 100%)",
					}}
					className={`text-20 sm:text-26 font-bold text-white w-full h-[50px] sm:h-[60px] rounded-[5px]`}>
					{ekkLanguage?.changePass.backBtnLabel}
				</button>
				<div className='text-left pt-4'>
					<p className='font-bold text-14'>
						{ekkLanguage?.login?.langChange}
						<span
							className={`${
								getLanguage() == "en"
									? "text-success"
									: "text-black-1000"
							}  mx-1 cursor-pointer`}
							onClick={() => {
								setLanguage("en");
								dispatch(selectLan());
							}}>
							English
						</span>{" "}
						<span
							className={`${
								getLanguage() == "bn"
									? "text-success"
									: "text-black-1000"
							}  mx-1 cursor-pointer`}
							onClick={() => {
								setLanguage("bn");
								dispatch(selectLan());
							}}>
							বাংলা
						</span>
					</p>
				</div>
			</div>
		</div>
	);
}
