import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import VerifyNumberSend from "components/mobile-components/Profile/VerifyNumberSend";
import React from "react";
import { useSelector } from "react-redux";

export default function ProfileVarifyPage() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  return (
    <IonApp>
      <IonPage className="business-name">
        <IonContent>
          <BackButtonTitle title={ekkLanguage.otpsend.pageTitle} />
          <VerifyNumberSend />
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
