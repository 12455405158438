import { IonButton, IonCol, IonRow } from "@ionic/react";
import { Input } from "components/global-components/common/Input";
import { getUser } from "config/authCommon";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setUser } from "redux/container/userSlice";
import User from "services/User";

export default function Contact() {
  const userData = useSelector((state) => state.user.data);
  const ekkLanguage = useSelector((state) => state.lan.lan);

  const [email, setEmail] = useState(userData.contact_and_basic_info?.email);
  const [nid, setNid] = useState(userData.contact_and_basic_info?.nid_number);
  const [name, setName] = useState(userData?.full_name);
  const history = useNavigate();
  const dispatch = useDispatch();

  let contactUpdateHandler = async () => {
    // if (email.includes("@") === false) {
    //   toast.warning(ekkLanguage.contact.currectEmail);
    // }
    if ((email && email.includes("@")) || nid || name) {
      var reqbody = {
        id_number: nid,
        email: email,
        display_name: name,
      };
      try {
        let response = await User.basicInfoUpdate(userData?._id, reqbody);
        if (response.status === 200 || response.status === 201) {
          dispatch(setUser(response.data));
          toast.success(ekkLanguage.contact.successLabel);
          history("/profile");
        }
      } catch (e) {
        console.log(e);
      }
    } else {
    }
  };
  let permissionToChange = () => {
    if (
      userData.contact_and_basic_info?.email === email &&
      userData.contact_and_basic_info?.nid_number === nid &&
      userData.contact_and_basic_info?.full_name === name
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="px-4 relative pt-20">
      <Input
        label={{
          label: ekkLanguage.contact.nameLabel,
          source: "/assets/images/user_name.jpg",
        }}
        onChange={(event) => {
          setName(event.target.value);
          permissionToChange();
        }}
        // star
        type="text"
        name="name"
        value={name}
        placeholder={ekkLanguage.contact.namePlaceholder}
      ></Input>
      <div className="mt-3">
        <Input
          label={{
            label: ekkLanguage.contact.emailLabel,
            source: "/assets/images/email.jpg",
          }}
          onChange={(event) => {
            setEmail(event.target.value);
            permissionToChange();
          }}
          // star
          type="email"
          name="email"
          value={email}
          placeholder={ekkLanguage.contact.emailPlaceholder}
        ></Input>
      </div>
      <div className="mt-3">
        <Input
          label={{
            label: ekkLanguage.contact.nidLabel,
            source: "/assets/images/nid.jpg",
          }}
          onChange={(event) => {
            setNid(event.target.value);
            permissionToChange();
          }}
          // star
          type="text"
          name="nid"
          value={nid}
          placeholder={ekkLanguage.contact.nidPlaceholder}
        ></Input>
      </div>
      <div className="submit-button-wrapper mt-[250px]">
        <IonRow>
          <IonCol size="12">
            <div className="text-center">
              <IonButton
                disabled={permissionToChange()}
                expand="full"
                className="bg-primary rounded-[7px] font-extrabold text-12 h-10"
                onClick={contactUpdateHandler}
              >
                {ekkLanguage.contact.btnLabel}
              </IonButton>
            </div>
          </IonCol>
        </IonRow>
      </div>

      {/* <IonGrid>
        <IonRow>
          <IonCol size="12">
            <div className="flex mb-7">
              <span>
                <Envelope />
              </span>
              <div className="ml-[10px] w-full">
                <h5 className="text-dark-1000 text-16 font-semibold">
                  {ekkLanguage.contact.emailLabel}
                </h5>
                <IonItem>
                  <IonInput
                    type="email"
                    value={email}
                    placeholder={ekkLanguage.contact.emailPlaceholder}
                    onIonChange={(e) => {
                      setEmail(e.detail.value);
                      permissionToChange();
                    }}
                    clearInput
                  ></IonInput>
                </IonItem>
              </div>
            </div>
          </IonCol>
          <IonCol size="12">
            <div className="flex mb-7">
              <span>
                <Envelope />
              </span>
              <div className="ml-[10px] w-full">
                <h5 className="text-dark-1000 text-16 font-semibold">
                  {ekkLanguage.contact.nidLabel}
                </h5>
                <IonItem>
                  <IonInput
                    type="text"
                    value={nid}
                    placeholder={ekkLanguage.contact.nidPlaceholder}
                    onIonChange={(e) => {
                      setNid(e.detail.value);
                      permissionToChange();
                    }}
                    clearInput
                  ></IonInput>
                </IonItem>
              </div>
            </div>
          </IonCol>
        </IonRow>
        <div>
          <IonRow>
            <IonCol size="12">
              <div className="text-center">
                <IonButton
                  disabled={permissionToChange()}
                  className="bg-primary rounded-[7px] font-extrabold text-12 h-10"
                  onClick={contactUpdateHandler}
                >
                  {ekkLanguage.contact.btnLabel}
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </div>
      </IonGrid> */}
    </div>
  );
}
