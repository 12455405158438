import { IonApp, IonContent, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import BottomSheet from "components/mobile-components/OrderList/BottomSheet";
import OrderStatus from "components/mobile-components/OrderList/OrderStatus";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function OrderList() {
  const { category } = useParams();
  const [menuCollapse, setMenuCollapse] = useState(true);
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  const ekkLanguage = useSelector((state) => state.lan.lan);

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.collectionOrderList.pageTitle}
            burgerMenu={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
            from="collector"
          />
          <section>
            <OrderStatus category={category} />
          </section>
          <BottomSheet />
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
