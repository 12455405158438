import React from "react";

export default function CancelOrder() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'>
			<circle cx='20' cy='20' r='20' fill='#F94B41' fillOpacity='0.15' />
			<g clipPath='url(#clip0_1325_45)'>
				<path
					d='M27.5001 13.3333H24.9167C24.7233 12.3928 24.2116 11.5478 23.4678 10.9406C22.724 10.3334 21.7936 10.0012 20.8334 10L19.1667 10C18.2066 10.0012 17.2762 10.3334 16.5324 10.9406C15.7886 11.5478 15.2768 12.3928 15.0834 13.3333H12.5001C12.2791 13.3333 12.0671 13.4211 11.9108 13.5774C11.7545 13.7337 11.6667 13.9457 11.6667 14.1667C11.6667 14.3877 11.7545 14.5996 11.9108 14.7559C12.0671 14.9122 12.2791 15 12.5001 15H13.3334V25.8333C13.3347 26.938 13.7741 27.997 14.5553 28.7782C15.3364 29.5593 16.3954 29.9987 17.5001 30H22.5001C23.6047 29.9987 24.6638 29.5593 25.4449 28.7782C26.226 27.997 26.6654 26.938 26.6667 25.8333V15H27.5001C27.7211 15 27.9331 14.9122 28.0893 14.7559C28.2456 14.5996 28.3334 14.3877 28.3334 14.1667C28.3334 13.9457 28.2456 13.7337 28.0893 13.5774C27.9331 13.4211 27.7211 13.3333 27.5001 13.3333ZM19.1667 11.6667H20.8334C21.3503 11.6673 21.8544 11.8278 22.2764 12.1262C22.6985 12.4246 23.0179 12.8462 23.1909 13.3333H16.8092C16.9822 12.8462 17.3017 12.4246 17.7237 12.1262C18.1458 11.8278 18.6499 11.6673 19.1667 11.6667ZM25.0001 25.8333C25.0001 26.4964 24.7367 27.1323 24.2678 27.6011C23.799 28.0699 23.1631 28.3333 22.5001 28.3333H17.5001C16.837 28.3333 16.2012 28.0699 15.7323 27.6011C15.2635 27.1323 15.0001 26.4964 15.0001 25.8333V15H25.0001V25.8333Z'
					fill='#F94B41'
				/>
				<path
					d='M18.3333 25C18.5543 25 18.7663 24.9122 18.9226 24.756C19.0789 24.5997 19.1667 24.3877 19.1667 24.1667V19.1667C19.1667 18.9457 19.0789 18.7337 18.9226 18.5775C18.7663 18.4212 18.5543 18.3334 18.3333 18.3334C18.1123 18.3334 17.9004 18.4212 17.7441 18.5775C17.5878 18.7337 17.5 18.9457 17.5 19.1667V24.1667C17.5 24.3877 17.5878 24.5997 17.7441 24.756C17.9004 24.9122 18.1123 25 18.3333 25Z'
					fill='#F94B41'
				/>
				<path
					d='M21.6666 25C21.8876 25 22.0996 24.9122 22.2558 24.756C22.4121 24.5997 22.4999 24.3877 22.4999 24.1667V19.1667C22.4999 18.9457 22.4121 18.7337 22.2558 18.5775C22.0996 18.4212 21.8876 18.3334 21.6666 18.3334C21.4456 18.3334 21.2336 18.4212 21.0773 18.5775C20.921 18.7337 20.8333 18.9457 20.8333 19.1667V24.1667C20.8333 24.3877 20.921 24.5997 21.0773 24.756C21.2336 24.9122 21.4456 25 21.6666 25Z'
					fill='#F94B41'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1325_45'>
					<rect
						width='20'
						height='20'
						fill='white'
						transform='translate(10 10)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
