import React, { useEffect, useState } from "react";
import { getLanguage, setLanguage } from "config/language";
import { useDispatch, useSelector } from "react-redux";
import PhoneNoCheck from "./PhoneNoCheck";
import { Transition, animated } from "react-spring/renderprops";
import { authBox_Block, forgotPass_block } from "Constants";
import Password from "./Password";
import SendOtpBlock from "./SendOtpBlock";
import TokenBlock from "./TokenBlock";
import SubmitPassBlock from "./SubmitPassBlock";
import ChangedCongressBlock from "./ChangedCongressBlock";
import useCookie from "react-use-cookie";
import Auth from "services/Auth";
import { loginFromSignUp } from "redux/container/authSlice";
import { updateToken } from "config/SuperFetch";
import { setLocalUser } from "config/user";
import { selectLan } from "redux/container/languageSlice";

export default function BannerContent() {
  const [loading, setLoading] = useState(false);
  const localLanguage = getLanguage();
  const [countryCode, setCountryCode] = useState("+88");
  const [country, setCountry] = useState("Bangladesh");
  const [userToken, setToken, removeToken] = useCookie("TOKEN");
  const [userPhoneNumber, setPhoneNumber, removePhoneNumber] =
    useCookie("PHONE_NUMBER");
  const [userCountry, setUserCountry, removeCountry] = useCookie("COUNTRY");
  const [phoneNo, setPhoneNo] = useState("");
  var sSize = window.matchMedia("(max-width: 599px)");
  const ekkLanguage = useSelector((state) => state.lan);
  const [blockIndex, setBlockIndex] = useState(
    authBox_Block.PHONE_NUMBER_BLOCK
  );
  const [password, setPassword] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  // const handleClose = () => {
  //   setIsOpen(false);
  // };
  const loginWithToken = async () => {
    let req_body = {
      phone_number: userPhoneNumber,
      country: userCountry,
    };
    try {
      const response = await Auth.logInWithToken(req_body);
      if (response?.status === 200) {
        dispatch(loginFromSignUp(response));
        updateToken(response);
        setLocalUser(response?.data);
        if (response?.data?.user.Country === "Bangladesh") {
          setLanguage("bn");
          dispatch(selectLan());
        } else {
          setLanguage("en");
          dispatch(selectLan());
        }
      }
    } catch (e) {}
  };
  useEffect(() => {
    if (userToken) {
      updateToken({ token: userToken });
      loginWithToken();
    }
  }, []);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);
  return (
    <div
      className={`${
        isOpen ? "pt-[80px] lg:pt-[120px]" : "pt-[120px] lg:pt-[180px]"
      }`}
    >
      <div
        className={`flex w-full justify-center items-center ${
          isOpen ? "lg:my-5 md:my-4 my-10" : "lg:my-5 md:my-4 my-10"
        }`}
      >
        <img
          src="/assets/images/ekkhero_pilot.png"
          alt="logo"
          className="w-[207px] h-[100px]"
        />
      </div>
      <div>
        {/* {isOpen && (
          <div className="bg-[#EFE0BE] flex justify-between items-center p-5 rounded-lg my-10">
            <img src="/assets/images/warning.png" alt="" />
            <p className="lg:text-18 text-14 text-[#00000080] pl-4">
              {localLanguage === "en"
                ? "Kindly note, transactions will be impacted on 1st March 2024 as system is being upgraded."
                : localLanguage === "bn"
                ? "দয়া করে মনে রাখবেন, সিস্টেমটি আপগ্রেডের পরিণামে ১লা মার্চ ২০২৪ পর্যন্ত লেনদেনে প্রভাব পড়তে পারে।"
                : "Xin lưu ý, việc nâng cấp hệ thống có thể ảnh hưởng đến các giao dịch cho đến ngày 1 tháng 3 năm 2024."}
            </p>
            <img
              src="/assets/images/cross.png"
              className="p-2"
              onClick={handleClose}
              alt=""
            ></img>
          </div>
        )} */}
      </div>
      <div className={`${isOpen ? "lg:pt-5 pt-4" : "pt-12"} relative`}>
        <Transition
          native
          reset
          unique
          items={blockIndex}
          from={{
            opacity: 0,
            transform: "translate3d(30%,0,0)",
          }}
          enter={{ opacity: 1, transform: "translate3d(0%,0,0)" }}
          leave={{
            opacity: 0,
            transform: "translate3d(-30%,0,0)",
          }}
        >
          {(index) => {
            if (index === authBox_Block.PHONE_NUMBER_BLOCK) {
              return (style) => (
                <animated.div className="absolute w-full" style={{ ...style }}>
                  <PhoneNoCheck
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    country={country}
                    setCountry={setCountry}
                    phoneNo={phoneNo}
                    setPhoneNo={setPhoneNo}
                    sSize={sSize}
                    setBlockIndex={setBlockIndex}
                  />
                </animated.div>
              );
            }
            if (index === authBox_Block.PASSWORD_BLOCK) {
              return (style) => (
                <animated.div className="absolute w-full" style={{ ...style }}>
                  <div>
                    <Password
                      setPassword={setPassword}
                      loading={loading}
                      setIsForgotPassword={setIsForgotPassword}
                      password={password}
                      phoneNo={phoneNo}
                      country={country}
                      countryCode={countryCode}
                      startLoading={startLoading}
                      stopLoading={stopLoading}
                      setBlockIndex={setBlockIndex}
                      sSize={sSize}
                    />
                  </div>
                </animated.div>
              );
            }
            if (index === authBox_Block.TOKEN) {
              return (style) => (
                <animated.div className="absolute w-full" style={{ ...style }}>
                  <div>
                    <TokenBlock
                      setPassword={setPassword}
                      loading={loading}
                      setIsForgotPassword={setIsForgotPassword}
                      password={password}
                      phoneNo={phoneNo}
                      country={country}
                      countryCode={countryCode}
                      startLoading={startLoading}
                      stopLoading={stopLoading}
                      setBlockIndex={setBlockIndex}
                      sSize={sSize}
                    />
                  </div>
                </animated.div>
              );
            }
            if (index === authBox_Block.CHANGE_PASS_BLOCK) {
              return (style) => (
                <animated.div className="absolute w-full" style={{ ...style }}>
                  <div>
                    <SubmitPassBlock
                      setPassword={setPassword}
                      loading={loading}
                      setIsForgotPassword={setIsForgotPassword}
                      password={password}
                      phoneNo={phoneNo}
                      country={country}
                      countryCode={countryCode}
                      startLoading={startLoading}
                      stopLoading={stopLoading}
                      setBlockIndex={setBlockIndex}
                      sSize={sSize}
                    />
                  </div>
                </animated.div>
              );
            }
            if (index === authBox_Block.CONGRESS_BLOCK) {
              return (style) => (
                <animated.div className="absolute w-full" style={{ ...style }}>
                  <div>
                    <ChangedCongressBlock />
                  </div>
                </animated.div>
              );
            }
          }}
        </Transition>
      </div>
    </div>
  );
}
