import { IonApp, IonContent, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import OrderStatusCard from "components/mobile-components/Common/OrderList/OrderStatusCard";
import DriverBottomSheet from "components/mobile-components/DriverOrderList/DriverBottomSheet";
import OrderStatus from "components/mobile-components/DriverOrderList/OrderStatus";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchOrder } from "redux/container/orderSlice";

export default function DriverOrderListPage() {
  const { category } = useParams();
  const [menuCollapse, setMenuCollapse] = useState(true);
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const dispatch = useDispatch();
  const orderList = useSelector((state) => state.order);
  useEffect(() => {
    dispatch(fetchOrder("driver", "Scheduled For Delivery"));
  }, []);

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.deliveryOrderList.pageTitle}
            burgerMenu={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
            from="driverOrderList"
          />
          <section className="pt-14">
            <OrderStatusCard response={orderList} ekkLanguage={ekkLanguage} />
          </section>
          <DriverBottomSheet />
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
