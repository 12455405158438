import React, { useState } from "react";
import { getLanguage } from "config/language";
import { useSelector } from "react-redux";
import { Transition, animated } from "react-spring/renderprops";
import { authBox_Block, forgotPass_block } from "Constants";
import Password from "./Password";
import SendOtpBlock from "./SendOtpBlock";
import TokenBlock from "./TokenBlock";
import SubmitPassBlock from "./SubmitPassBlock";
import ChangedCongressBlock from "./ChangedCongressBlock";

export default function ForgotPassContent() {
	const [loading, setLoading] = useState(false);
	const [countryCode, setCountryCode] = useState("+88");
	const [country, setCountry] = useState("Bangladesh");
	const [phoneNo, setPhoneNo] = useState("");
	var sSize = window.matchMedia("(max-width: 599px)");
	const ekkLanguage = useSelector((state) => state.lan);
	const [blockIndex, setBlockIndex] = useState(
		authBox_Block.PHONE_NUMBER_BLOCK
	);
	const [password, setPassword] = useState("");
	const [isForgotPassword, setIsForgotPassword] = useState(false);
	const startLoading = () => setLoading(true);
	const stopLoading = () => setLoading(false);

	return (
		<div className='sm:pt-[185px] pt-[120px]'>
			{blockIndex !== forgotPass_block.CONGRESS_BLOCK && (
				<h1
					className={`  ${
						getLanguage() === "en"
							? "lg:text-32 text-22 md:text-32"
							: "lg:text-32 text-20 md:text-32"
					} font-bold lg:text-262626-1000 pr-[0px] pb-4 text-white text-center lg:text-left`}>
					{ekkLanguage?.lan.landing.header4}
				</h1>
			)}

			<div className='pt-12 relative'>
				<Transition
					native
					reset
					unique
					items={blockIndex}
					from={{
						opacity: 0,
						transform: "translate3d(30%,0,0)",
					}}
					enter={{ opacity: 1, transform: "translate3d(0%,0,0)" }}
					leave={{
						opacity: 0,
						transform: "translate3d(-30%,0,0)",
					}}>
					{(index) => {
						if (index === forgotPass_block.PHONE_NUMBER_BLOCK) {
							return (style) => (
								<animated.div
									className='absolute w-full'
									style={{ ...style }}>
									<SendOtpBlock
										countryCode={countryCode}
										setCountryCode={setCountryCode}
										country={country}
										setCountry={setCountry}
										phoneNo={phoneNo}
										setPhoneNo={setPhoneNo}
										sSize={sSize}
										setBlockIndex={setBlockIndex}
									/>
								</animated.div>
							);
						}
						if (index === forgotPass_block.TOKEN) {
							return (style) => (
								<animated.div
									className='absolute w-full'
									style={{ ...style }}>
									<div>
										<TokenBlock
											setPassword={setPassword}
											loading={loading}
											setIsForgotPassword={setIsForgotPassword}
											password={password}
											phoneNo={phoneNo}
											country={country}
											countryCode={countryCode}
											startLoading={startLoading}
											stopLoading={stopLoading}
											setBlockIndex={setBlockIndex}
											sSize={sSize}
										/>
									</div>
								</animated.div>
							);
						}
						if (index === forgotPass_block.CHANGE_PASS_BLOCK) {
							return (style) => (
								<animated.div
									className='absolute w-full'
									style={{ ...style }}>
									<div>
										<SubmitPassBlock
											setPassword={setPassword}
											loading={loading}
											setIsForgotPassword={setIsForgotPassword}
											password={password}
											phoneNo={phoneNo}
											country={country}
											countryCode={countryCode}
											startLoading={startLoading}
											stopLoading={stopLoading}
											setBlockIndex={setBlockIndex}
											sSize={sSize}
										/>
									</div>
								</animated.div>
							);
						}
						if (index === forgotPass_block.CONGRESS_BLOCK) {
							return (style) => (
								<animated.div
									className='absolute w-full'
									style={{ ...style }}>
									<div>
										<ChangedCongressBlock />
									</div>
								</animated.div>
							);
						}
					}}
				</Transition>
			</div>
		</div>
	);
}
