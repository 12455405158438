import React, { forwardRef, useRef, useState } from "react";
import moment from "moment";
import { IonToggle } from "@ionic/react";
import Calendar from "assets/images/icon/svg/Calendar";
import Add from "assets/images/icon/svg/Add";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DueDate({
	selectedDeliveryDate,
	setSelectedDeliveryDate,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [checked, setChecked] = useState(false);

	const CustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			onClick={onClick}
			className={`bg-F3F3F3 p-3 block rounded-[10px] mt-4 border ${
				selectedDeliveryDate == "Select A Date"
					? "border-primary"
					: "border-transparent"
			} `}
			style={{
				boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
			}}
			id='date-input'>
			<div className='flex justify-between items-center'>
				<div className='flex items-center'>
					<span>
						<Calendar
							color={
								selectedDeliveryDate == "Select A Date"
									? "#F94B41"
									: "#222222"
							}
						/>
					</span>
					<p
						className={`text-12 ${
							selectedDeliveryDate == "Select A Date"
								? "text-primary"
								: "text-black-1000"
						} ml-2`}>
						{selectedDeliveryDate == "Select A Date"
							? selectedDeliveryDate
							: moment(selectedDeliveryDate).format("DD-MM-YYYY")}
					</p>
				</div>
				<div>
					<Add />
				</div>
			</div>
		</div>
	));
	let dateChanger = (e) => {
		setSelectedDeliveryDate(e);
	};
	return (
		<>
			<div
				className='text-center py-4 bg-white rounded-[10px] mt-6 px-4'
				style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)" }}>
				<div className='flex items-center justify-between'>
					<p className='text-16 text-black-1000'>
						{ekkLanguage.payment.changeDueDate}
					</p>
					<IonToggle
						checked={checked}
						onIonChange={(e) => setChecked(e.detail.checked)}
					/>
				</div>

				<div className={`${checked ? "block" : "hidden"}`}>
					<DatePicker
						minDate={moment().toDate()}
						selected={
							selectedDeliveryDate !== "Select A Date"
								? moment(selectedDeliveryDate)._d
								: moment(new Date()).add(1, "days")._d
						}
						onChange={(date) => dateChanger(date)}
						customInput={<CustomInput />}
						withPortal
					/>
				</div>
			</div>
		</>
	);
}
