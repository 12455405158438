import {
	IonCol,
	IonGrid,
	IonRow,
	IonSegment,
	IonSegmentButton,
} from "@ionic/react";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrder } from "redux/container/orderSlice";

function OrderStatusBtn() {
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<IonGrid>
			<IonRow>
				<IonCol size='12'>
					<IonSegment
						scrollable
						className='orderBtnWrapper'
						value='Delivery Failed'
						onIonChange={(e) =>
							dispatch(fetchOrder("driver", e.detail.value))
						}>
						<IonSegmentButton
							value='Delivery Failed'
							className='delivery-failed relative'>
							<button className='text-F27900 font-sans p-0 font-normal shadow-none text-14'>
								{ekkLanguage.orderList.tabMenuDeliveryFailed}
							</button>
						</IonSegmentButton>

						<IonSegmentButton
							value='Partially_Delivered'
							className='partial-delivery relative'>
							<button className='text-66D9FF-1000 font-sans p-0 font-normal shadow-none text-14'>
								{ekkLanguage.orderList.tabMenuPartialDelivered}
							</button>
						</IonSegmentButton>
						<IonSegmentButton
							value='Delivered'
							className='delivered relative'>
							<button className='text-0099CC-1000 font-sans p-0 font-normal shadow-none text-14'>
								{ekkLanguage.orderList.tabMenuDelivered}
							</button>
						</IonSegmentButton>
					</IonSegment>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
}

export default memo(OrderStatusBtn);
