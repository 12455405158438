import AuthLayout from "components/global-components/AuthLayout";
import ChangePassword from "components/pages-components/LogIn/ChangePassword";
import Password from "components/pages-components/LogIn/Password";
import PhoneNumberCountry from "components/pages-components/LogIn/PhoneNumberCountry";
import Registration from "components/pages-components/LogIn/Registration";
import Token from "components/pages-components/LogIn/Token";
import VerificationBox from "components/pages-components/LogIn/VerificationBox";
import { setLanguage } from "config/language";
import { authBox_Block } from "Constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { animated, Transition } from "react-spring/renderprops";
import { selectors } from "redux/container/authSlice";
import "../../components/pages-components/LogIn/styles/index.scss";
import DownloadPwaScreen from "./DownloadPwaModal";

const Login = (props) => {
	if (localStorage.getItem("lan") === null) {
		setLanguage("bn");
	}

	const [loading, setLoading] = useState(false);
	const [blockIndex, setBlockIndex] = useState(
		authBox_Block.PHONE_NUMBER_BLOCK
	);
	const [phoneNo, setPhoneNo] = useState("");
	const [countryCode, setCountryCode] = useState("+88");
	const [country, setCountry] = useState("Bangladesh");
	const [password, setPassword] = useState("");
	const [token, setToken] = useState("");
	const [isForgotPassword, setIsForgotPassword] = useState(false);
	const startLoading = () => setLoading(true);
	const stopLoading = () => setLoading(false);
	const [verifyNumber, setVerifyNumber] = useState(false);
	const navigate = useNavigate();

	const { loginStatus } = useSelector(selectors.getAuth);
	// if (loginStatus) return (window.location.href = "/");
	useEffect(() => {
		navigate(loginStatus ? "/" : "/login");
	}, [loginStatus]);

	return (
		<>
			{!verifyNumber ? (
				<AuthLayout title='Login' loading={loading}>
					<DownloadPwaScreen />;
					<Transition
						native
						reset
						unique
						items={blockIndex}
						from={{
							opacity: 0,
							transform: "translate3d(100%,0,0)",
						}}
						enter={{ opacity: 1, transform: "translate3d(0%,0,0)" }}
						leave={{
							opacity: 0,
							transform: "translate3d(-50%,0,0)",
						}}>
						{(index) => {
							if (index === authBox_Block.PHONE_NUMBER_BLOCK) {
								return (style) => (
									<animated.div
										className='absolute'
										style={{ ...style }}>
										<PhoneNumberCountry
											loading={loading}
											phoneNo={phoneNo}
											setPhoneNo={setPhoneNo}
											countryCode={countryCode}
											setCountryCode={setCountryCode}
											country={country}
											setCountry={setCountry}
											startLoading={startLoading}
											stopLoading={stopLoading}
											setBlockIndex={setBlockIndex}
											setVerifyNumber={setVerifyNumber}
										/>
									</animated.div>
								);
							}

							if (index === authBox_Block.PASSWORD_BLOCK) {
								return (style) => (
									<animated.div
										className='absolute'
										style={{ ...style }}>
										<div>
											<Password
												setPassword={setPassword}
												loading={loading}
												setIsForgotPassword={setIsForgotPassword}
												password={password}
												phoneNo={phoneNo}
												country={country}
												countryCode={countryCode}
												startLoading={startLoading}
												stopLoading={stopLoading}
												setBlockIndex={setBlockIndex}
											/>
										</div>
									</animated.div>
								);
							}

							if (index === authBox_Block.TOKEN) {
								return (style) => (
									<animated.div
										className='absolute'
										style={{ ...style }}>
										<div>
											<Token
												loading={loading}
												setToken={setToken}
												phoneNo={phoneNo}
												country={country}
												countryCode={countryCode}
												startLoading={startLoading}
												stopLoading={stopLoading}
												setBlockIndex={setBlockIndex}
												token={token}
												isForgotPassword={isForgotPassword}
											/>
										</div>
									</animated.div>
								);
							}

							if (index === authBox_Block.CHANGE_PASS_BLOCK) {
								return (style) => (
									<animated.div
										className='absolute'
										style={{ ...style }}>
										<div>
											<ChangePassword
												loading={loading}
												phoneNo={phoneNo}
												country={country}
												countryCode={countryCode}
												startLoading={startLoading}
												stopLoading={stopLoading}
												setBlockIndex={setBlockIndex}
												setIsForgotPassword={setIsForgotPassword}
											/>
										</div>
									</animated.div>
								);
							}

							if (index === authBox_Block.REGISTRATION_BLOCK) {
								return (style) => (
									<animated.div
										className='absolute'
										style={{ ...style }}>
										<div>
											<Registration
												loading={loading}
												phoneNo={phoneNo}
												country={country}
												countryCode={countryCode}
											/>
										</div>
									</animated.div>
								);
							}
						}}
					</Transition>
				</AuthLayout>
			) : (
				<VerificationBox
					phoneNo={phoneNo}
					country={country}
					countryCode={countryCode}
					setBlockIndex={setBlockIndex}
					setVerifyNumber={setVerifyNumber}
				/>
			)}
		</>
	);
};

export default Login;
