import React, { forwardRef, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import Deposit from "services/Deposit";
import { digitChanger } from "helpers/helper";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IonCol, IonGrid, IonRow } from "@ionic/react";

export default function SummeryCard() {
	const [selectedDate, setSelectedDate] = useState(moment(new Date()));
	const [summery, setSummery] = useState({});
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();

	let getSummery = async (date) => {
		let formateDate = moment(date).format("DD-MM-YYYY");
		let response = await Deposit.getSummery(formateDate);
		if (response.success) {
			setSummery(response.data?.summary);
		} else {
			setSummery({});
		}
	};

	useEffect(() => {
		getSummery(selectedDate);
	}, []);
	let dateChanger = (e) => {
		setSelectedDate(e);
		getSummery(e);
	};
	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className='text-center font-bold mb-5 cursor-pointer justify-center flex'
			style={{ color: "#262626" }}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center'>
					{moment(selectedDate).format("DD MMMM, YYYY")}
				</div>
			</div>
		</div>
	));
	let getDetails = (name, listName) => {
		// let formateDate = moment(selectedDate).format("DD-MM-YYYY");
		return navigate(`/analyses-details/${selectedDate}/${name}/${listName}`);
	};

	return (
		<div className=' pt-4 px-6'>
			<DatePicker
				maxDate={Date.now()}
				selected={moment(selectedDate)._d}
				onChange={(date) => dateChanger(date)}
				customInput={<ExampleCustomInput />}
				withPortal
			/>

			<div>
				<IonGrid>
					<IonRow className='mb-2'>
						<IonCol size='6' className='pr-2 mb-[6px]'>
							<div
								className='p-[10px] bg-white rounded-[7px] cursor-pointer'
								style={{
									boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, .1);",
								}}
								onClick={() =>
									getDetails("assignAmount", "assignList")
								}>
								<h4
									className='font-bold text-14 text-black-1000 pb-4 flex items-center justify-center'
									style={{ color: "#808080" }}>
									<img
										src='assets/images/user.png'
										alt='icon'
										className='pr-1'
									/>
									<span>{ekkLanguage.home.assigned}</span>
								</h4>
								<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
									৳{" "}
									{digitChanger(
										Number(summery?.assigned ?? 0).toFixed(2)
									)}
								</h2>
							</div>
						</IonCol>
						<IonCol size='6' className='pr-2 mb-[6px]'>
							<div
								className='p-[10px] bg-white rounded-[7px] cursor-pointer'
								style={{
									boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.1);",
								}}
								onClick={() =>
									getDetails("deliveredAmount", "deliveredList")
								}>
								<h4
									className='font-bold text-14 text-black-1000 pb-4 flex items-center justify-center'
									style={{ color: "#808080" }}>
									<img
										src='assets/images/fast-delivery.png'
										alt='icon'
										className='pr-1'
									/>
									<span>{ekkLanguage.home.delivered}</span>
								</h4>
								<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
									৳{" "}
									{digitChanger(
										Number(summery?.delivered ?? 0).toFixed(2)
									)}
								</h2>
							</div>
						</IonCol>
						<IonCol size='6' className='pr-2 mb-[6px]'>
							<div
								className='p-[10px] bg-white rounded-[7px] cursor-pointer'
								style={{
									boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.1);",
								}}
								onClick={() =>
									getDetails("returnAmount", "returnList")
								}>
								<h4
									className='font-bold text-14 text-black-1000 pb-4 flex items-center justify-center'
									style={{ color: "#808080" }}>
									<img
										src='assets/images/undo.png'
										alt='icon'
										className='pr-1'
									/>
									<span>{ekkLanguage.home.returned}</span>
								</h4>
								<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
									৳{" "}
									{digitChanger(
										Number(summery?.returned ?? 0).toFixed(2)
									)}
								</h2>
							</div>
						</IonCol>
						<IonCol size='6' className='pr-2 mb-[6px]'>
							<div
								className='p-[10px] bg-white rounded-[7px] cursor-pointer'
								style={{
									boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.1);",
								}}>
								<h4
									className='font-bold text-14 text-black-1000 pb-4 flex items-center justify-center'
									style={{ color: "#808080" }}>
									<img
										src='assets/images/sign.png'
										alt='icon'
										className='pr-1'
									/>
									<span>{ekkLanguage.home.collected}</span>
								</h4>
								<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
									৳{" "}
									{digitChanger(
										Number(summery?.collected ?? 0).toFixed(2)
									)}
								</h2>
							</div>
						</IonCol>
						<IonCol size='6' className='pr-2 mb-[6px]'>
							<div
								className='p-[10px] bg-white rounded-[7px] cursor-pointer'
								style={{
									boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.1);",
								}}>
								<h4
									className='font-bold text-14 text-black-1000 pb-4 flex items-center justify-center'
									style={{ color: "#808080" }}>
									<img
										src='assets/images/deposit.png'
										alt='icon'
										className='pr-1'
									/>
									<span>{ekkLanguage.home.deposited}</span>
								</h4>
								<h2 className='font-bold text-24 text-center text-black-1000 pb-5'>
									৳{" "}
									{digitChanger(
										Number(summery?.deposit ?? 0).toFixed(2)
									)}
								</h2>
							</div>
						</IonCol>
					</IonRow>
				</IonGrid>
			</div>
		</div>
	);
}
