import { baseUrl } from "config/apiUrlConfig";
import SuperFetch from "config/SuperFetch";
// https://dorder.ekkbaz.com/v4/orders/business/list?limit=10&page=1
const Orders = {
	// getOrdersList: (page, limit) => {
	// 	return SuperFetch(
	// 		`${baseUrl.order}/v4/orders/business/list?limit=${limit}&page=${page}`,
	// 		{
	// 			method: "GET",
	// 		}
	// 	);
	// },

	getOrdersList: (assign, status) => {
		return SuperFetch(
			`${baseUrl.order}/v5/orders/assign-drive-collector?assign=${assign}&status=${status}`
		);
	},
	getCollectionsList: (page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.order}/v5/orders/by-user/collections?page=${page}&limit=${limit}&search`
		);
	},
	searchCollectionsList: (query = "", page = 1, limit = 15) => {
		return SuperFetch(
			`${baseUrl.order}/v5/orders/by-user/collections?search=${query}&page=${page}&limit=${limit}`
		);
	},
	orderSearchById: (id) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/order_id/${id}`, {
			method: "GET",
		});
	},
	orderListBySorted: (value, page = 1, limit = 500) => {
		return SuperFetch(
			`${baseUrl.order}/v4/orders/business/list?limit=${limit}&page=${page}&order_status=${value}`,
			{
				method: "GET",
			}
		);
	},
	singleOrder: (id) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/details/${id}`);
	},
	// https://api.ekkbaz.com/order/v4/orders/details/62bbe678576ccf3565557d1c
	createOrder: (data) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/create`, {
			method: "POST",
			data: data,
		});
		// /v4/orders/create
	},
	// createOrder: (data) => {
	//   return SuperFetch(`http://localhost:3005/v4/orders/create`, {
	//     method: "POST",
	//     data: data,
	//   });
	//   // /v4/orders/create
	// },
	todayPurchaseSummery: (start, end) => {
		return SuperFetch(
			`${baseUrl.order}/v4/orders/my-business/total-order-details?date_start=${start}&date_end=${end}`
		);
	},
	searchOrder: (id, limit, page) => {
		return SuperFetch(
			`${baseUrl.order}/v4/orders/custom/search?page=${page}&limit=${limit}&order_id=${id}`
		);
	},
	orderCancel: (id) => {
		return SuperFetch(`${baseUrl.order}/v4/orders/update/${id}`, {
			method: "PUT",
			data: { order_status: "Cancel" },
		});
	},
	orderAmountCollection: (id) => {
		return SuperFetch(
			`${baseUrl.order}/v5/orders/order-amount-collection/${id}`
		);
	},
	getSingleCollection: (id) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/details/${id}`);
	},
	orderUpdate: (id, query) => {
		return SuperFetch(`${baseUrl.order}/v4/orders/update/${id}`, {
			method: "PUT",
			data: query,
		});
	},
	collectorAmountDetails: (date) => {
		return SuperFetch(
			`${baseUrl.order}/ekkhero/orders/collection/collection-amount-summery-hero-date/details?dateValue=${date}`
		);
	},
	getCollectionsOrderList:()=>{
		return SuperFetch(
			`${baseUrl.order}/ekkbaz/orders/collector-deposit-user-list`
		);
	}
};
export default Orders;
