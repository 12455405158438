import React from "react";
import Card from "../Common/Card";
import "moment-timezone";
import { useSelector } from "react-redux";
import { digitChanger } from "helpers/helper";
import moment from "moment-timezone";
export default function DeliveryCard({ bankInfo }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<div className='px-2 pb-5'>
			<Card title={ekkLanguage.bankDetailsPage.paymentDetails}>
				<div>
					<table className='table-fixed w-full paymentSettlementLabel'>
						<tbody className="before:content-[' '] before:block before:h-2 border-b-2">
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.bankDetailsPage.bankName}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{bankInfo?.bankName}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.bankDetailsPage.depositDate}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{digitChanger(
										moment(bankInfo?.createdAt).format("DD-MM-YYYY")
									)}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.bankDetailsPage.totalOrder}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{bankInfo?.totalOrders}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.bankDetailsPage.collectionAmount}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{digitChanger(
										Number(bankInfo?.collectedAmount).toFixed(2)
									)}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.bankDetailsPage.depositAmount}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{digitChanger(
										Number(bankInfo?.payAmount).toFixed(2)
									)}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.bankDetailsPage.deuDeposit}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{digitChanger(
										Number(
											bankInfo?.collectedAmount - bankInfo?.payAmount
										).toFixed(2)
									)}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.bankDetailsPage.depositTicketNumber}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{bankInfo?.depositTicketNumber}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.bankDetailsPage.transactionNumber}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{bankInfo?.transactionNumber}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.bankDetailsPage.branchSubBranch}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{bankInfo?.branchSubBranch}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{bankInfo.bankName === "EkkBaz Hub"
										? ekkLanguage.bankDetailsPage.hubAddress
										: ekkLanguage.bankDetailsPage.bankAddress}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{bankInfo?.bankAddress}
								</td>
							</tr>
							<tr>
								<td
									colspan='2'
									className='text-black-1000 text-12 font-bold py-[6px]'>
									{ekkLanguage.bankDetailsPage.comment}
									<p className='pt-[5px] text-12 text-black-500 font-normal'>
										{bankInfo?.comment}
									</p>
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									<img
										src={bankInfo?.signature}
										alt='signature'
										className='rounded-[5px] bg-white block h-[100px] m-auto'
										style={{
											border: "0.5px solid rgba(0, 0, 0, 0.3)",
										}}
									/>
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									<img
										src={bankInfo?.depositSlip}
										alt='billImage'
										className='rounded-[5px] block h-[100px] m-auto'
										style={{
											border: "0.5px solid rgba(0, 0, 0, 0.3)",
											background: "#E6E6E6",
										}}
									/>
								</td>
							</tr>
						</tbody>
						{/* {Array.isArray(collection) && collection.length !== 0 ? (
							collection.map((el, i) => {
								return (
									<tbody className="before:content-[' '] before:block before:h-2 border-b-2">
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.orderDetails.date}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{digitChanger(
													moment(el?.createdAt).format(
														"DD-MM-YYYY"
													)
												)}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.orderDetails.amount}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{digitChanger(el?.amount)}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.orderDetails.collectedBy}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{el?.collectedBy}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.orderDetails.dueDate}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{digitChanger(
													moment(el?.dueDate).format("DD-MM-YYYY")
												)}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												{ekkLanguage.orderDetails.remark}
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												{el?.remark}
											</td>
										</tr>
										<tr>
											<td className='text-000080 text-12 font-medium py-[6px]'>
												<img
													src={el?.signature}
													alt='signature'
													className='rounded-[5px] bg-white block h-[100px] m-auto'
													style={{
														border:
															"0.5px solid rgba(0, 0, 0, 0.3)",
													}}
												/>
											</td>
											<td className='text-000080 text-12 font-medium py-[6px] text-right'>
												<img
													src={el?.payImage}
													alt='billImage'
													className='rounded-[5px] block h-[100px] m-auto'
													style={{
														border:
															"0.5px solid rgba(0, 0, 0, 0.3)",
														background: "#E6E6E6",
													}}
												/>
											</td>
										</tr>
									</tbody>
								);
							})
						) : (
							<p className='text-222222 text-12 font-semibold pt-3'>
								{ekkLanguage.orderDetails.withoutSettelment}
							</p>
						)} */}
					</table>
				</div>
			</Card>
		</div>
	);
}
{
	/* <div className='text-000080 text-12 font-medium py-[6px] w-1/2'> */
}
// 	<img
// 		src={el?.signature}
// 		alt='signature'
// 		className='rounded-[5px] bg-white mr-2 block'
// 		style={{
// 			border:
// 				"0.5px solid rgba(0, 0, 0, 0.3)",
// 		}}
// 	/>
// </div>
// <div className='text-000080 text-12 font-medium py-[6px] text-right w-1/2'>
// 	<img
// 		src={el?.payImage}
// 		alt='billImage'
// 		className='rounded-[5px] ml-2 block'
// 		style={{
// 			border:
// 				"0.5px solid rgba(0, 0, 0, 0.3)",
// 			background: "#E6E6E6",
// 			objectFit: "contain",
// 		}}
// 	/>
{
	/* </div> */
}
