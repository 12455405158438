import { IonCol, IonRow } from "@ionic/react";
import { useSelector } from "react-redux";

export default function CustomerSupplierCards({ order }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<IonRow>
			<IonCol size='6'>
				<div
					className='text-center  bg-white rounded-[10px] mb-7 h-[80px]'
					style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)" }}>
					<div className='inline-block text-center px-3 pt-4'>
						<p className='text-black-333 text-12 pb-[5px]'>
							{" "}
							{ekkLanguage.deliverForm.customer}
						</p>
						<h6 className='text-black-1000 text-14 pb-[10px] font-bold'>
							{order?.buyer?.business_name}
						</h6>
					</div>
				</div>
			</IonCol>
			<IonCol size='6'>
				<div
					className='text-center  bg-white rounded-[10px] mb-7 h-[80px]'
					style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)" }}>
					<div className='inline-block text-left px-3 pt-4'>
						<p className='text-black-333 text-center text-12 pb-[5px]'>
							{ekkLanguage.deliverForm.supplier}
						</p>
						<h6 className='text-black-1000 text-14 pb-[10px] font-bold'>
							{order?.seller?.business_name}
						</h6>
					</div>
				</div>
			</IonCol>
		</IonRow>
	);
}
