import {
  IonApp,
  IonContent,
  IonPage,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import OrderMetaBar from "components/mobile-components/OrderDetails/OrderMetaBar";
import TimeLine from "components/mobile-components/OrderDetails/TimeLine";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderSingle } from "redux/container/orderSlice";
import { useParams } from "react-router-dom";
import BottomSheet from "components/mobile-components/OrderDetails/BottomSheet";
import Orders from "services/Orders";
import CostDetailsCard from "components/global-components/common/CostDetailsCard";
import OrderMeta from "components/mobile-components/CollectionHistoryDetails/OrderMeta";
import PaymentDetailsCard from "components/global-components/common/PaymentDetailsCard";
import PaymentSettlementCard from "components/global-components/common/PaymentSettlement";

export default function CollectionHistoryListDetailsPage() {
  let { id } = useParams();
  const response = useSelector((state) => state.order);
  let [collection, setCollection] = useState([]);
  let [singleOrder, setSingleOrder] = useState({});

  const fetchOderCollectionAmount = async () => {
    let response = await Orders.orderAmountCollection(id);
    if (response?.status === 200) {
      setCollection(response.data);
    }
  };
  const getSingleOrder = async () => {
    let response = await Orders.getSingleCollection(id);
    if (response?.status === 200) {
      setSingleOrder(response.data);
    }
  };

  useEffect(() => {
    getSingleOrder();
    fetchOderCollectionAmount(id);
  }, []);

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <BackButtonTitle
            title={response?.singleOrder?.created_by_business?.business_name}
            from="orderDetailsPage"
          />

          <OrderMetaBar order={singleOrder} />
          <section className="px-2 pt-24">
            <OrderMeta order={singleOrder} />
            <CostDetailsCard order={singleOrder} />
            <PaymentDetailsCard order={singleOrder} />
            <PaymentSettlementCard collection={collection} />
            <TimeLine order={singleOrder} />
            <BottomSheet order={singleOrder} />
          </section>
          {/* <TimeLineTextBox order={response.singleOrder} /> */}
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
