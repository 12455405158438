import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { bankListArray } from "data/bankList";
import React from "react";
import { useSelector } from "react-redux";

export default function BankInfo({ bankInfo }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let verified = require("assets/images/bank/verified-full.png");
	let notVerified = require("assets/images/bank/notVerified-full.png");
	let selectedBank = bankListArray.find((el) => el.name === bankInfo.bankName);
	return (
		<div className='mt-[30px]'>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div className='text-center mb-5 relative'>
							<img
								src={
									bankInfo.status === "unVerify"
										? notVerified
										: verified
								}
								alt='verified check'
								className='absolute top-[-20px] left-0'
							/>
							<div className=''>
								<img
									src={selectedBank?.logo}
									alt='bankLogo'
									className='pb-2 m-auto'
								/>
							</div>

							<h6 className='text-16 font-normal text-black-1000'>
								{bankInfo?.bankName}
							</h6>
						</div>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
}
