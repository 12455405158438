import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import OrderStatus from "components/mobile-components/DriverOrderList/OrderStatus";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchOrder } from "redux/container/orderSlice";

export default function OrderHistoryListPage() {
  const { category } = useParams();
  const ekkLanguage = useSelector((state) => state.lan.lan);

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <BackButtonTitle title={ekkLanguage.orderList.orderHistoryList} />
          <section>
            <OrderStatus category={category} />
          </section>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
