import { IonApp, IonContent, IonLoading, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import DepositsList from "components/mobile-components/DepositsList";
import BottomSheet from "components/mobile-components/DepositsList/BottomSheet";

import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Deposit from "services/Deposit";

export default function DepositsListPage() {
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [depositList, setDepositList] = useState([]);
  const [loading, setLoading] = useState(false);
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  const ekkLanguage = useSelector((state) => state.lan.lan);

  const getDepositList = async () => {
    setLoading(true);
    let response = await Deposit.getDepositList();
    if (response.status === 200) {
      setLoading(false);
      setDepositList(response.data);
    } else {
      setLoading(false);
    }
  };
  const getBankInfoFilter = async (status) => {
    let response = await Deposit.getDepositListFilter(status);
    if (response.status === 200) {
      setDepositList(response.data);
    } else {
    }
  };

  useEffect(() => {
    getDepositList();
  }, []);
  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.depositsList.pageTitle}
            burgerMenu={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
            from="depositsList"
          />
          <IonLoading isOpen={loading} message={ekkLanguage.orderList.loader} />
          <section>
            <DepositsList
              depositList={depositList}
              loading={loading}
              getDepositList={getDepositList}
              getBankInfoFilter={getBankInfoFilter}
            />
          </section>
          <BottomSheet />
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
