import { IonCol } from "@ionic/react";
import Cross from "assets/images/icon/svg/Cross";
import Minus from "assets/images/icon/svg/Minus";
import Plus from "assets/images/icon/svg/Plus";
import { digitChanger, translator } from "helpers/helper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	deliverItemDecrease,
	deliverItemIncrease,
	productRemove,
} from "redux/container/orderSlice";

export default function SingleProductForCard({ image, item, setProductList }) {
	let quantity = item.quantity;

	const singleOrder = useSelector(
		(state) => state.order?.singleOrder?.order_items
	);
	let orderItemsQty = singleOrder?.find((el) => el._id === item._id).quantity;

	item = item?.catalog;
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [qty, setQty] = useState(quantity);
	const dispatch = useDispatch();

	return (
		<IonCol size='12' className='p-0' key={item.id}>
			<div className='border rounded-[10px] p-2 mt-5 border-black-100 overflow-hidden relative'>
				<span
					className=' absolute right-2 top-2 font-semibold cursor-pointer'
					onClick={() => {
						dispatch(productRemove(item._id));
					}}>
					<Cross />
				</span>
				<div className='flex pb-4'>
					<div
						style={{ width: 100, height: 100 }}
						className=' bg-F3F3F3 rounded-[10px] text-center flex items-center justify-center overflow-hidden'>
						<img
							src={image}
							alt='porductImage'
							style={{ objectFit: "contain" }}
						/>
					</div>
					<div className='ml-3 flex-1 pr-4'>
						<h2 className='text-14 font-semibold mb-3 text-black'>
							{item?.product?.title}
						</h2>
						<h3 className='text-primary text-16 font-bold mb-2'>
							{" "}
							{item?.country?.currency_symbol_native}{" "}
							{digitChanger(item?.base_price)}{" "}
							<span className='text-black-1000 text-12 font-normal'>
								{" "}
								* {digitChanger(quantity)} ={" "}
								{item?.country?.currency_symbol_native}{" "}
								{digitChanger(
									Number(item?.base_price * quantity).toFixed(2)
								)}
							</span>
						</h3>
						<span
							className={`text-10 font-medium ${
								qty >= item?.minimum_order_quantity
									? "text-000080"
									: "text-primary"
							} `}>
							{ekkLanguage.createOrder.moqLabel}:{" "}
							{digitChanger(item?.minimum_order_quantity)}
							{ekkLanguage.createOrder.pcsLabel}
						</span>
					</div>
				</div>
				<div className='flex'>
					<div>
						{quantity > item.minimum_order_quantity && (
							<span
								className='bg-primary w-8 h-9 flex justify-center items-center rounded-md leading-6'
								style={{
									boxShadow: "0px 3px 10px rgba(0,0,0,.15)",
								}}
								onClick={() => {
									dispatch(deliverItemDecrease(item._id));
								}}>
								<Minus />
							</span>
						)}
					</div>

					<div className='w-full px-3'>
						<input
							className={`appearance-none w-full rounded-md h-9 leading-6 text-center ${
								qty >= item.minimum_order_quantity
									? "bg-F3F3F3"
									: "bg-F94B41-200"
							}  `}
							type='number'
							value={quantity}
							readOnly
							// onChange={(e) => {
							// 	if (e.target.value > -1) {
							// 		setQty(e.target.value);
							// 	} else {
							// 		setQty(0);
							// 	}
							// }}
						></input>
					</div>
					<div>
						{orderItemsQty > quantity && (
							<span
								className='bg-success w-8 h-9 flex justify-center items-center rounded-md leading-6'
								style={{
									boxShadow: "0px 3px 10px rgba(0,0,0,.15)",
								}}
								onClick={() => {
									dispatch(deliverItemIncrease(item._id));
								}}>
								<Plus />
							</span>
						)}
					</div>
				</div>
			</div>
		</IonCol>
	);
}
