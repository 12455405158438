import { IonRow } from "@ionic/react";
import { useSelector } from "react-redux";
import Card from "../Common/Card";
// import ProductQuantityInput from "./ProductQuantityInput";
import SingleProductForCard from "./SingleProductForCard";
export default function ProductCard() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const order = useSelector((state) => state.order.deliveredItems);

	return (
		<div className='px-2 pt-0 pb-7'>
			<Card title={ekkLanguage.createOrder.productItemTitle}>
				<div>
					<IonRow>
						{order.productItems &&
							order.productItems.map((item, i) => {
								let image;
								if (item?.catalog?.product?.images?.length) {
									image = item?.catalog?.product?.images[0];
								} else {
									if (item?.catalog?.product?.type === "variant") {
										image = "/assets/images/varient_default.png";
									} else if (item?.catalog?.product?.type === "pack") {
										image = "/assets/images/pack_default.png";
									} else if (
										item?.catalog?.product?.type === "offer"
									) {
										image = "/assets/images/offer_default.png";
									}
								}
								return (
									<SingleProductForCard
										image={image}
										item={item}
										key={i}
									/>
								);
							})}
					</IonRow>
				</div>
			</Card>
		</div>
	);
}
