import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { addStatusClass, digitChanger, translator } from "helpers/helper";
import { useSelector } from "react-redux";

export default function OrderMeta({ order }) {
	// let status = addStatusClass(order?.order_status);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<div className='mt-9 mb-4'>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div className='text-center mb-7'>
							<h2 className='text-black-1000 text-20 font-bold mb-3'>
								{order?.created_by_business?.business_name}
							</h2>
							<h3 className='text-primary text-20 font-bold mb-3'>
								<span className='text-delivered'>
									{translator(order?.currency)}{" "}
									{digitChanger(order?.total?.toFixed(2))}{" "}
									{order?.order_status !== "Paid" && "/ "}
								</span>
								{order?.order_status !== "Paid" && (
									<>
										{ekkLanguage.orderDetails.remaining} {":"}{" "}
										{translator(order?.currency)}{" "}
										{digitChanger(
											(order?.total - order?.total_paid).toFixed(2)
										)}
									</>
								)}
							</h3>
							<p className='text-black-500 text-16 font-bold'>
								{order?.order_origin && "Origin: EkkBaz |"}{" "}
								{ekkLanguage.orderDetails.createdBy}{" "}
								{order?.created_by_user?.full_name}
							</p>
						</div>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
}
