import { digitChanger } from "helpers/helper";
import { useSelector } from "react-redux";
import Card from "../Common/Card";

export default function CostSummeryCard({ order }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<div className='px-2 pb-5'>
			<Card title={ekkLanguage.orderDetails.castItemLabel}>
				<div>
					<table className='table-fixed w-full'>
						<tbody className="before:content-[' '] before:block before:h-2 after:content-[' '] after:block after:h-2">
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.subtotal}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.country?.currency_symbol_native}{" "}
									{digitChanger(order?.sub_total?.toFixed(2))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.tax}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.country?.currency_symbol_native}{" "}
									{digitChanger(order?.tax_total?.toFixed(2))}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.discount}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.country?.currency_symbol_native}{" "}
									{digitChanger(
										Number(order?.discountAmount ?? 0.0).toFixed(2)
									)}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.deliveryFee}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.country?.currency_symbol_native}{" "}
									{digitChanger(
										Number(order?.deliveryFee ?? 0.0).toFixed(2)
									)}
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[6px]'>
									{ekkLanguage.orderDetails.serviceFee}
								</td>
								<td className='text-000080 text-12 font-medium py-[6px] text-right'>
									{order?.country?.currency_symbol_native}{" "}
									{digitChanger(
										order?.payment_term?.creditFee?.toFixed(2)
									)}
								</td>
							</tr>
						</tbody>
						<tfoot className='border-t'>
							<tr>
								<td className='text-primary text-12 font-bold py-[6px]'>
									{ekkLanguage.orderDetails.total}
								</td>
								<td className='text-primary text-12 font-bold py-[6px] text-right'>
									{order?.country?.currency_symbol_native}{" "}
									{order?.order_status === "Partially_Delivered"
										? digitChanger(Number(order?.total).toFixed(2))
										: digitChanger(Number(order?.total).toFixed(2))}
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</Card>
		</div>
	);
}
