import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import "./styles/index.scss";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import configureStore from "./redux/store/configureStore";
import { serviceWorker } from "serviceworker";
import { BrowserRouter } from "react-router-dom";
const { store, persistor } = configureStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ToastContainer position="top-right" autoClose={2000} />
      <GoogleReCaptchaProvider reCaptchaKey="6LcU1z0hAAAAAHJMWGUZudi6kUuTw5ZeF0qOosQh">
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// ============= Service Worker =============
serviceWorker();
