import React from "react";
import BankSearch from "./BankSearch";
import StatusButtons from "./StatusButtons";
import BankCard from "./BankCard";

export default function DepositsList({
	depositList,
	loading,
	getDepositList,
	getBankInfoFilter,
}) {
	return (
		<div className='px-2 pt-14'>
			{/* <BankSearch /> */}
			<StatusButtons
				getDepositList={getDepositList}
				getBankInfoFilter={getBankInfoFilter}
			/>
			<BankCard depositList={depositList} loading={loading} />
		</div>
	);
}
