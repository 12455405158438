import { IonCol, IonGrid, IonRow, IonSearchbar } from "@ionic/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchOrder, searchOrder } from "redux/container/orderSlice";

export default function OrderInvoiceSearch() {
	const [text, setText] = useState("");
	const dispatch = useDispatch();

	const _handleKeyPress = (e) => {
		if (e.key === "Enter") {
			dispatch(searchOrder(text, 500, 1));
		}
	};

	return (
		<IonGrid>
			<IonRow>
				<IonCol size='12'>
					<div>
						<IonSearchbar
							className='p-0'
							type='number'
							placeholder='Search your Order'
							value={text}
							onKeyPress={_handleKeyPress}
							onIonChange={(e) => {
								setText(e.target.value);
								if (e.target.value === "") {
									dispatch(fetchOrder(1, 500));
								}
							}}></IonSearchbar>
					</div>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
}
