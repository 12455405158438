import { IonCol, IonGrid, IonRow, IonSearchbar } from "@ionic/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrder, searchOrder } from "redux/container/orderSlice";

export default function BankSearch() {
	const [text, setText] = useState("");
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const _handleKeyPress = (e) => {
		if (e.key === "Enter") {
			// dispatch(searchOrder(text, 500, 1));
		}
	};

	return (
		<IonGrid>
			<IonRow>
				<IonCol size='12'>
					<div>
						<IonSearchbar
							className='p-0'
							type='number'
							placeholder={ekkLanguage.depositsList.searchLabel}
							value={text}
							onKeyPress={_handleKeyPress}
							onIonChange={(e) => {
								setText(e.target.value);
								if (e.target.value === "") {
									// dispatch(fetchOrder(1, 500));
								}
							}}></IonSearchbar>
					</div>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
}
