import { IonCheckbox, IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import { digitChanger, statusTranslator } from "helpers/helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function SelectOrders({
  collectionList,
  selectedCollectionList,
  setSelectedCollectionList,
  collectorLoader,
  setPayAmount,
  selectAll,
  setSelectAll,
}) {
  const ekkLanguage = useSelector((state) => state.lan.lan);

  // handle Select All Function
  const handleSelectAll = () => {
    const allCollections = collectionList || [];
    const updatedList = selectAll ? new Set() : new Set(allCollections);

    const totalAmount = Array.from(updatedList).reduce(
      (sum, item) => sum + (item?.amount || 0),
      0
    );

    setPayAmount(totalAmount?.toFixed(2));
    setSelectedCollectionList(Array.from(updatedList));
    setSelectAll(!selectAll);
  };

  // If there is no selected collection, then show select all
  useEffect(() => {
    if (selectedCollectionList?.length === 0) {
      setSelectAll(false);
    }
    if (selectedCollectionList?.length === collectionList?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedCollectionList, setSelectAll, collectionList]);

  return (
    <IonGrid className=" rounded-sm">
      <div className="flex justify-between items-center mb-2 px-2">
        <h6 className={`font-bold  py-0.5 px-1 text-14`}>
          {ekkLanguage.collectionOrderList.pageTitle}
        </h6>
        {collectionList?.length > 1 && (
          <div className="flex">
            {" "}
            <IonCheckbox
              justify="start"
              className={`  py-0.5 text-14 selection-collection ${
                selectAll ? "text-success" : "text-black-1000 "
              }`}
              onIonChange={handleSelectAll}
              checked={selectAll}
              // labelPlacement="end"
              // slot="end"
              color="success"
            ></IonCheckbox>
            <h6
              onClick={handleSelectAll}
              className={`  py-0.5 ml-2 text-14 selection-collection ${
                selectAll ? "text-success" : "text-black-1000 "
              }`}
            >
              {ekkLanguage.collectionOrderList.selectAll}
            </h6>
          </div>
        )}
      </div>

      <IonRow>
        {collectorLoader ? (
          <IonLoading
            isOpen={true}
            message={ekkLanguage.orderList?.loader}
          ></IonLoading>
        ) : collectionList?.length ? (
          collectionList.map((collection, index) => {
            return (
              <IonCol size="6" className="mb-1" key={index}>
                <div
                  className="rounded-md"
                  onClick={() => {
                    const updatedList = new Set(selectedCollectionList);
                    let col = { ...collection };
                    delete col?.order;
                    const hasCol = Array.from(updatedList).some(
                      (item) => item._id === col._id
                    );

                    if (hasCol) {
                      updatedList.delete(
                        Array.from(updatedList).find(
                          (item) => item._id === col._id
                        )
                      );
                    } else {
                      updatedList.add(col);
                    }
                    const totalAmount = Array.from(updatedList).reduce(
                      (sum, item) => sum + (item?.amount || 0),
                      0
                    );
                    setPayAmount(totalAmount?.toFixed(2));
                    setSelectedCollectionList(Array.from(updatedList));
                  }}
                >
                  <div className="rounded-xl overflow-hidden bg-white p-2 mb-1 relative">
                    <span className="absolute" style={{ top: -1, right: -1 }}>
                      {selectedCollectionList.find(
                        (select) => select?._id === collection?._id
                      ) && (
                        <IonCheckbox
                          color="success"
                          className="rounded select-collection"
                          checked={true}
                        />
                      )}
                    </span>
                    <div className="flex flex-col">
                      <h5 className="font-semibold text-12 mb-0.5">
                        {collection?.order?.buyer?.business_name}
                      </h5>
                      <div className="flex justify-between mb-0.5">
                        <span className="font-bold text-10 ">
                          ৳ {digitChanger(collection?.amount.toFixed(2))}
                        </span>
                        <span className="font-bold text-10 ">
                          {digitChanger(collection?.orderId)}
                        </span>
                      </div>
                      <div className="flex justify-between mb-0.5">
                        <span className="font-bold text-10 ">
                          {statusTranslator(collection?.order?.order_status)}
                        </span>
                        <span className="font-bold text-10 ">
                          {digitChanger(
                            moment(collection?.createdAt).format("hh:mm a")
                          )}
                        </span>
                      </div>

                      <div className="flex ">
                        <span className="font-bold text-10 text-[#00000080]">
                          {collection?.order?.buyer?.address_line}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </IonCol>
            );
          })
        ) : (
          <>
            <IonCol size="2"></IonCol>
            <IonCol size="8">
              <div
                className="text-center flex items-center justify-center"
                style={{ height: "calc(100vh - 420px)" }}
              >
                <div>
                  <img
                    className="m-auto mb-[10px] mt-5"
                    src="/assets/images/not-found-file.png"
                    alt="emptyCard"
                  />
                  <p className="text-16 font-normal text-222222 mb-[35px]">
                    {ekkLanguage.orderList.notFound}
                  </p>
                </div>
              </div>
            </IonCol>
          </>
        )}
      </IonRow>
    </IonGrid>
  );
}
