import { baseUrl } from "config/apiUrlConfig";
import SuperFetch from "config/SuperFetch";

// /articles/filter/category?&page=1&limit=10&context=Article&categories=Corporate
const Blog = {
  getLatestJobList: (page = 1, country, limit = 5, lan = "EN") => {
    return SuperFetch(
      `${baseUrl.social}/articles/filter/category?&page=${page}&limit=${limit}&categories=EkkHero App Job Post`
    );
  },
  singleJobPost: (id) => {
    return SuperFetch(`${baseUrl.social}/articles/${id}`);
  },
  learningList: (page = 1, limit = 5, lan) => {
    return SuperFetch(
      `${baseUrl.social}/articles/filter/category?page=${page}&limit=${limit}&context=Article&categories=Learning&language=${lan}`
    );
  },
  learningDetails: (id) => {
    return SuperFetch(`${baseUrl.social}/articles/${id}`);
  },
  getCommentList: (id, page = 1, limit = 10) => {
    return SuperFetch(`${baseUrl.social}/articles/comment-list/${id}`);
  },
  addComment: (id, comment) => {
    return SuperFetch(`${baseUrl.social}/articles/add-comment/${id}`, {
      method: "PUT",
      data: comment,
    });
  },
  addLike: (id) => {
    return SuperFetch(`${baseUrl.social}/articles/add-like/${id}`, {
      method: "PUT",
    });
  },
};

export default Blog;