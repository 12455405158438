import React from "react";
import Sheet from "react-modal-sheet";
import Print from "assets/images/icon/svg/Print";
import { IonItem } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import {
	setBottomSheet,
	setDepositBottomSheet,
} from "redux/container/userSlice";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";

export default function BottomSheet({ order }) {
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector(
		(state) => state.user.newDepositBottomSheet
	);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[120, 120, 100, 0]}
				onClose={() => dispatch(setDepositBottomSheet(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => navigate(`/bank-list`)}
							lines='none'>
							<span className='py-[5px]'>
								<Print />
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.depositsList.newDeposit}
							</p>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop
					onClick={() => dispatch(setDepositBottomSheet(false))}
				/>
			</Sheet>
		</div>
	);
}
