import { IonButton } from "@ionic/react";
import Trash from "assets/images/icon/svg/Trash";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";

export default function Signature({ signatureImage, setSignatureImage }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const signatureRef = useRef();
	const clear = () => signatureRef.current.clear();
	const save = () => {
		setSignatureImage(
			signatureRef.current.getTrimmedCanvas().toDataURL("image/png")
		);
	};
	useEffect(() => {}, [signatureImage]);

	return (
		<div className='pt-7'>
			<div className='flex items-center justify-between pb-[10px]'>
				<p className='text-16 text-black-1000 font-semibold'>
					{ekkLanguage.payment.signature}{" "}
				</p>
				<span
					onClick={clear}
					className='h-10 w-10 bg-white rounded-[7px] text-center flex items-center justify-center'
					style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)" }}>
					<Trash />
				</span>
			</div>
			<div className='text-left rounded-[10px]'>
				<div
					onMouseLeave={save}
					onTouchEnd={save}
					className='bg-white rounded-[10px] overflow-hidden'
					style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)" }}>
					<SignatureCanvas
						ref={signatureRef}
						penColor='black'
						canvasProps={{
							width: window.innerWidth,
							height: 200,
							className: "sigCanvas",
						}}
					/>
				</div>
			</div>
		</div>
	);
}
