import {
	IonCol,
	IonGrid,
	IonRow,
	IonSegment,
	IonSegmentButton,
} from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrder, fetchOrderListBySorted } from "redux/container/orderSlice";

export default function StatusButtons({ getDepositList, getBankInfoFilter }) {
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<IonGrid>
			<IonRow>
				<IonCol size='12'>
					<IonSegment scrollable className='orderBtnWrapper'>
						<IonSegmentButton
							className='recent relative'
							onClick={() => {
								getDepositList();
							}}>
							<button className='text-black-1000 font-sans p-0 font-normal shadow-none text-14'>
								{ekkLanguage.depositsList.recent}
							</button>
						</IonSegmentButton>
						<IonSegmentButton
							className='cancel relative'
							onClick={() => {
								getBankInfoFilter("unVerify");
							}}>
							<button className='text-F94B41-1000 font-sans p-0 font-normal shadow-none text-14'>
								{ekkLanguage.depositsList.notVerified}
							</button>
						</IonSegmentButton>
						<IonSegmentButton
							className='paid relative'
							onClick={() => {
								getBankInfoFilter("Verified");
							}}>
							<button className='text-36A94D-1000 font-sans p-0 font-normal shadow-none text-14'>
								{ekkLanguage.depositsList.verified}
							</button>
						</IonSegmentButton>
					</IonSegment>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
}
