export const DEVELOPMENT = {
	pass: "https://dpassapi.azurewebsites.net",
	business: "https://dbusiness.ekkbaz.com",
	product: "https://dproduct.ekkbaz.com",
	order: "https://dorderapi.azurewebsites.net",
	bucket: "https://dfileapi.azurewebsites.net",
	ekkhero: "https://eh2t.azurewebsites.net",
};
// export var baseUrl = DEVELOPMENT;
// previous apis
// export const PRODUCTION = {
// 	pass: "https://ppass.ekkbaz.com",
// 	business: "https://pbusiness.ekkbaz.com",
// 	product: "https://pproduct.ekkbaz.com",
// 	order: "https://order.ekkbaz.com",
// 	bucket: "https://pfiles.ekkbaz.com",
// 	ekkhero: "https://ekkhero.ekkbaz.com",
// };

// migrated apis
export const PRODUCTION = {
	pass: "https://ppassapi.azurewebsites.net",
	business: "https://pbusiness.ekkbaz.com",
	product: "https://pproduct.ekkbaz.com",
	order: "https://porderapi.azurewebsites.net",
	bucket: "https://pfileapi.azurewebsites.net",
	ekkhero: "https://ekkhero.ekkbaz.com",
};
export var baseUrl = PRODUCTION;
