import { IonCol, IonGrid, IonRow } from "@ionic/react";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

export default function TimeLine({ order }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='pt-5'>
			<IonGrid>
				<IonRow>
					<IonCol size='10'>
						<div className='pb-4'>
							<h2 className='text-black-1000 text-18 font-semibold'>
								{ekkLanguage.orderDetails.timelineLabel}
							</h2>
						</div>
					</IonCol>
				</IonRow>
				<IonRow>
					{order?.order_timeline &&
						order?.order_timeline.map((item, i) => {
							return (
								<IonCol size='12' key={i}>
									<div className='flex mb-3 p-4 bg-white rounded-[10px]'>
										{/* <img
								src='assets/images/author.png'
								alt='author'
								className='w-10 h-10'></img> */}
										<div className='pl-[10px]'>
											<div>
												<h6 className='pb-1 text-14 text-black-1000 font-bold'>
													{item?.author}
													<span className='text-black-500'>
														{" "}
														|{" "}
														{moment(item?.createdAt).format(
															"DD MMMM, YY"
														)}{" "}
														|{" "}
														{moment(item?.createdAt).format(
															"HH:mm A"
														)}
													</span>
												</h6>
												<p className='text-222222 text-12 font-semibold'>
													{String(item?.content)
														.split("\n")
														.map((el, i) => {
															return <p key={i}>{el}</p>;
														})}
												</p>
											</div>
										</div>
									</div>
								</IonCol>
							);
						})}
				</IonRow>
			</IonGrid>
		</div>
	);
}
