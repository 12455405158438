import { digitChanger } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";

export default function TotalAmount({
	collectionAmount,
	amount,
	collectorLoader,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='text-center'>
			<div
				className='inline-block text-center rounded-[10px] bg-white my-[30px] py-5 px-10 '
				style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)" }}>
				<p className='text-black-333 text-12 pb-[10px]'>
					{ekkLanguage.depositForm.totalCollectionAmount}
				</p>
				{!collectorLoader && (
					<>
						<p className='text-delivered text-12 pb-[10px] font-extrabold'>
							{collectionAmount && collectionAmount.total_orders}{" "}
							{ekkLanguage.depositForm.orders}
						</p>
						<h6 className='text-delivered text-18 pb-[10px] font-bold'>
							৳{" "}
							{collectionAmount &&
								digitChanger(Number(amount).toFixed(2))}
						</h6>
					</>
				)}
			</div>
		</div>
	);
}
