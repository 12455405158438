import React from "react";
import Sheet from "react-modal-sheet";
import Print from "assets/images/icon/svg/Print";
import { IonItem } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setCollectorBottomSheet } from "redux/container/userSlice";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";

export default function BottomSheet() {
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector(
		(state) => state.user.collectorBottomSheet
	);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[120, 120, 100, 0]}
				onClose={() => dispatch(setCollectorBottomSheet(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => {
								dispatch(setCollectorBottomSheet(false));
								navigate(`/collection-history-list`);
							}}
							lines='none'>
							<span className='py-[5px]'>
								<img
									src='/assets/images/collector.png'
									alt='collector'
								/>
							</span>
							<p className='text-14 font-semibold text-black-1000 pl-2'>
								{ekkLanguage.collectionHistory.collectionHistory}
							</p>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop
					onClick={() => dispatch(setCollectorBottomSheet(false))}
				/>
			</Sheet>
		</div>
	);
}
