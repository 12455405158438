import {
  IonApp,
  IonContent,
  IonPage,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import TimeLine from "components/mobile-components/OrderDetails/TimeLine";
import TimeLineTextBox from "components/mobile-components/OrderDetails/TimeLineTextBox";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOderCollectionAmount,
  fetchOrderSingle,
} from "redux/container/orderSlice";
import { useParams } from "react-router-dom";
import BottomSheet from "components/mobile-components/OrderDetails/BottomSheet";
import Orders from "services/Orders";
import BankInfo from "components/mobile-components/DepositDetails/BankInfo";
import DepositAccountCard from "components/mobile-components/DepositDetails/DepositAccountCard";
import DeliveryCard from "components/mobile-components/DepositDetails/DeliveryCard";
import Deposit from "services/Deposit";

export default function BankDetailsPage() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [bankInfo, setBankInfo] = useState({});

  useIonViewDidEnter(() => {
    dispatch(fetchOrderSingle(id));
  });
  useIonViewWillEnter(() => {
    dispatch(fetchOrderSingle(id));
  });

  const getBankInfo = async () => {
    let response = await Deposit.getBankInfo(id);
    if (response.status === 200) {
      setBankInfo(response.data);
    } else {
    }
  };

  useEffect(() => {
    getBankInfo();
  }, []);

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <BackButtonTitle title={ekkLanguage.bankDetailsPage.pageTitle} />
          <section className="px-2 pt-12">
            <BankInfo bankInfo={bankInfo} />
            <DepositAccountCard bankInfo={bankInfo} />
            <DeliveryCard bankInfo={bankInfo} />
            {/* <TimeLine order={response.singleOrder} /> */}
          </section>
          {/* <TimeLineTextBox order={response.singleOrder} /> */}
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
