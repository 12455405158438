import React from "react";
import Sheet from "react-modal-sheet";
import Print from "assets/images/icon/svg/Print";
import { IonItem } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setDriverBottomSheet } from "redux/container/userSlice";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";

export default function DriverBottomSheet({ order }) {
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector((state) => state.user.driverOrderBs);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[140, 140, 100, 0]}
				onClose={() => dispatch(setDriverBottomSheet(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => {
								dispatch(setDriverBottomSheet(false));
								navigate(`/order-history-list`);
							}}
							lines='none'>
							<span className='py-[5px]'>
								<Print />
							</span>
							<p className='text-16 font-bold text-black-1000 pl-2'>
								{ekkLanguage.orderList.deliveryHistoryList}
							</p>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop
					onClick={() => dispatch(setDriverBottomSheet(false))}
				/>
			</Sheet>
		</div>
	);
}
