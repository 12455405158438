import React from "react";
import Mobile from "routes/Mobile";
import MainRoutes from "routes/Routes";
import "./styles/index.scss";

function App() {
	return (
		<div>
			<MainRoutes />
		</div>
	);
}

export default App;
