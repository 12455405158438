import React, { useEffect, useRef, useState } from "react";
import DueDate from "./DueDate";
import FullCredit from "./FullCredit";
import TotalPayment from "./TotalPayment";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderSingle, setSingleOrder } from "redux/container/orderSlice";
import { digitChanger, translator } from "helpers/helper";
import moment from "moment";
import Orders from "services/Orders";
import User from "services/User";
import { getUser } from "config/authCommon";
import { setBottomSheet } from "redux/container/userSlice";
import CustomModal from "../Common/Modal";
import Signature from "../Common/Signature";
import UploadImage from "../Common/UploadImage";
import { ClipLoader } from "react-spinners";
import { IonButton } from "@ionic/react";

export default function Payment() {
	const [loader, setLoader] = useState(false);
	let user = getUser();
	let { id } = useParams();
	const response = useSelector((state) => state.order.singleOrder);
	const [isOpen, setIsOpen] = useState(false);
	let [signatureImage, setSignatureImage] = useState(null);
	let [cameraImage, setCameraImage] = useState(null);
	const [selectedDeliveryDate, setSelectedDeliveryDate] =
		useState("Select A Date");
	const [creditValidationModal, setCreditValidationModal] = useState(false);
	const [dateValidationModal, setDateValidationModal] = useState(false);
	const [signatureValidationModal, setSignatureValidationModal] =
		useState(false);
	const [cameraValidationModal, setCameraValidationModal] = useState(false);
	const [restAmount, setRestAmount] = useState("");
	const dispatch = useDispatch();
	const onCloseModal = () => setIsOpen(false);
	const onOpenCreditValidationModal = () => setCreditValidationModal(true);
	const onCloseCreditValidationModal = () => setCreditValidationModal(false);
	const onOpenDateValidationModal = () => setDateValidationModal(true);
	const onCloseDateValidationModal = () => setDateValidationModal(false);
	const onOpenSignatureValidationModal = () =>
		setSignatureValidationModal(true);
	const onCloseSignatureValidationModal = () =>
		setSignatureValidationModal(false);
	const onOpenCameraValidationModal = () => setCameraValidationModal(true);
	const onCloseCameraValidationModal = () => setCameraValidationModal(false);

	const ekkLanguage = useSelector((state) => state.lan.lan);
	let [remaining, setRemaining] = useState();
	const navigate = useNavigate();
	let [signatureImageURL, setSignatureImageURL] = useState(null);
	let [cameraImageURL, setCameraImageURL] = useState(null);
	const [imageLoader, setImageLoader] = useState(false);

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
			backgroundColor: "#F2F3F7",
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	useEffect(() => {
		dispatch(fetchOrderSingle(id));
	}, []);

	function DataURIToBlob(dataURI) {
		const splitDataURI = dataURI.split(",");
		const byteString =
			splitDataURI[0].indexOf("base64") >= 0
				? atob(splitDataURI[1])
				: decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++)
			ia[i] = byteString.charCodeAt(i);

		return new Blob([ia], { type: mimeString });
	}
	const imageUrlCollect = async () => {
		setImageLoader(true);
		// const signatureFile = DataURIToBlob(signatureImage);
		// const signData = new FormData();
		// signData.append("key", signatureFile, "image.jpg");
		const cameraFile = DataURIToBlob(cameraImage);
		const cameraData = new FormData();
		cameraData.append("key", cameraFile, "image.jpg");

		// let signResponse = await User.toBucketImage(signData);
		let cameraResponse = await User.toBucketImage(cameraData);
		if (cameraResponse.status === 201) {
			// setSignatureImageURL(signResponse?.data);
			setCameraImageURL(cameraResponse?.results);
			setImageLoader(false);
			setIsOpen(true);
		} else {
			setImageLoader(false);
		}
	};

	const orderSubmit = async () => {
		setLoader(true);
		if (remaining >= -0.1) {
			let query = {
				due_date:
					selectedDeliveryDate !== "Select A Date"
						? selectedDeliveryDate
						: response?.payment_term?.paymentDueDate,
				order_status: "Partially_Paid",
				paid_amount:
					Number(response?.total - response?.total_paid) - remaining,
				// signature: signatureImageURL[0],
				payImage: cameraImageURL[0],
			};

			let resp = await Orders.orderUpdate(id, query);
			if (resp.success === true) {
				setLoader(false);
				navigate("/success-collection");
				onOpenCreditValidationModal();
				setIsOpen(false);
				dispatch(setSingleOrder(resp.data));
				setRestAmount();
				dispatch(setBottomSheet(false));
			} else {
				setLoader(false);
			}
		} else {
			onOpenCreditValidationModal();
		}
	};

	return (
		<>
			<div className='pt-14 px-4'>
				<TotalPayment data={response} />
				<FullCredit
					data={response}
					restAmount={restAmount}
					setRestAmount={setRestAmount}
					remaining={remaining}
					setRemaining={setRemaining}
				/>

				{/* <Signature
					setSignatureImage={setSignatureImage}
					signatureImage={signatureImage}
				/> */}
				<UploadImage
					cameraImage={cameraImage}
					setCameraImage={setCameraImage}
				/>
				{remaining != 0 && (
					<DueDate
						data={response}
						selectedDeliveryDate={selectedDeliveryDate}
						setSelectedDeliveryDate={setSelectedDeliveryDate}
					/>
				)}
			</div>
			<div className='pt-[30px] pb-[30px] px-4'>
				<button
					onClick={() => {
						if (restAmount && restAmount > 0 && remaining >= -0.1) {
							if (!cameraImage) {
								onOpenCameraValidationModal();
							} else {
								imageUrlCollect();
							}
						} else {
							onOpenCreditValidationModal();
						}
					}}
					className='h-[50px] w-full rounded-[5px] bg-primary text-white text-24 font-bold flex justify-center items-center'>
					{imageLoader ? (
						<ClipLoader
							sizeUnit={"px"}
							size={25}
							color={"white"}
							loading={true}
						/>
					) : (
						ekkLanguage.payment.collectNow
					)}
				</button>
			</div>
			<Modal
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				style={customStyles}>
				<div className='p-5 relative'>
					<div
						onClick={onCloseModal}
						className='absolute right-3 top-3 h-7 w-7 flex items-center justify-center'>
						<i className='material-icons'>close</i>
					</div>

					<img
						src='/assets/images/ekkbaz_icon.png'
						alt='logo'
						className='w-[50px] h-[40px] mb-2'
					/>
					<h5
						className='text-24 font-bold pb-7'
						style={{ color: "#0099CC" }}>
						{ekkLanguage.payment.confirmPayment}
					</h5>
					<div className='flex justify-between items-center pb-5'>
						<div>
							<h6 className='text-18 font-normal text-black-1000'>
								{response?.buyer?.business_name}
							</h6>
							<p className='text-12 font-normal text-black-333'>
								{digitChanger(response?.buyer?.phone_number)}
							</p>
						</div>
						<div>
							<h6 className='text-18 font-normal text-black-1000'>
								{user?.DisplayName}
							</h6>
							<p className='text-12 font-normal text-black-333'>
								{digitChanger(user?.phone_number)}
							</p>
						</div>
					</div>
					<div className='pb-5 text-center'>
						<h5 className='text-18 font-bold text-black-1000 pb-[10px]'>
							{ekkLanguage.payment.totalAmount}
						</h5>
						<h6
							className='text-18 font-bold'
							style={{ color: "#0099CC" }}>
							{response?.country?.currency_symbol_native}{" "}
							{digitChanger(
								Number(response?.total - response?.total_paid).toFixed(
									2
								)
							)}
						</h6>
					</div>
					<div className='flex justify-between items-center pb-10'>
						<div>
							<h6 className='text-14 font-normal text-success pb-[10px]'>
								{ekkLanguage.payment.todayPayment}
							</h6>
							<p className='text-14 font-normal text-success'>
								{response?.country?.currency_symbol_native}{" "}
								{digitChanger(
									Number(
										Number(response?.total - response?.total_paid) -
											remaining
									).toFixed(2)
								)}
							</p>
							<p
								className='text-14 font-normal pt-5'
								style={{ color: "#B3B3B3" }}>
								{digitChanger(moment().format("DD-MM-YYYY"))}
							</p>
						</div>
						<div>
							<h6 className='text-14 font-normal text-primary pb-[10px]'>
								{ekkLanguage.payment.duePayment}
							</h6>
							<p className='text-14 font-normal text-primary'>
								{response?.country?.currency_symbol_native}{" "}
								{digitChanger(Number(remaining).toFixed(2))}
							</p>
							<p
								className='text-14 font-normal pt-5'
								style={{ color: "#B3B3B3" }}>
								{digitChanger(
									selectedDeliveryDate === "Select A Date"
										? moment(
												response?.payment_term?.paymentDueDate
										  ).format("DD-MM-YYYY")
										: moment(selectedDeliveryDate).format(
												"DD-MM-YYYY"
										  )
								)}
							</p>
						</div>
					</div>
					<div className='flex justify-between items-center'>
						{/* <div
							className='w-1/2 mr-[10px] h-[80px] rounded-[5px] overflow-hidden'
							style={{ backgroundColor: "#E6E6E6" }}>
							<img
								src={signatureImage}
								alt='signature'
								className='m-auto h-100 object-cover'
							/>
						</div> */}
						<div
							className='h-[100px] rounded-[5px] overflow-hidden'
							style={{ backgroundColor: "#E6E6E6" }}>
							<img
								src={cameraImage}
								alt='billImage'
								className='m-auto h-100 object-cover'
							/>
						</div>
					</div>
					<div className='pt-[80px] pb-[30px] px-4'>
						<IonButton
							disabled={loader ? true : false}
							onClick={orderSubmit}
							className='h-[50px] w-full rounded-[5px] bg-primary text-white text-24 font-bold'>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={25}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.payment.collectionDone
							)}
						</IonButton>
					</div>
				</div>
			</Modal>
			{/* credit modal*/}
			<CustomModal
				isOpen={creditValidationModal}
				onClose={onCloseCreditValidationModal}
				title={ekkLanguage.payment.creditWarning}
				btnText={ekkLanguage.payment.okBtnLabel}
			/>
			{/* date modal*/}
			<CustomModal
				isOpen={dateValidationModal}
				onClose={onCloseDateValidationModal}
				title={ekkLanguage.payment.dateWarning}
				btnText={ekkLanguage.payment.okBtnLabel}
			/>
			{/* camera modal*/}
			<CustomModal
				isOpen={cameraValidationModal}
				onClose={onCloseCameraValidationModal}
				title={ekkLanguage.payment.cameraWarning}
				btnText={ekkLanguage.payment.okBtnLabel}
			/>
			{/*  signature modal*/}
			<CustomModal
				isOpen={signatureValidationModal}
				onClose={onCloseSignatureValidationModal}
				title={ekkLanguage.payment.signatureWarning}
				btnText={ekkLanguage.payment.okBtnLabel}
			/>
		</>
	);
}
