import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Orders from "services/Orders";
import CollectionCard from "./CollectionCard";
import CollectionSearch from "./CollectionSearch";
import {
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
} from "@ionic/react";
// import CollectionStatusBtn from "./CollectionStatusBtn";

export default function CollectionHistoryList({ category }) {
	const [collectionHistoryList, setCollectionHistoryList] = useState([]);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [loading, setLoading] = useState(false);
	const [pageCount, setPageCount] = useState(2);

	let getCollectionsList = async () => {
		setLoading(true);
		let response = await Orders.getCollectionsList();
		if (response.status === 200) {
			setLoading(false);
			setCollectionHistoryList(response.data);
		} else {
			setLoading(false);
		}
	};
	const collectionSearch = async (q) => {
		setLoading(true);
		let response = await Orders.searchCollectionsList(q);
		if (response.status === 200) {
			setLoading(false);
			setCollectionHistoryList(response.data);
		} else {
			setLoading(false);
		}
	};
	let lodeMoreData = async () => {
		let response = await Orders.getCollectionsList(pageCount);
		if (response.status === 200) {
			if (response.data.length > 0) {
				setPageCount((prev) => prev + 1);
				setCollectionHistoryList([
					...collectionHistoryList,
					...response.data,
				]);
			}
		}
	};

	useEffect(() => {
		getCollectionsList();
	}, []);

	return (
		<div className='px-2 pt-14'>
			<CollectionSearch collectionSearch={collectionSearch} />
			{/* <CollectionStatusBtn category={category} /> */}
			<CollectionCard
				loading={loading}
				response={collectionHistoryList}
				ekkLanguage={ekkLanguage}
				category={category}
			/>

			<IonLoading
				isOpen={loading ? true : false}
				message={ekkLanguage.orderList.loader}
			/>

			<IonInfiniteScroll
				onIonInfinite={(ev) => {
					lodeMoreData();
					setTimeout(() => ev.target.complete(), 1000);
				}}>
				<IonInfiniteScrollContent
					loadingText={ekkLanguage.orderList.loader}
					loadingSpinner='bubbles'></IonInfiniteScrollContent>
			</IonInfiniteScroll>
		</div>
	);
}
