import React from "react";

export default function Amount() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			viewBox='0 0 12 12'
			fill='none'>
			<path
				d='M9.5 0H2.5C1.12 0 0 1.12 0 2.5V3C0 3.44 0.195 3.835 0.5 4.11V9.5C0.5 10.88 1.62 12 3 12H9C10.38 12 11.5 10.88 11.5 9.5V4.11C11.805 3.835 12 3.44 12 3V2.5C12 1.12 10.88 0 9.5 0ZM10.5 9.5C10.5 10.325 9.825 11 9 11H3C2.175 11 1.5 10.325 1.5 9.5V4.5H10.5V9.5ZM11 3C11 3.275 10.775 3.5 10.5 3.5H1.5C1.225 3.5 1 3.275 1 3V2.5C1 1.675 1.675 1 2.5 1H9.5C10.325 1 11 1.675 11 2.5V3ZM4 6.81C4 6.085 4.59 5.5 5.31 5.5H5.5C5.5 5.225 5.725 5 6 5C6.275 5 6.5 5.225 6.5 5.5H6.635C7.17 5.5 7.665 5.785 7.935 6.25C8.075 6.49 7.99 6.795 7.755 6.935C7.515 7.075 7.21 6.99 7.07 6.755C6.98 6.6 6.815 6.505 6.635 6.505H5.315C5.145 6.505 5.005 6.645 5.005 6.815C5.005 6.97 5.115 7.1 5.265 7.125L6.905 7.4C7.54 7.505 8 8.05 8 8.695C8 9.42 7.41 10.005 6.69 10.005H6.5C6.5 10.28 6.275 10.505 6 10.505C5.725 10.505 5.5 10.28 5.5 10.005H5.365C4.83 10.005 4.335 9.72 4.065 9.255C3.925 9.015 4.01 8.71 4.25 8.57C4.49 8.43 4.795 8.515 4.935 8.75C5.025 8.905 5.19 9 5.37 9H6.69C6.86 9 7 8.86 7 8.69C7 8.535 6.89 8.405 6.74 8.38L5.1 8.105C4.465 8 4.005 7.455 4.005 6.81H4Z'
				fill='#808080'
			/>
		</svg>
	);
}
