import React, { useRef, useState } from "react";
import Auth from "services/Auth";
import { authBox_Block, forgotPass_block } from "Constants";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { loginFromSignUp } from "redux/container/authSlice";
import { useNavigate } from "react-router-dom";
import { updateToken } from "config/SuperFetch";
import { setLocalUser } from "config/user";
import { getLanguage, setLanguage } from "config/language";
import { selectLan } from "redux/container/languageSlice";
import { selectors } from "redux/container/authSlice";
import LeftArrow2 from "assets/images/icon/svg/LeftArrow2";
import EyeOn from "assets/images/icon/svg/EyeOn";
import EyeOff from "assets/images/icon/svg/EyeOff";

export default function SubmitPassBlock({
  password,
  setPassword,
  loading,
  startLoading,
  setBlockIndex,
  setIsForgotPassword,
  stopLoading,
  phoneNo,
  country,
  countryCode,
  sSize,
}) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [newPassword_forgotPassword, setNewPassword_forgotPassword] =
    useState("");
  const [confrimNewPassword, setConfrimNewPassword] = useState("");
  const [error, setError] = useState("");
  const [lengthError, setLengthError] = useState("");
  let clickBtnRef = useRef(null);
  const [eyeIcon, setEyeIcon] = useState(false);
  const [eyeIcon2, setEyeIcon2] = useState(false);

  const _handleKeyPress = (e) => {
    if (e.key === "Enter") {
      clickBtnRef.click();
    }
  };

  const resetPasswordHandler = async (
    newPassword_forgotPassword,
    country,
    phoneNumber,
    countryCode
  ) => {
    setError(
      newPassword_forgotPassword !== confrimNewPassword
        ? ekkLanguage.changePass.passNotMatchLabel
        : false
    );
    if (
      newPassword_forgotPassword == confrimNewPassword &&
      newPassword_forgotPassword.length > 3
    ) {
      let req_body = {
        password: newPassword_forgotPassword,
        country: country,
        phone_number: (countryCode + phoneNumber).trim(),
      };

      try {
        startLoading();
        let response = await Auth.resetPassword(req_body);
        if (response.status === 200 || response.status === 201) {
          await setIsForgotPassword(false);
          stopLoading();
          toast.success(ekkLanguage.changePass.successLabel);
          setBlockIndex(authBox_Block.PHONE_NUMBER_BLOCK);
        } else {
          toast.error(ekkLanguage.changePass.errorLabel);
        }
      } catch (e) {
        stopLoading();
        console.log(e);
        toast.error(ekkLanguage.changePass.errorLabel);
      }
    } else {
      setLengthError(ekkLanguage.changePass.lengthErrorLabel);
    }
  };

  return (
    <div>
      <div>
        <label
          className={`text-14 font-bold md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}
        >
          {ekkLanguage.changePass.passwordLabel}
        </label>
        <div className="relative">
          <input
            type={!eyeIcon ? "password" : "text"}
            onChange={(event) =>
              setNewPassword_forgotPassword(event.target.value)
            }
            placeholder={ekkLanguage.changePass.passwordPlaceHolder}
            id={"ekk-input"}
            style={{
              paddingLeft: 30,
              height: sSize.matches ? 50 : 80,
              flex: "flex: 1 0 auto",
              borderRadius: ".25rem",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderWidth: 0,
              borderLeft: "1px solid rgba(0,0,0,.05)",
            }}
            className="appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white"
          />
          <span
            className="absolute right-5 top-[50%]"
            onClick={() => setEyeIcon(!eyeIcon)}
            style={{ transform: "translateY(-50%)" }}
          >
            {eyeIcon ? <EyeOn /> : <EyeOff />}
          </span>
        </div>
        <label
          className={`text-14 font-bold md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center pt-4`}
        >
          {ekkLanguage.changePass.confirmPassLabel}
        </label>
        <div className="relative">
          <input
            type={!eyeIcon2 ? "password" : "text"}
            onKeyPress={_handleKeyPress}
            onChange={(event) => setConfrimNewPassword(event.target.value)}
            placeholder={ekkLanguage.changePass.confirmPassPlaceHolder}
            id={"ekk-input"}
            style={{
              paddingLeft: 30,
              height: sSize.matches ? 50 : 80,
              flex: "flex: 1 0 auto",
              borderRadius: ".25rem",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderWidth: 0,
              borderLeft: "1px solid rgba(0,0,0,.05)",
            }}
            className="appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white"
          />
          <span
            className="absolute right-5 top-[50%]"
            onClick={() => setEyeIcon2(!eyeIcon2)}
            style={{ transform: "translateY(-50%)" }}
          >
            {eyeIcon2 ? <EyeOn /> : <EyeOff />}
          </span>
        </div>
        <div className="pt-4">
          {error && <span className="text-primary mt-1">{error}</span>}
        </div>
      </div>

      <div className="py-4">
        <button
          onClick={() =>
            resetPasswordHandler(
              newPassword_forgotPassword,
              country,
              phoneNo,
              countryCode
            )
          }
          ref={clickBtnRef}
          style={{
            background:
              "linear-gradient(89.31deg, #00C0FF 0%, rgba(0, 178, 36, 0.5) 100%)",
          }}
          className={`text-20 sm:text-26 font-bold text-white w-full h-[50px] sm:h-[60px] rounded-[5px] lg:mt-10 mt-40`}
        >
          {ekkLanguage?.password.btnLabel}
        </button>
        {/* <div className='text-left pt-4'>
					<p className='font-bold text-14'>
						{ekkLanguage?.login?.langChange}
						<span
							className={`${
								getLanguage() == "en"
									? "text-success"
									: "text-black-1000"
							}  mx-1 cursor-pointer`}
							onClick={() => {
								setLanguage("en");
								dispatch(selectLan());
							}}>
							English
						</span>{" "}
						<span
							className={`${
								getLanguage() == "bn"
									? "text-success"
									: "text-black-1000"
							}  mx-1 cursor-pointer`}
							onClick={() => {
								setLanguage("bn");
								dispatch(selectLan());
							}}>
							বাংলা
						</span>
					</p>
				</div> */}
      </div>
    </div>
  );
}
