import { IonApp, IonContent, IonPage } from "@ionic/react";
import AnalysesDetails from "components/mobile-components/AnalysisDetails";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React from "react";
import { useSelector } from "react-redux";

export default function AnalysesDetailsPage() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <BackButtonTitle title={ekkLanguage.home.details} />
          <div className="pt-14">
            <AnalysesDetails />
          </div>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
