import React, { Component } from "react";
import PropTypes from "prop-types";
import { COUNTRYINFO } from "data/country";
import { getUser } from "config/user";

export class Input extends Component {
  static defaultProps = {
    label: "",
    errorMessage: "",
    placeholder: "",
  };
  render() {
    const {
      label,
      source,
      placeholder,
      errorMessage,
      extraLabel,
      star,
      currencyShow,
      ...inputExtras
    } = this.props;
    const countryList = [
      {
        name: "Bangladesh",
        currency: "৳",
      },
      {
        name: "Vietnam",
        currency: "₫",
      },
      {
        name: "Singapore",
        currency: "$",
      },
      {
        name: "United States",
        currency: "$",
      },
    ];
    const user = getUser();
    return (
      <div className="mb-1 mt-2 relative">
        {/* {typeof label === "function" ? (
					label()
				) : (
					<label className=' uppercase tracking-wide text-grey-darker text-sm font-semibold mb-2'>
						{label}
					</label>
				)} */}
        {typeof label === "object" && (
          <label className="flex tracking-wide text-grey-darker text-16 font-bold mb-2">
            {label?.source && (
              <img className="mr-[10px] w-5 h-5" src={label.source} alt="" />
            )}{" "}
            <span className={`${!label?.source && "pl-[2px]"}`}>
              {label.label}
            </span>{" "}
            {star && <span className="text-primary ml-1">*</span>}
            {extraLabel && (
              <span className="ml-1 text-12 text-[#00000080]">
                ({extraLabel})
              </span>
            )}
          </label>
        )}
        {currencyShow && (
          <span className="absolute left-0  py-[14px] pl-3 font-bold">
            {
              countryList.find((con) =>
                con?.name === user?.Country ? con?.currency : "none"
              )?.currency
            }
          </span>
        )}
        <input
          placeholder={placeholder}
          className={`appearance-none block w-full bg-grey-lighter text-grey-darker border text-14 font-semibold rounded py-4 ${
            currencyShow ? "pl-[30px] pr-[10px]" : "px-[15px]"
          } leading-tight focus:outline-none focus:bg-white`}
          {...inputExtras}
        />

        <div className="flex items-center pl-1">
          {errorMessage && (
            <p className="text-primary mt-1 text-xs font-bold">
              {errorMessage}{" "}
            </p>
          )}
        </div>
      </div>
    );
  }
}

Input.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
};
