import React, { forwardRef, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import Orders from "services/Orders";
import { useSelector } from "react-redux";
import { digitChanger } from "helpers/helper";
import { useParams } from "react-router-dom";

export default function AnalysesDetails() {
	const { date, category, cateList } = useParams();

	const [selectedDate, setSelectedDate] = useState(
		date ? date : moment(new Date())
	);

	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [summery, setSummery] = useState({});

	let getSummery = async (date) => {
		let formateDate = moment(date).format("DD-MM-YYYY");
		let response = await Orders.collectorAmountDetails(formateDate);
		if (response.success) {
			setSummery(response.data);
		} else {
			setSummery({});
		}
	};
	useEffect(() => {
		getSummery(selectedDate);
	}, []);
	let dateChanger = (e) => {
		setSelectedDate(e);
		getSummery(e);
	};
	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className='text-center font-bold cursor-pointer justify-center flex'
			style={{ color: "#262626" }}
			onClick={onClick}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center'>
					{moment(selectedDate).format("DD MMMM, YYYY")}
				</div>
			</div>
		</div>
	));

	return (
		<div className='pt-5'>
			<div className='flex px-4 items-center justify-between pb-4'>
				<h3 className='font-bold text-20 text-black-1000'>
					{category === "assignAmount" && ekkLanguage.home.assigned}
					{category === "deliveredAmount" && ekkLanguage.home.delivered}
					{category === "returnAmount" && ekkLanguage.home.returned}
				</h3>
				<div>
					<DatePicker
						maxDate={Date.now()}
						selected={moment(selectedDate)._d}
						onChange={(date) => dateChanger(date)}
						customInput={<ExampleCustomInput />}
						withPortal
					/>
				</div>
			</div>
			<div>
				<table
					className='table-auto w-full bg-white'
					style={{ boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.05)" }}>
					<thead>
						<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
							<th className='font-bold text-14'>S/N</th>
							<th className='font-bold text-14'>
								{ekkLanguage.home.orderNumber}
							</th>
							<th className='font-bold text-14'>
								{ekkLanguage.home.amount}
							</th>
						</tr>
					</thead>
					<tbody>
						{summery?.[cateList]?.length
							? summery?.[cateList].map((el, i) => {
									return (
										<tr className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'>
											<td className='font-semibold text-12'>
												{digitChanger(i + 1)}
											</td>
											<td className='font-semibold text-12'>
												{digitChanger(el?.orderId)}
											</td>
											<td className='font-semibold text-12'>
												{digitChanger(el[category])}
											</td>
										</tr>
									);
							  })
							: ""}
					</tbody>
				</table>
			</div>
		</div>
	);
}
