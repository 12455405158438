import React from "react";

export default function Pin() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			viewBox='0 0 12 12'
			fill='none'>
			<g clip-path='url(#clip0_1203_12)'>
				<path
					d='M5.97898 12.004L5.63044 11.7052C5.14994 11.3029 0.954468 7.67955 0.954468 5.02915C0.954468 2.25419 3.20402 0.00463867 5.97898 0.00463867C8.75393 0.00463867 11.0035 2.25419 11.0035 5.02915C11.0035 7.67958 6.80801 11.3029 6.32951 11.7072L5.97898 12.004ZM5.97898 1.09108C3.80508 1.09354 2.0434 2.85522 2.04094 5.02912C2.04094 6.69417 4.62215 9.35405 5.97898 10.5715C7.33582 9.35355 9.91701 6.69215 9.91701 5.02912C9.91455 2.85522 8.1529 1.09357 5.97898 1.09108Z'
					fill='#808080'
				/>
				<path
					d='M5.97898 7.0207C4.87901 7.0207 3.9873 6.12899 3.9873 5.02903C3.9873 3.92906 4.87901 3.03735 5.97898 3.03735C7.07895 3.03735 7.97065 3.92906 7.97065 5.02903C7.97065 6.12899 7.07897 7.0207 5.97898 7.0207ZM5.97898 4.03317C5.42899 4.03317 4.98314 4.47902 4.98314 5.029C4.98314 5.57899 5.42899 6.02484 5.97898 6.02484C6.52896 6.02484 6.97481 5.57899 6.97481 5.029C6.97481 4.47902 6.52898 4.03317 5.97898 4.03317Z'
					fill='#808080'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1203_12'>
					<rect width='12' height='12' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
