import { baseUrl } from "config/apiUrlConfig";
import SuperFetch from "config/SuperFetch";

const Deposit = {
	getTodayCollection: (start, end) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/collector-details`);
	},
	collectionSubmit: (data) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/collector-deposit-new`, {
			method: "POST",
			data: data,
		});
	},
	getDepositList: () => {
		return SuperFetch(`${baseUrl.order}/v5/orders/collector-deposit-list`);
	},
	getDepositListFilter: (status) => {
		return SuperFetch(
			`${baseUrl.order}/v5/orders/deposit-status-filter/${status}`
		);
	},
	getBankInfo: (id) => {
		return SuperFetch(`${baseUrl.order}/v5/orders/deposit-details/${id}`);
	},
	getDepositAmount: () =>
		SuperFetch(`${baseUrl.order}/v5/orders/collector-deposit-amount`),

	getDepositByDate: (start, end) => {
		return SuperFetch(
			`${baseUrl.order}/v5/orders/collector-details?start=${start}&end=${end}`
		);
	},
	getSummery: (date) =>
		SuperFetch(
			`${baseUrl.order}/ekkhero/orders/collection/collection-amount-summery-hero-date?dateValue=${date}`
		),
};

export default Deposit;
